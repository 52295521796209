import {useMatch, useNavigate} from "react-router-dom";
import {MenuItem} from "primereact/menuitem";
import styled from "styled-components";
import {classNames} from "primereact/utils";

interface OwnProps {
    item: MenuItem,
    collapsed?: boolean
}

export const RouterMenuItem = ({item, collapsed}: OwnProps) => {
    const match = useMatch({path: item.url!, end: true})
    const navigate = useNavigate();
    return <StyledItem
        onClick={() => {
            navigate(item.url!)
        }}
        className={"p-3 border-round font-bold flex align-items-center mb-3 " + (match ? "surface-100 text-primary" : "")}>
        {item.icon && <span className={`flex align-items-center ${classNames({"mr-3": !collapsed})}`}> <i className={"text-lg " + item.icon}/></span>}
        {!collapsed && <span className="text-sm">{item?.label}</span>}
    </StyledItem>
}

const StyledItem = styled.div`
  :hover {
    cursor: pointer;
    background: var(--surface-100);
  }
`

