import React from "react";
import {BaseAuthScreen} from "../../components/ui/BaseAuthScreen";
import {ResetPasswordForm} from "../../components/relay/ResetPasswordForm";
import {useMatch} from "react-router-dom";
import {RESET_PASSWORD_PATH} from "../../routes/AuthModuleRoutes";
import {Message} from "primereact/message";
import {useTranslation} from "react-i18next";

export const ResetPasswordScreen = () => {
    const {t} = useTranslation('auth')
    const match = useMatch(RESET_PASSWORD_PATH);
    const token = match?.params.token

    if (!token) {
        return <BaseAuthScreen>
            <h1 className="text-center mb-6 text">{t('reset-password.token.heading')}</h1>
            <Message severity="error" text={t('reset-password.token.message')}/>
        </BaseAuthScreen>
    }

    return <BaseAuthScreen>
        <div className="pl-6 pr-6 pb-4">
            <h1 className="text-center mb-6 text">{t('reset-password.heading')}</h1>
            <ResetPasswordForm token={token}/>
        </div>
    </BaseAuthScreen>
}
