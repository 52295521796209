import React from "react"
import graphql from 'babel-plugin-relay/macro'
import {useFragment} from "react-relay"
import {Card} from "primereact/card";
import {DateTimeDisplay} from "../../../components/datetime/DateTimeDisplay";
import {OrderItem_OrderFragment$key} from "../../../__generated__/OrderItem_OrderFragment.graphql";
import {ProductImage} from "../../billing/components/cart/ProductImage";
import {Button} from "primereact/button";
import styled from "styled-components";

const ORDER_FRAGMENT = graphql`
    fragment OrderItem_OrderFragment on Order {
        createdAt
        cart {
            items {
                ... on CartProduct {
                    product {
                        ... on Product {
                            title
                            icon {
                                url
                            }
                        }
                    }
                }
            }
            totals {
                includingAllDiscounts {
                    grossPrice
                }
            }
        }
        invoiceData {
            invoiceNumber
            invoiceDataBase64
        }
    }
`

interface OwnProps {
    orderFragmentRef: OrderItem_OrderFragment$key
}

export const OrderItem = ({orderFragmentRef}: OwnProps) => {
    const {
        createdAt,
        cart,
        invoiceData
    } = useFragment<OrderItem_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef)

    const title = cart?.items[0].product?.title
    const price = cart?.totals.includingAllDiscounts.grossPrice
    const iconUrl = cart?.items[0].product?.icon?.url

    const downloadInvoicePDF = () => {
        if (invoiceData?.invoiceDataBase64) {
            const link = document.createElement("a");
            link.href = "data:application/octet-stream;base64," + invoiceData?.invoiceDataBase64;
            link.download = invoiceData.invoiceNumber + ".pdf";
            link.click();
        }
    }

    return (
        <OrderCart title={title}>
            <div className="flex justify-content-between">
                <ProductImage url={iconUrl || undefined}/>
                <div>
                    <div className="flex">
                        <b className="mr-2">Bestellung vom:</b>
                        <DateTimeDisplay value={createdAt} hideTimezone/>
                    </div>
                    <div className="flex">
                        <b className="mr-2">Preis:</b>
                        <PriceDisplay>{price} €</PriceDisplay>
                    </div>

                </div>
                <Button icon="pi pi-cloud-download" onClick={() => downloadInvoicePDF()}/>
            </div>
        </OrderCart>
    )
}

const OrderCart = styled(Card)`
  width: 30rem;
`

const PriceDisplay = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: "Nunito Sans", sans-serif;
  align-items: center;
  height: 100%;
  display: flex;
  color: #031d3c;
`
