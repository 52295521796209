import {MainSidebar} from "./MainSidebar";
import {MainTopBar} from "./MainTopBar";
import {ReactNode} from "react";

interface OwnProps {
    children: any
    rightSideSlot?: ReactNode
    collapsed?: boolean
}

export const BaseScreen = ({children, rightSideSlot, collapsed}: OwnProps) => {

    return <div className="flex flex-grow-1 overflow-hidden">
        <MainSidebar
            collapsed={collapsed}
            className="border-right-1 border-300 hidden md:flex  "/>

        <div className="flex flex-grow-1 flex-column">
            <MainTopBar className="border-bottom-1 z-1"/>

            <div className="flex flex-grow-1 overflow-y-auto">
                <div className="surface-100 flex-grow-1  justify-content-center flex">
                    {children}
                </div>
                {rightSideSlot}
            </div>
        </div>


    </div>
}
