import {FormikErrors} from "formik/dist/types";
import {useFragment, useMutation} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {usePaymentLogic_OrderFragment$key} from "../../../../../__generated__/usePaymentLogic_OrderFragment.graphql";
import {usePaymentLogic_PayMutation} from "../../../../../__generated__/usePaymentLogic_PayMutation.graphql";


export interface UsePaymentLogicSignature {
    startPayment: (values: any, setErrors: (errors: FormikErrors<any>) => void, setSubmitting: (submitting: boolean) => void) => void;
    paymentInProcess: boolean
}

interface PaymentParameters {
    iban?: string
}

const ORDER_FRAGMENT = graphql`
    fragment usePaymentLogic_OrderFragment on Order {
        id
        selectedPaymentMethod {
            paymentMethodId
            ... on OneTimeSelectedPaymentMethod {
                stripeData {
                    paymentIntentSecret
                }
            }
        }

        billingDetails {
            firstName
            lastName
            street
            houseNumber
            postalCode
            city
            country
            invoiceEmail
            customerType
            ... on BusinessBillingDetails{
                company
            }
        }
        cart {
            selection {
                selectedProducts {
                    amount
                    productId
                }
            }
            totals {
                includingAllDiscounts {
                    netPrice
                }
            }
        }
    }
`;

export const usePaymentLogic = (orderFragmentRef: usePaymentLogic_OrderFragment$key): UsePaymentLogicSignature => {
    const order = useFragment<usePaymentLogic_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef)
    const [pay, isInFLight] = useMutation<usePaymentLogic_PayMutation>(graphql`
        mutation usePaymentLogic_PayMutation($input: PayOrderInput!) {
            Billing {
                payOrder(input: $input) {
                    order {
                        ...OrderFlowScreen_OrderFragment
                    }
                }
            }
        }
    `)


    const startPayment = (values: PaymentParameters, setErrors: (errors: FormikErrors<any>) => void, setSubmitting: (submitting: boolean) => void) => {
        pay({
            variables: {
                input: {
                    orderId: order.id,
                    iban: values.iban
                }
            }
        })
    }

    return {
        startPayment,
        paymentInProcess: isInFLight
    }

}
