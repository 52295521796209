/**
 * @generated SignedSource<<c435bd4f25d848666151ac2f9fa8a853>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamInformation_PublishedModuleFragment$data = {
  readonly isExam: boolean;
  readonly remainingDaysForExam: number;
  readonly " $fragmentType": "ExamInformation_PublishedModuleFragment";
};
export type ExamInformation_PublishedModuleFragment$key = {
  readonly " $data"?: ExamInformation_PublishedModuleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExamInformation_PublishedModuleFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExamInformation_PublishedModuleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remainingDaysForExam",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isExam",
      "storageKey": null
    }
  ],
  "type": "PublishedModuleViewer",
  "abstractKey": null
};

(node as any).hash = "01fa7ec95f701e2fbc0a94f3251dc0ec";

export default node;
