/**
 * @generated SignedSource<<e865618b2513c01885d91c1fa843bf01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CartStep_CartFragment$data = {
  readonly items: ReadonlyArray<{
    readonly code?: string;
    readonly " $fragmentSpreads": FragmentRefs<"CartItem_CartItemFragment">;
  }>;
  readonly selection: {
    readonly selectedDiscountCodes: ReadonlyArray<string>;
    readonly selectedProducts: ReadonlyArray<{
      readonly amount: number;
      readonly productId: string;
    }>;
  };
  readonly totals: {
    readonly includingAllDiscounts: {
      readonly netPrice: number;
    };
    readonly " $fragmentSpreads": FragmentRefs<"CartTotalDisplay_CartTotalsFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CodeRefusalExplanation_RefusalsFragment">;
  readonly " $fragmentType": "CartStep_CartFragment";
};
export type CartStep_CartFragment$key = {
  readonly " $data"?: CartStep_CartFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CartStep_CartFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CartStep_CartFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Selection",
      "kind": "LinkedField",
      "name": "selection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "selectedDiscountCodes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SelectedProduct",
          "kind": "LinkedField",
          "name": "selectedProducts",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CartTotals",
      "kind": "LinkedField",
      "name": "totals",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Price",
          "kind": "LinkedField",
          "name": "includingAllDiscounts",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "netPrice",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CartTotalDisplay_CartTotalsFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "items",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CartItem_CartItemFragment"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "code",
              "storageKey": null
            }
          ],
          "type": "CartDiscount",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CodeRefusalExplanation_RefusalsFragment"
    }
  ],
  "type": "Cart",
  "abstractKey": null
};

(node as any).hash = "851134a15ec02918b4882882fc7cb800";

export default node;
