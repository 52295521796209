import graphql from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import {Button} from "primereact/button";
import {useFormik} from "formik";
import * as Yup from "yup";
import {classNames} from "primereact/utils";
import {InputText} from "primereact/inputtext";
import {toast} from "react-toastify";
import {ChangePasswordForm_ChangeEmailMutation} from "../../../../../__generated__/ChangePasswordForm_ChangeEmailMutation.graphql";
import {ValidatedField, RenderConfig} from "../../../../../components/form/ValidatedField";

const UPDATE_META_VALUE_MUTATION = graphql`
    mutation ChangePasswordForm_ChangeEmailMutation($input: ChangePasswordInput!) {
        Auth {
            changePassword(input: $input){
                clientMutationId
            }
        }
    }
`

interface FormState {
    value: string
}

export const ChangePasswordForm = () => {
    const [changePassword, isInFlight] = useMutation<ChangePasswordForm_ChangeEmailMutation>(UPDATE_META_VALUE_MUTATION)
    const formik = useFormik<FormState>({
        initialValues: {
            value: ""
        },
        validationSchema: Yup.object().shape({
            value: Yup
                .string()
                .min(8, "Das Passwort muss mindestens 8 Zeichen lang sein.")
                .required("Das Feld Passwort wird benötigt"),
        }),
        onSubmit: values => {
            changePassword({
                variables: {
                    input: {
                        newPassword: values.value
                    }
                },
                onCompleted: () => {
                    toast("Die Passwortänderung war erfolgreich", {type: "success"})
                    formik.resetForm()
                }
            })
        }
    })

    return <form onSubmit={formik.handleSubmit} className="field grid">
        <label className="col-fixed font-bold" style={{width: 150}}>
            Passwort
        </label>
        <div className="col">
            <ValidatedField<FormState, string>
                className="mb-0 flex flex-column"
                name={"value"}
                formikConfig={formik}
                component={
                    ({fieldName, fieldValue, updateField, isValid}: RenderConfig<string>) => {
                        return (
                            <InputText id={fieldName} name={fieldName} value={fieldValue}
                                       onChange={e => updateField(e.target.value)}
                                       placeholder={"Neues Passwort... (min. 8 Zeichen)"}
                                       className={`${classNames({"p-invalid": !isValid})} w-20rem h-3rem`}/>
                        )
                    }}/>
        </div>
        <div className="flex align-items-center">
            <Button
                type="submit"
                disabled={isInFlight || formik.values.value.length < 8}
                className="h-3rem" icon={"pi pi-save"}/>
        </div>

    </form>
}
