import React from "react";
import {useFormik} from "formik";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import {Button} from "primereact/button";
import * as Yup from "yup";
import {useMutation} from "react-relay";
import {Card} from "primereact/card";
import graphql from 'babel-plugin-relay/macro';
import {Password} from "primereact/password";
import {Checkbox} from "primereact/checkbox";
import {Dropdown} from "primereact/dropdown";
import {RegistrationStep_RegisterMutation} from "../../__generated__/RegistrationStep_RegisterMutation.graphql";
import {ValidatedFieldV2} from "../../components/form/ValidatedFieldV2";
import styled from "styled-components";


const REGISTRATION_MUTATION = graphql`
    mutation RegistrationStep_RegisterMutation($input:RegistrationInput!) {
        Auth {
            register(input: $input){
                userId
            }
        }
    }
`;

interface FormState {
    firstName: string
    lastName: string
    email: string
    email2: string
    salutation: string
    password: string
    password2: string
    legal: boolean
    subscribedNewsletterRefs: string[]
}

interface OwnProps {
    advance: (applicationToken: string) => void;
    email: string
}

export const RegistrationStep = ({email, advance}: OwnProps) => {
    const [register, isRunning] = useMutation<RegistrationStep_RegisterMutation>(REGISTRATION_MUTATION)

    const formik = useFormik<FormState>({
        initialValues: {
            salutation: "Unknown",
            firstName: '',
            lastName: '',
            email: email,
            email2: email,
            password: '',
            password2: '',
            legal: false,
            subscribedNewsletterRefs: []
        },
        validationSchema: Yup.object().shape({
            email: Yup
                .string()
                .email("Bitte geben Sie eine gültige E-Mail ein")
                .required("Das Feld E-Mail wird benötigt."),
            email2: Yup.string()
                .email("Bitte geben Sie eine gültige E-Mail ein")
                .oneOf([Yup.ref("email"), undefined], "E-Mails müssen übereinstimmen."),
            password: Yup
                .string()
                .min(8, "Das Passwort muss mindestens 8 Zeichen lang sein.")
                .required("Das Feld Passwort wird benötigt"),
            password2: Yup.string()
                .oneOf([Yup.ref("password"), undefined], "Passwörter müssen übereinstimmen."),
            firstName: Yup
                .string()
                .required("Das Feld Vorname wird benötigt."),
            lastName: Yup
                .string()
                .required("Das Feld Nachname wird benötigt."),
            legal: Yup
                .boolean()
                .oneOf([true], "Das Feld ist benötigt.")
                .required("Das Feld ist benötigt."),
        }),
        onSubmit: (values) => {
            register({
                variables: {
                    input: {
                        email: values.email,
                        name: `${values.firstName} ${values.lastName}`,
                        password: values.password,
                    }
                },
                onCompleted: response => {
                    advance(response.Auth.register?.userId!)
                },
            })
        }
    });

    return <div className={"flex justify-content-center align-items-center p-sidebar-full"}>
        <Card>
            <h1>Registrierung</h1>

            <FormContainer onSubmit={formik.handleSubmit} className="p-fluid">
                <ValidatedFieldV2<FormState, string>
                    name={"salutation"}
                    label={"Anrede"}
                    required={true}
                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <div>
                            <Dropdown
                                name={fieldName}
                                value={fieldValue}
                                onChange={e => updateField(e.target.value)}
                                options={[{label: "Herr", value: "Mr"}, {
                                    label: "Frau",
                                    value: "Mrs"
                                }, {label: "Divers", value: "Divers"}]}
                                optionValue={"value"}
                                optionLabel={"label"}
                                className={classNames({'p-invalid': !isValid})}
                            />
                        </div>

                    }}/>
                <ValidatedFieldV2<FormState, string>
                    name={"firstName"}
                    label={"Vorname"}
                    iconClass={"pi-user"}
                    required={true}

                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <InputText id={fieldName} name={fieldName} value={fieldValue}
                                          onChange={e => updateField(e.target.value)}
                                          className={classNames({'p-invalid': !isValid})}/>

                    }}/>
                <ValidatedFieldV2<FormState, string>
                    name={"lastName"}
                    label={"Nachname"}
                    iconClass={"pi-user"}
                    required={true}

                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <InputText id={fieldName} name={fieldName} value={fieldValue}
                                          onChange={e => updateField(e.target.value)}
                                          className={classNames({'p-invalid': !isValid})}/>

                    }}/>
                <ValidatedFieldV2<FormState, string>
                    name={"email"}
                    label={"E-Mail"}
                    iconClass={"pi-envelope"}
                    required={true}

                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <InputText id={fieldName} name={fieldName} value={fieldValue}
                                          onChange={e => updateField(e.target.value)}
                                          className={classNames({'p-invalid': !isValid})}/>

                    }}/>
                <ValidatedFieldV2<FormState, string>
                    name={"email2"}
                    label={"E-Mail (wiederholen)"}
                    iconClass={"pi-envelope"}
                    required={true}

                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <InputText id={fieldName} name={fieldName} value={fieldValue}
                                          onChange={e => updateField(e.target.value)}
                                          className={classNames({'p-invalid': !isValid})}/>

                    }}/>

                <ValidatedFieldV2<FormState, string>
                    name={"password"}
                    label={"Passwort"}
                    iconClass={"pi-lock"}
                    required={true}

                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <Password id={fieldName} name={fieldName} value={fieldValue}
                                         toggleMask={false}
                                         feedback={true}
                                         weakLabel={"Schwach"}
                                         mediumLabel={"Mittel"}
                                         strongLabel={"Stark"}
                                         onChange={e => updateField(e.target.value)}
                                         className={classNames({'p-invalid': !isValid})}/>

                    }}/>
                <ValidatedFieldV2<FormState, string>
                    name={"password2"}
                    label={"Passwort (wiederholen)"}
                    iconClass={"pi-lock"}
                    required={true}

                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <Password id={fieldName} name={fieldName} value={fieldValue}
                                         toggleMask={false}
                                         feedback={false}
                                         onChange={e => updateField(e.target.value)}
                                         className={classNames({'p-invalid': !isValid})}/>

                    }}/>

                <ValidatedFieldV2<FormState, boolean>
                    name={"legal"}
                    required={true}

                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName}) => {
                        return <div className="p-col-12">
                            <Checkbox
                                className="mr-2"
                                inputId={fieldName} onChange={e => updateField(e.checked)}
                                checked={fieldValue}/>
                            <label htmlFor={fieldName}
                                   className="p-checkbox-label">
                                Ich habe die <a target="_blank"
                                                rel="noopener noreferrer"
                                                href="src/screens/registration/RegistrationStep">
                                Datenschutzbestimmungen</a> gelesen.*</label>
                        </div>
                    }}/>
                <Button disabled={isRunning} type="submit" label="Jetzt registrieren" className="p-mt-2"/>
            </FormContainer>
        </Card>
    </div>
}

const FormContainer = styled.form`
  min-width: 400px;
`
