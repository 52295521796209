import React from "react";
import {Route} from "react-router";
import {RouteAuthState} from "../model/SecureRouteDefinition";
import {BrowserRouter, Routes} from "react-router-dom";
import {useTypedSelector} from "../Store";
import {selectCurrentUser} from "../slices/AuthSlice";
import {DashboardModuleRoutes} from "../routes/DashboardModuleRoutes";
import {CoursesModuleRoutes} from "../routes/CoursesModuleRoutes";
import {SettingModuleRoutes} from "../routes/SettingModuleRoutes";
import {FaqModuleRoutes} from "../routes/FaqModuleRoutes";
import {BillingModuleRoutes} from "../routes/BillingModuleRoutes";
import {AuthModuleRoutes} from "../routes/AuthModuleRoutes";
import {CurrentUserSetter} from "../components/CurrentUserSetter";
import {BaseScreen} from "../screens/base/BaseScreen";
import {UserBlockedDisplay} from "../components/UserBlockedDisplay";

const routes = [
    ...AuthModuleRoutes,
    ...DashboardModuleRoutes,
    ...CoursesModuleRoutes,
    ...SettingModuleRoutes,
    ...FaqModuleRoutes,
    ...BillingModuleRoutes
]


export const RootNavigation = () => {
    const currentUser = useTypedSelector(selectCurrentUser)

    return (
        <CurrentUserSetter>
            <BrowserRouter>
                <Routes>
                    {
                        routes.filter(rd => {
                            if (rd.authState === RouteAuthState.Independent) return true

                            return currentUser ? rd.authState === RouteAuthState.OnlyLoggedIn : rd.authState === RouteAuthState.OnlyLoggedOut
                        }).map(rd => ({
                            path: rd.path,
                            element: rd.authState === RouteAuthState.OnlyLoggedIn ?
                                <BaseScreen>{rd.element}</BaseScreen> :
                                rd.element
                        })).map((rd, index) =>
                            <Route
                                key={rd.path}
                                path={rd.path}
                                element={currentUser?.user.isBlocked ? <BaseScreen><UserBlockedDisplay /></BaseScreen>: rd.element}
                            />
                        )
                    }
                </Routes>
            </BrowserRouter>
        </CurrentUserSetter>
    )
}
