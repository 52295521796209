import React, {Suspense} from 'react'
import {graphql} from "babel-plugin-relay/macro";
import {useLazyLoadQuery} from "react-relay";
import {FaqScreen_Query} from "../../../__generated__/FaqScreen_Query.graphql";
import {FaqsTable} from "./FaqsTable";
import {FaqSearchContainer} from "./filter/FaqSearchContainer";
import {FaqFilter} from './filter/FaqFilter';
import styled from "styled-components";

const QUERY = graphql`
    query FaqScreen_Query {
        ...FaqsTable_Faqs
    }
`

export const FaqScreen = () => {
    const query = useLazyLoadQuery<FaqScreen_Query>(
        QUERY,
        {
            first: 5
        }
    )

    return (
        <Container className="flex flex-column w-full align-items-center">
            <h3>FAQ</h3>
            <FaqSearchContainer>
                <FaqFilter/>
                <Suspense fallback={<div>Lade...</div>}>
                    <FaqsTable faqsFragmentRef={query}/>
                </Suspense>
            </FaqSearchContainer>
        </Container>
    )
}

const Container = styled.div`
  overflow-y: auto !important;
`
