import React from "react";
import {Message} from "primereact/message";


export const RegistrationFinishedStep = () => {
    return <div>
        <h1>Registrierung abgeschlossen</h1>

        <Message severity="success"
                 text={"Ihre Registrierung war erfolgreich. Bitte überprüfen Sie Ihr E-Mail Postfach um die E-Mail zu bestätigen und die Registrierung abzuschließen."}/>
    </div>
}
