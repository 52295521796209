import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import {Button} from "primereact/button";
import {DialogResult, useDialogLogic} from "../../../components/dialog/useDialogLogic";
import {toast} from "react-toastify";
import {useFragment, useMutation} from "react-relay";
import {
    SignOutFromModuleButton_SignOutFromModuleMutation
} from "../../../__generated__/SignOutFromModuleButton_SignOutFromModuleMutation.graphql";
import {
    SignOutFromModuleButton_PublishedModuleFragment$key
} from "../../../__generated__/SignOutFromModuleButton_PublishedModuleFragment.graphql";

const PUBLISHED_MODULE_FRAGMENT = graphql`
    fragment SignOutFromModuleButton_PublishedModuleFragment on PublishedModuleViewer {
        id
        canSignOut
        title
    }
`

const SIGN_OUT_FROM_MODULE_MUTATION = graphql`
    mutation SignOutFromModuleButton_SignOutFromModuleMutation($input: SignOutFromModuleInput!) {
        Viewer {
            States {
                signOutFromModule(input: $input) {
                    courseState {
                        node {
                            ... ModuleItems_CourseStateFragment
                        }
                    }
                }
            }
        }
    }
`

interface OwnProps {
    courseStateId: string
    publishedModuleFragmentRef: SignOutFromModuleButton_PublishedModuleFragment$key
}

export const SignOutFromModuleButton = ({courseStateId, publishedModuleFragmentRef}: OwnProps) => {
    const publishedModule = useFragment<SignOutFromModuleButton_PublishedModuleFragment$key>(PUBLISHED_MODULE_FRAGMENT, publishedModuleFragmentRef)

    const [signOutFromModule, isSigningOutFromModule] = useMutation<SignOutFromModuleButton_SignOutFromModuleMutation>(SIGN_OUT_FROM_MODULE_MUTATION)
    const {dialogComponent, showDialog} = useDialogLogic()

    const dialogContent = (canSignOut: boolean) => {
        return canSignOut ? (
            <div>
                <span>Möchtest du dich wirklich von diesem Modul abmelden?</span>
                <p>Bitte vergewissere dich vorher, welcher Alternativtermin für dich am besten passt</p>
                <br/>
                <p><span style={{color: "red"}}>ACHTUNG: </span>Dieser Vorgang kann nicht rückgängig gemacht
                    werden!</p>
                <p>Bei Unklarheiten wende dich gerne per E-Mail an <a
                    href="mailto:termine@thekey.community">termine@thekey.community</a></p>
            </div>
        ) : (
            <div>
                <span>Leider kannst du dich nur 4 Tage vor dem Start des Moduls abmelden</span>
                <p>Bei Fragen oder Unklarheiten wende dich gerne per E-Mail an <a
                    href="mailto:termine@thekey.community">termine@thekey.community</a></p>
            </div>
        )

    }

    return <>
        {dialogComponent}
        <Button
            className={"p-button-danger"}
            type="button"
            label="Abmelden"
            disabled={isSigningOutFromModule}
            loading={isSigningOutFromModule}
            onClick={(e) => {
                showDialog({
                    title: `vom Modul: ${publishedModule.title} abmelden`,
                    content: dialogContent(publishedModule.canSignOut),
                    affirmativeText: "Abmelden",
                    affirmativeButtonDisabled: !publishedModule.canSignOut,
                    dialogCallback: (dialogResult: DialogResult) => {
                        if (dialogResult === "Accept") {
                            signOutFromModule({
                                variables: {
                                    input: {
                                        moduleId: publishedModule.id,
                                        courseStateId: courseStateId
                                    }
                                },
                                onCompleted: () => {
                                    toast.success("Erfolgreich abgemeldet")
                                },
                                onError: () => {
                                    toast.error("Es ist ein Fehler aufgetreten! Sollte es öfter vorkommen, wende dich an app@thekey.community")
                                }
                            })
                        }
                    }
                })
            }}/>
    </>
}
