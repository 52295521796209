/**
 * @generated SignedSource<<6f0ce46740cf4eb572e8ec509f8f703c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PaymentMethodType = "Card" | "Giropay" | "IapApple" | "IapGoogle" | "InvoicePartner" | "InvoiceTk" | "MonthlyPartner" | "MonthlyTk" | "Paypal" | "Sepa" | "Sofort" | "%future added value";
export type SelectedPaymentMethodType = "EmployerInvoice" | "MonthlyPayment" | "OneTimePayment" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MonthlyPaymentCategory_OrderFragment$data = {
  readonly allowedPaymentMethods: ReadonlyArray<PaymentMethodType>;
  readonly cart: {
    readonly totals: {
      readonly monthlyOptions: ReadonlyArray<{
        readonly closingRate: {
          readonly grossPrice: number;
          readonly netPrice: number;
        };
        readonly downPayment: {
          readonly grossPrice: number;
          readonly netPrice: number;
          readonly taxRatePercentage: number;
        };
        readonly financedAmount: {
          readonly grossPrice: number;
          readonly netPrice: number;
          readonly taxRatePercentage: number;
        };
        readonly monthlyRate: {
          readonly grossPrice: number;
          readonly netPrice: number;
          readonly taxRatePercentage: number;
        };
        readonly paymentMethodTypes: ReadonlyArray<PaymentMethodType>;
        readonly rateCount: number;
        readonly totalAmount: {
          readonly grossPrice: number;
          readonly netPrice: number;
        };
      }>;
    };
  } | null;
  readonly id: string;
  readonly selectedPaymentMethod: {
    readonly chosenOption?: {
      readonly closingRate: {
        readonly grossPrice: number;
        readonly netPrice: number;
      };
      readonly downPayment: {
        readonly grossPrice: number;
        readonly netPrice: number;
        readonly taxRatePercentage: number;
      };
      readonly financedAmount: {
        readonly grossPrice: number;
        readonly netPrice: number;
        readonly taxRatePercentage: number;
      };
      readonly monthlyRate: {
        readonly grossPrice: number;
        readonly netPrice: number;
        readonly taxRatePercentage: number;
      };
      readonly paymentMethodTypes: ReadonlyArray<PaymentMethodType>;
      readonly rateCount: number;
      readonly totalAmount: {
        readonly grossPrice: number;
        readonly netPrice: number;
      };
    };
    readonly paymentMethodId: PaymentMethodType;
    readonly selectedPaymentMethodType: SelectedPaymentMethodType;
  } | null;
  readonly " $fragmentType": "MonthlyPaymentCategory_OrderFragment";
};
export type MonthlyPaymentCategory_OrderFragment$key = {
  readonly " $data"?: MonthlyPaymentCategory_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MonthlyPaymentCategory_OrderFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "grossPrice",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netPrice",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = [
  (v1/*: any*/),
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "taxRatePercentage",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Price",
    "kind": "LinkedField",
    "name": "closingRate",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Price",
    "kind": "LinkedField",
    "name": "totalAmount",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Price",
    "kind": "LinkedField",
    "name": "downPayment",
    "plural": false,
    "selections": (v3/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Price",
    "kind": "LinkedField",
    "name": "financedAmount",
    "plural": false,
    "selections": (v3/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Price",
    "kind": "LinkedField",
    "name": "monthlyRate",
    "plural": false,
    "selections": (v3/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "rateCount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "paymentMethodTypes",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MonthlyPaymentCategory_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "selectedPaymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentMethodId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "selectedPaymentMethodType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MonthlyOption",
              "kind": "LinkedField",
              "name": "chosenOption",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            }
          ],
          "type": "MonthlySelectedPaymentMethod",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowedPaymentMethods",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CartTotals",
          "kind": "LinkedField",
          "name": "totals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MonthlyOption",
              "kind": "LinkedField",
              "name": "monthlyOptions",
              "plural": true,
              "selections": (v4/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
})();

(node as any).hash = "71e08b34d352f137d5bf2e8903f9c601";

export default node;
