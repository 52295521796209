/**
 * @generated SignedSource<<6fc523a2bffc007e9c7a569bffb9dbaf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AlternativeDatesButton_PublishedCourseFragment$data = {
  readonly alternativeModules: ReadonlyArray<{
    readonly alternatives: ReadonlyArray<{
      readonly alternativeModule: {
        readonly moduleTemplateId: string;
        readonly " $fragmentSpreads": FragmentRefs<"AlternativeDateInformation_PublishedCourseFragment">;
      };
      readonly " $fragmentSpreads": FragmentRefs<"AlternativeDateInformation_AlternativeFragment">;
    }>;
    readonly publishedModuleId: string;
  }>;
  readonly rebookedCounter: number;
  readonly " $fragmentType": "AlternativeDatesButton_PublishedCourseFragment";
};
export type AlternativeDatesButton_PublishedCourseFragment$key = {
  readonly " $data"?: AlternativeDatesButton_PublishedCourseFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AlternativeDatesButton_PublishedCourseFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AlternativeDatesButton_PublishedCourseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rebookedCounter",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AlternativeModules",
      "kind": "LinkedField",
      "name": "alternativeModules",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publishedModuleId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Alternative",
          "kind": "LinkedField",
          "name": "alternatives",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PublishedModuleViewer",
              "kind": "LinkedField",
              "name": "alternativeModule",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "moduleTemplateId",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AlternativeDateInformation_PublishedCourseFragment"
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AlternativeDateInformation_AlternativeFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PublishedCourse",
  "abstractKey": null
};

(node as any).hash = "e65ac205c36561b4546380e8101137d7";

export default node;
