import React, {useEffect, useState} from "react";
import {useFragment, useMutation} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {paymentMethodIsMonthly} from "../../utils/payment-method-utils";
import {CurrencyDisplay} from "../../../../components/currency/CurrencyDisplay";
import {
    PaymentOptionCategoryContainer,
    PaymentOptionContainer,
    PaymentOptionHeaderContainer,
    PaymentOptionHeading,
    PaymentOptionHeadingPrice,
    PaymentOptionsContainer
} from "./common/StyledComponents";
import {LoadingOverlay} from "../cart/LoadingOverlay";
import {
    MonthlyPaymentCategory_OrderFragment$key
} from "../../../../__generated__/MonthlyPaymentCategory_OrderFragment.graphql";
import {
    MonthlyPaymentCategory_ChangePaymentMethodMutation
} from "../../../../__generated__/MonthlyPaymentCategory_ChangePaymentMethodMutation.graphql";

import {RadioButton} from "primereact/radiobutton";
import {PaymentOptionDisplay} from "./PaymentOptionDisplay";
import styled from "styled-components";

const ORDER_FRAGMENT = graphql`
    fragment MonthlyPaymentCategory_OrderFragment on Order {
        id
        selectedPaymentMethod {
            paymentMethodId
            selectedPaymentMethodType
            ... on MonthlySelectedPaymentMethod {
                chosenOption {
                    closingRate {
                        grossPrice
                        netPrice
                    }
                    totalAmount {
                        grossPrice
                        netPrice
                    }
                    downPayment {
                        netPrice
                        grossPrice
                        taxRatePercentage
                    }
                    financedAmount{
                        netPrice
                        grossPrice
                        taxRatePercentage
                    }
                    monthlyRate{
                        netPrice
                        grossPrice
                        taxRatePercentage
                    }
                    rateCount
                    paymentMethodTypes
                }
            }
        }
        allowedPaymentMethods

        cart {
            totals {
                monthlyOptions {
                    closingRate {
                        grossPrice
                        netPrice
                    }
                    totalAmount {
                        grossPrice
                        netPrice
                    }
                    downPayment {
                        netPrice
                        grossPrice
                        taxRatePercentage
                    }
                    financedAmount{
                        netPrice
                        grossPrice
                        taxRatePercentage
                    }
                    monthlyRate{
                        netPrice
                        grossPrice
                        taxRatePercentage
                    }
                    rateCount
                    paymentMethodTypes
                }
            }
        }
    }
`;

const CHANGE_PAYMENT_METHOD_MUTATION = graphql`
    mutation MonthlyPaymentCategory_ChangePaymentMethodMutation($input: ChangePaymentMethodInput!) {
        Billing {
            changePaymentMethod(input: $input) {
                order {
                    ...OrderFlowScreen_OrderFragment
                }
            }
        }
    }
`;

interface OwnProps {
    orderFragmentRef: MonthlyPaymentCategory_OrderFragment$key
}

export const MonthlyPaymentCategory = ({orderFragmentRef}: OwnProps) => {
    const order = useFragment<MonthlyPaymentCategory_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef)

    const [changePaymentMethod, isInFlight] = useMutation<MonthlyPaymentCategory_ChangePaymentMethodMutation>(CHANGE_PAYMENT_METHOD_MUTATION)


    const allowedMonthlyPaymentMethod = order.allowedPaymentMethods.map(l => l).find(paymentMethodIsMonthly);
    const monthlyOptionsSorted = order.cart?.totals.monthlyOptions.map(monthlyOption => monthlyOption).sort((a, b) => a.rateCount - b.rateCount)

    const defaultSelectedMonthlyOption = order.selectedPaymentMethod?.chosenOption || order.cart?.totals.monthlyOptions[0]
    const defaultSelectedPaymentMethod = order.selectedPaymentMethod?.paymentMethodId || order.cart?.totals.monthlyOptions[0].paymentMethodTypes[0]


    const [selectedMonthlyOption, setSelectedMonthlyOption] = useState(defaultSelectedMonthlyOption)
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(defaultSelectedPaymentMethod)

    useEffect(() => {
            if (allowedMonthlyPaymentMethod && selectedMonthlyOption && selectedPaymentMethod && isOpen) {
                changePaymentMethod({
                    variables: {
                        input: {
                            orderId: order.id,
                            paymentType: "MonthlyPayment",
                            paymentMethodType: selectedPaymentMethod,
                            monthlyOption: {
                                ...selectedMonthlyOption,
                                paymentMethodTypes: [selectedPaymentMethod]
                            }
                        }
                    }
                })
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedMonthlyOption, selectedPaymentMethod]
    )


    if (order.cart?.totals.monthlyOptions.length === 0 || allowedMonthlyPaymentMethod === undefined) {
        return <PaymentOptionCategoryContainer>
            Leider können wir Dir keine monatliche Zahlung anbieten können. Solltest Du Dir das nicht erklären können,
            wende
            Dich bitte an <a href="mailto:betrieb@thekey.community">betrieb@thekey.community</a>
        </PaymentOptionCategoryContainer>;
    }

    const rateCountsFromHighestToLowest = order.cart?.totals.monthlyOptions.map(x => x.rateCount).sort((a, b) => b - a);
    const highestAvailableRateCount = rateCountsFromHighestToLowest?.find(() => true)
    const cheapestMonthlyOption = order.cart?.totals.monthlyOptions.find(x => x.rateCount === highestAvailableRateCount)
    const isOpen = order.selectedPaymentMethod?.selectedPaymentMethodType === "MonthlyPayment"


    return <>
        <PaymentOptionCategoryContainer onClick={() => {
            if (!isOpen && selectedMonthlyOption) {
                changePaymentMethod({
                    variables: {
                        input: {
                            orderId: order.id,
                            paymentType: "MonthlyPayment",
                            paymentMethodType: allowedMonthlyPaymentMethod,
                            monthlyOption: {
                                ...selectedMonthlyOption,
                                paymentMethodTypes: [...selectedMonthlyOption.paymentMethodTypes]
                            }
                        }
                    }
                })
            }
        }}>
            <PaymentOptionHeaderContainer>
                <PaymentOptionHeading>Monatliche Zahlung</PaymentOptionHeading>
                {cheapestMonthlyOption?.monthlyRate.netPrice && !order.selectedPaymentMethod?.chosenOption &&
                    <PaymentOptionHeadingPrice>
                        ab <CurrencyDisplay value={cheapestMonthlyOption?.monthlyRate.netPrice as number}/> pro Monat
                    </PaymentOptionHeadingPrice>}
            </PaymentOptionHeaderContainer>


            {isOpen && <PaymentOptionsContainer>
                {monthlyOptionsSorted?.map(monthlyOption => <ResponsivePaymentOptionContainer
                        key={monthlyOption.rateCount}
                        className={"flex justify-content-between"}
                        isSelected={monthlyOption.rateCount === order.selectedPaymentMethod?.chosenOption?.rateCount}>
                        <div className="flex flex-wrap justify-content-between align-content-between w-full" onClick={() => setSelectedMonthlyOption(monthlyOption)}>
                            <div className="flex flex-column">
                                <PaymentOptionHeaderContainer>
                                    <PaymentOptionHeadingPrice>
                                        <CurrencyDisplay
                                            value={monthlyOption?.monthlyRate.netPrice || 0}/> pro
                                        Monat in {monthlyOption.rateCount} Monaten
                                    </PaymentOptionHeadingPrice>
                                </PaymentOptionHeaderContainer>

                                <div className="mt-3">

                                    {selectedMonthlyOption?.rateCount === monthlyOption.rateCount ?
                                        <small>Zu einer Gesamtsumme von <CurrencyDisplay
                                            value={selectedMonthlyOption?.financedAmount.netPrice}/></small> : null}
                                </div>
                            </div>
                            <PaymentMethodOptionsContainer
                                showPaymentMethods={selectedMonthlyOption?.rateCount === monthlyOption.rateCount}>
                                {selectedMonthlyOption?.paymentMethodTypes.map(paymentMethod => {
                                    return <div key={paymentMethod}
                                                className="field-radiobutton">
                                        <RadioButton inputId={paymentMethod}
                                                     name={paymentMethod}
                                                     value={paymentMethod}
                                                     onChange={(e) => setSelectedPaymentMethod(e.value)}
                                                     checked={selectedPaymentMethod === paymentMethod}/>
                                        <label htmlFor={paymentMethod}>
                                            <PaymentOptionDisplay paymentMethod={paymentMethod}/>
                                        </label>

                                    </div>
                                })}
                            </PaymentMethodOptionsContainer>
                        </div>
                    </ResponsivePaymentOptionContainer>
                )}

            </PaymentOptionsContainer>}
        </PaymentOptionCategoryContainer>
        {isInFlight && <LoadingOverlay/>}
    </>
}

interface PaymentMethodContainerProps {
    showPaymentMethods: boolean
}

const PaymentMethodOptionsContainer = styled.div<PaymentMethodContainerProps>`
  display: flex;
  flex-direction: column;
  visibility: ${props => props.showPaymentMethods ? "visible" : "hidden"}
`

const ResponsivePaymentOptionContainer = styled(PaymentOptionContainer)`
  flex-direction: row;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

