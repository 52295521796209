/**
 * @generated SignedSource<<9308539853f959f43811d137930d7d76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateOrderInput = {
  cartSelection?: CartSelectionInput | null;
  clientMutationId?: string | null;
};
export type CartSelectionInput = {
  createdByUser?: string | null;
  selectedDiscountCodes: ReadonlyArray<string>;
  selectedProducts: ReadonlyArray<SelectedProductInput>;
};
export type SelectedProductInput = {
  amount: number;
  productId: string;
};
export type OrderFlowFromCartBase64Screen_CreateOrderMutation$variables = {
  createOrderInput: CreateOrderInput;
};
export type OrderFlowFromCartBase64Screen_CreateOrderMutation$data = {
  readonly Billing: {
    readonly createOrder: {
      readonly order: {
        readonly id: string;
      };
    } | null;
  };
};
export type OrderFlowFromCartBase64Screen_CreateOrderMutation = {
  response: OrderFlowFromCartBase64Screen_CreateOrderMutation$data;
  variables: OrderFlowFromCartBase64Screen_CreateOrderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "createOrderInput"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BillingMutations",
    "kind": "LinkedField",
    "name": "Billing",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "createOrderInput"
          }
        ],
        "concreteType": "CreateOrderPayload",
        "kind": "LinkedField",
        "name": "createOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Order",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrderFlowFromCartBase64Screen_CreateOrderMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrderFlowFromCartBase64Screen_CreateOrderMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2f426fcbf15e6fd64083c8bdce5c8410",
    "id": null,
    "metadata": {},
    "name": "OrderFlowFromCartBase64Screen_CreateOrderMutation",
    "operationKind": "mutation",
    "text": "mutation OrderFlowFromCartBase64Screen_CreateOrderMutation(\n  $createOrderInput: CreateOrderInput!\n) {\n  Billing {\n    createOrder(input: $createOrderInput) {\n      order {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c22378a24cb036e24fedc925893a326c";

export default node;
