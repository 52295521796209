import React, {ReactNode} from "react";
import Logo from "../../../../assets/images/community.png";
import styled from "styled-components";
import {Button} from "primereact/button";
import {useTypedDispatch, useTypedSelector} from "../../../../Store";
import {logout, selectIsLoggedIn} from "../../../../slices/AuthSlice";

interface OwnProps {
    mainSlot: ReactNode;
}


export const BillingBase = ({mainSlot}: OwnProps) => {
    const isLoggedIn = useTypedSelector(selectIsLoggedIn)
    const dispatch = useTypedDispatch()

    return <BillingBaseContainer>
        <PageHeader>
            <PageHeaderInner>
                <LogoImage src={Logo} alt={"thekey.academy"}/>
                {isLoggedIn ?
                    <Button label="Ausloggen" className="p-button-outlined p-button-danger ml-auto"
                            onClick={() => {
                                dispatch(logout())
                                window.location.reload()
                            }}/> : null}
            </PageHeaderInner>
        </PageHeader>
        <PageContent>
            <InnerContent>
                <h1 className="tk-academy-larger-bold tk-color-waterblue mt-3 mb-4">
                    Deine Bestellung
                </h1>
                {mainSlot}
            </InnerContent>
        </PageContent>
    </BillingBaseContainer>
}

const BillingBaseContainer = styled.div`
  background-color: white;
  font-family: "Nunito Sans", sans-serif;
  width: 100vw;
  padding-bottom: 100px;
`

const PageHeader = styled.div`
  padding: 20px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`

const PageHeaderInner = styled.div`
  display: flex;
  max-width: 1024px;
  width: 100%;
  flex-direction: row;
`

const PageContent = styled.div`
  background: white;
  display: flex;
  justify-content: center;
  padding: 0 20px;
`

const InnerContent = styled.div`
  max-width: 893px;
  width: 100%;
`

const LogoImage = styled.img`
  width: 300px;
`



