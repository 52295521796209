import React from 'react';

interface OwnProps {
    locationName: string;
    hotel: string | null;
}

export const LocationInformation = ({locationName, hotel}: OwnProps) => {
    return (
        <div>
            <b>Ort: </b>
            <span>{locationName}{hotel && "-" + hotel}</span>
        </div>
    )

}
