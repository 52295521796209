import {RouteAuthState, SecureRouteDefinition} from "../model/SecureRouteDefinition";
import {OrderFlowFromCartBase64Screen} from "../screens/billing/components/OrderFlowFromCartBase64Screen";
import {OrderFlowFromIdScreen} from "../screens/billing/components/OrderFlowFromIdScreen";
import React from "react";

export const ORDER_FLOW_FROM_CART_BASE = "/checkout/orders/from-cart/:cartBase64"
export const ORDER_FLOW_FROM_ID = "/checkout/orders/:orderId"

export const BillingModuleRoutes: SecureRouteDefinition[] = [
    {
        path: ORDER_FLOW_FROM_CART_BASE,
        element: <OrderFlowFromCartBase64Screen/>,
        requiredPermissions: [],
        authState: RouteAuthState.Independent
    },
    {
        path: ORDER_FLOW_FROM_ID,
        element: <OrderFlowFromIdScreen/>,
        requiredPermissions: [],
        authState: RouteAuthState.Independent
    }
];
