import {useEffect} from "react";
import {useLazyLoadQuery} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {useTypedDispatch} from "../Store";
import {CurrentUserSetter_Query} from "../__generated__/CurrentUserSetter_Query.graphql";
import {CurrentUserData, setUserData} from "../slices/AuthSlice";

const QUERY = graphql`
    query CurrentUserSetter_Query {
        Viewer {
            Auth {
                currentUser {
                    user {
                        email
                        id
                        name
                        extension {
                            ... on UserExtensionImpl {
                                isBlocked
                                jobTitle
                                branche
                            }
                        }
                    }
                    permissionsInAccount
                }
            }
        }
    }
`

interface OwnProps {
    children: any
}

export const CurrentUserSetter = ({children}: OwnProps) => {
    const dispatch = useTypedDispatch()

    const currentUser = useLazyLoadQuery<CurrentUserSetter_Query>(QUERY, {})

    useEffect(() => {
        if (currentUser.Viewer.Auth.currentUser) {
            dispatch(setUserData({
                user: {
                    id: currentUser.Viewer.Auth.currentUser.user.id,
                    email: currentUser.Viewer.Auth.currentUser.user.email,
                    name: currentUser.Viewer.Auth.currentUser.user.name,
                    isBlocked: currentUser.Viewer.Auth.currentUser.user.extension.isBlocked,
                    jobTitle: currentUser.Viewer.Auth.currentUser.user.extension.jobTitle,
                    branche: currentUser.Viewer.Auth.currentUser.user.extension.branche,
                },
                permissionsInAccount: currentUser.Viewer.Auth.currentUser.permissionsInAccount
            } as CurrentUserData))
        }
        // eslint-disable-next-line
    }, [])


    return <>
        {children}
    </>

}
