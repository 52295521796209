import React, {useState} from 'react'
import graphql from 'babel-plugin-relay/macro'
import {useMatch} from "react-router-dom";
import {COURSE_INFORMATION} from '../../routes/CoursesModuleRoutes'
import {useLazyLoadQuery, useSubscribeToInvalidationState} from "react-relay";
import {
    CourseInformationScreen_CourseInformationQuery
} from "../../__generated__/CourseInformationScreen_CourseInformationQuery.graphql";
import {ModuleItems} from './parts/ModuleItems';
import {Accordion, AccordionTab} from "primereact/accordion";

const COURSE_INFORMATION_QUERY = graphql`
    query CourseInformationScreen_CourseInformationQuery($publishedCourseId: ID!) {
        Viewer {
            States {
                CourseStateForUser(publishedCourseId: $publishedCourseId) {
                    id
                    publishedCourse {
                        name
                        secondName
                        description

                    }
                    moduleStates {
                        id
                    }
                    ...ModuleItems_CourseStateFragment
                }
            }
        }
    }
`

interface State {
    fetchKey: number
}

export const CourseInformationScreen = () => {
    const {params: {publishedCourseId}} = useMatch(COURSE_INFORMATION)!

    const [state, setState] = useState<State>({fetchKey: 0})

    const query = useLazyLoadQuery<CourseInformationScreen_CourseInformationQuery>(COURSE_INFORMATION_QUERY, {
        publishedCourseId: publishedCourseId!
    }, {
        fetchPolicy: "network-only",
        fetchKey: state.fetchKey
    })

    const publishedCourse = query.Viewer.States.CourseStateForUser?.publishedCourse
    const courseState = query.Viewer.States.CourseStateForUser

    useSubscribeToInvalidationState(courseState?.moduleStates?.map(l => l!.id) || [], () => {
        setState(state => ({...state, fetchKey: state.fetchKey + 1}))
    })

    return (
        <div className="flex flex-column align-items-center">
            <h3>Informationen zum Lehrgang {publishedCourse?.name} - {publishedCourse?.secondName}</h3>
            {publishedCourse?.description ? <Accordion>
                <AccordionTab header={"Hinweis:"}>
                    <p dangerouslySetInnerHTML={{__html: publishedCourse.description}}/>
                </AccordionTab>
            </Accordion>: null}
            <div className="p-2">
                <ModuleItems courseStateRef={courseState!}/>
            </div>
        </div>
    )
}
