import React from 'react';
import {DateTimeDisplay} from "../datetime/DateTimeDisplay";
import {Dropdown} from "primereact/dropdown";

interface OwnProps {
    dates: string[]

    placeholder: string
    selectedDate: string
    setSelectedDate: (date: string) => void
}

export const DateSelectionDropDown = ({dates,selectedDate, setSelectedDate, placeholder}: OwnProps) => {

    const dropDownOptions = dates.map(date => ({
        label: <DateTimeDisplay value={date} hideTimezone/>,
        value: date
    }))

    return <Dropdown
        value={selectedDate}
        options={dropDownOptions}
        onChange={(e) => setSelectedDate(e.value)}
        placeholder={placeholder}/>
}
