import CommunityLogo from "../../assets/images/community.png";

import {MAIN_MENU_ITEMS} from "./MainMenu";
import {NavLink} from "react-router-dom";
import {Menu} from "primereact/menu";
import React, {useState} from "react";
import {Agbs} from "../../components/Agbs";
import styled from "styled-components";
import {Dialog} from "primereact/dialog";

interface OwnProps {
    className: string
    collapsed?: boolean
}

export const MainSidebar = ({className, collapsed}: OwnProps) => {
    const [agbsOpen, setAgbsOpen] = useState<boolean>()

    return <div className={"flex-column " + (className ? className : "")}>
        <div className="p-4 text-center">
            <NavLink to="/">
                {!collapsed && <img alt="Community" src={CommunityLogo} width={150}/>}
                {collapsed && <img alt="Community" src={CommunityLogo} width={50}/>}
            </NavLink>
        </div>

        <div className="flex-grow-1 flex p-4">
            <Menu
                className="flex-grow-1 border-0"
                style={{width: "100%"}}
                model={MAIN_MENU_ITEMS(collapsed)}/>
        </div>

        {!collapsed && <div className="border-top-1 border-300 p-4">
            <div className="flex">
                <a className="mr-2"
                   target="_blank"
                   rel="noreferrer"
                   href={"https://www.thekey.community/impressum/"}>
                    Impressum
                </a>
                <span>|</span>
                <a className="ml-2 mr-2"
                   target="_blank"
                   rel="noreferrer"
                   href={"https://www.thekey.community/datenschutz/"}>
                    Datenschutz
                </a>
                <span>|</span>
                <AgbLink className="ml-2 mr-2" onClick={() => setAgbsOpen(!agbsOpen)}>
                    AGBs
                </AgbLink>
                <Dialog header="Allgemeinen Geschäftsbedingungen"
                        closeOnEscape
                        visible={agbsOpen}
                        modal={false}
                        onHide={() => setAgbsOpen(false)}>
                    <Agbs/>
                </Dialog>
            </div>
        </div>}
    </div>
}

const AgbLink = styled.span`
  margin: 0 0.2rem;
  text-decoration: underline;
  color: #0000EE;
  cursor: pointer;
`
