import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import React from "react";
import styled from "styled-components";
import {CurrencyDisplay} from "../../../../components/currency/CurrencyDisplay";
import {CartItem_CartItemFragment$key} from "../../../../__generated__/CartItem_CartItemFragment.graphql";
import {ProductImage} from "./ProductImage";

const CART_ITEM_FRAGMENT = graphql`
    fragment CartItem_CartItemFragment on Item {
        itemType
        ... on CartDiscount {
            title
            value {
                ... on DiscountActionEuroCalcType {
                    euro
                }
            }
            code
            validUntil
        }
        ... on CartProduct {
            product {
                ... on Product {
                    id
                    title
                    icon {
                        url
                    }
                }
            }
            productRef
            totalPrice {
                netPrice
                grossPrice
            }

            individualPrice {
                netPrice
                grossPrice
            }
            amount
        }
    }
`;

interface OwnProps {
    cartItemFragmentRef: CartItem_CartItemFragment$key
}

export const CartItem = ({
                             cartItemFragmentRef
                         }: OwnProps) => {
    const cartItem = useFragment<CartItem_CartItemFragment$key>(CART_ITEM_FRAGMENT, cartItemFragmentRef)

    return <>
        {cartItem.itemType === "CartProduct" &&
            <CartItemContainer>
                <ProductImage url={cartItem.product?.icon?.url || undefined}/>

                <ProductTitleWrapper>
                    <ProductTitle>{cartItem.product?.title}</ProductTitle>
                </ProductTitleWrapper>

                <RightSideWrapper>
                    <PriceDisplay>
                        <CurrencyDisplay value={cartItem.totalPrice?.netPrice!}/>
                    </PriceDisplay>
                </RightSideWrapper>

            </CartItemContainer>}
    </>

}

const CartItemContainer = styled.div`
  display: grid;
  grid-template-columns: 75px 2fr 1fr;
  grid-column-gap: 20px;

  @media (max-width: 1024px) {
    grid-template-columns: 75px 1fr;
    grid-row-gap: 20px;
  }

  padding-bottom: 20px;
  border-bottom: solid 1px #e7ebf1;
  margin-bottom: 20px;
`


const ProductTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const RightSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 1024px) {
    padding-top: 20px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

`

export const PriceDisplay = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: "Nunito Sans", sans-serif;
  align-items: center;
  height: 100%;
  display: flex;
  color: #031d3c;
`

export const DiscountCodeValiditySpan = styled.span`
  margin-right: 10px;
  padding: 0 3px 1px;
  border-radius: 2px;
  background-color: #247ac7;
  color: white;
  font-size: 12px;
`
export const ProductTitle = styled.div`
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  color: #031d3c;
  display: flex;
  align-items: center;
`
