import React from "react";
import styled from "styled-components";

interface OwnProps {
    className?: string
    url?: string
}

export const ProductImage = ({url, className}: OwnProps) => {
    return url ?
        <ProductImageDisplay className={className} src={url} height={75} width={75}
                             alt={""}/> :
        <ProductImageEmpty className={className}/>
}

const ProductImageDisplay = styled.img`
  height: 75px;
  width: 75px;
  border-radius: 15px;
`

const ProductImageEmpty = styled.div`
  height: 75px;
  width: 75px;
`
