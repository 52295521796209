import {PrivateForm} from "./PrivateForm";
import React from "react";
import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {
    BillingDetailsForm_OrderFragment$key,
} from "../../../../../__generated__/BillingDetailsForm_OrderFragment.graphql";
import styled from "styled-components";


const ORDER_FRAGMENT = graphql`
    fragment BillingDetailsForm_OrderFragment on Order {
        id
        billingDetails {
            customerType
            ...PrivateForm_BillingDetailsFragment
        }
    }
`

interface OwnProps {
    orderFragmentRef: BillingDetailsForm_OrderFragment$key
}

export const BillingDetailsForm = ({orderFragmentRef}: OwnProps) => {
    const order = useFragment<BillingDetailsForm_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef)

    return <div>
        <Information>Hinweis: Bitte geben Sie zunächst Ihre Daten als teilnehmende Person ein. Eine abweichenden Rechnungsadresse können Sie gerne weiter unten angeben. Bitte beachten Sie, dass die nachfolgende Adresse sowie das Geburtsdatum auf dem IHK-Zertifikat erscheinen.</Information>
        <PrivateForm
            orderId={order.id}
            billingDetailsFragmentRef={order.billingDetails}
            />
    </div>
}

const Information = styled.div`
  border: solid 2px #e7ebf1;
  border-radius: 6px;
  padding: 1rem;
  margin: 1rem 0;
`
