import React, {useState} from "react";
import {RegistrationStep} from "./RegistrationStep";
import {RegistrationFinishedStep} from "./RegistrationFinishedStep";

type RegistrationSteps = "check" | "registration" | "apply" | "finished"

interface State {
    step: RegistrationSteps
    applicationToken?: string
    email?: string
}

export const RegistrationFlowScreen = () => {
    const [state, setState] = useState<State>({
        step: "registration"
    });

    let Component;

    if (state.step === "registration") {
        Component = <RegistrationStep email={state.email!} advance={applicationToken => setState({
            step: "apply",
            applicationToken
        })}/>
    } else if (state.step === "apply") {
        Component = <RegistrationFinishedStep/>
    } else Component = null

    return Component
}
