import {useFormik} from "formik";
import React, {useContext, useEffect, useState} from "react";
import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import * as yup from "yup";
import {isValidIBANNumber} from "../../../utils/iban-utils";
import {useTranslation} from "react-i18next";
import {paymentMethodNeedsSepaMandate} from "../../../utils/payment-method-utils";
import styled from "styled-components";
import {usePaymentLogic} from "./usePaymentLogic";
import {PaymentDataFormPart} from "./PaymentDataFormPart";
import {OrderFlowContext} from "../../../hooks/OrderFlowContext";
import {Separator} from "../../payment/PaymentStep";
import {LoadingOverlay} from "../../cart/LoadingOverlay";
import {ValidatedFieldV2} from "../../../../../components/form/ValidatedFieldV2";
import {ReviewForm_OrderFragment$key} from "../../../../../__generated__/ReviewForm_OrderFragment.graphql";
import {Checkbox} from "primereact/checkbox";
import {Dialog} from "primereact/dialog";
import {Agbs} from "../../../../../components/Agbs";

interface OwnProps {
    orderFragmentRef: ReviewForm_OrderFragment$key
}

export interface ReviewFormState {
    paymentMethodField: string
    legal: boolean
    iban?: string
    sepaMandate: boolean
}

export const ReviewForm = ({orderFragmentRef}: OwnProps) => {
    const {t} = useTranslation("billing")
    const [agbModalOpen, setAgbModalOpen] = useState<boolean>(false)
    const {updateReviewFormState} = useContext(OrderFlowContext)

    const order = useFragment<ReviewForm_OrderFragment$key>(graphql`
        fragment ReviewForm_OrderFragment on Order {
            selectedPaymentMethod {
                paymentMethodId
            }
            ...usePaymentLogic_OrderFragment
            ...PaymentDataFormPart_OrderFragment
        }
    `, orderFragmentRef)

    const {startPayment, paymentInProcess} = usePaymentLogic(order)

    const formikConfig = useFormik<ReviewFormState>({
        initialValues: {
            paymentMethodField: "",
            iban: "",
            legal: false,
            sepaMandate: false
        },
        enableReinitialize: true,
        validationSchema: yup.object().shape({
            legal: yup
                .boolean()
                .oneOf([true], t("core:forms.required-field", {fieldName: t("purchase-form.legal")}))
                .required(t("core:forms.required-field", {fieldName: t("purchase-form.legal")})),
            iban: yup
                .string()
                .test("test", "test", function (value) {
                    const valueIsFilled = !value || value.length > 8
                    if (paymentMethodNeedsSepaMandate(order.selectedPaymentMethod?.paymentMethodId!) && (valueIsFilled && !isValidIBANNumber(value!))) {
                        return this.createError({
                            path: "iban",
                            message: t("purchase-form.iban-error")
                        });
                    }
                    return true;
                }),
            sepaMandate: yup
                .boolean()
                .test("test", "test", function (value) {
                    if (paymentMethodNeedsSepaMandate(order.selectedPaymentMethod?.paymentMethodId!) && !value) {
                        return this.createError({
                            path: "sepaMandate",
                            message: t("purchase-form.sepa-mandate")
                        });
                    }

                    return true;
                })
        }),
        onSubmit: (values, {setSubmitting, setErrors}) => {
            startPayment(values, setErrors, setSubmitting)
        }
    })

    const LegalText = () => (
        <span className="p-checkbox-label">
            <span>Mit meiner Bestellung stimme ich den</span>
            <AgbLink onClick={() => setAgbModalOpen(true)}>AGBs</AgbLink>
            <span>und der </span>
            <a rel="noopener noreferrer" href='https://www.thekey.community/datenschutz/'
               target='_blank'>Datenschutzerklärung</a>
            <span> zu. (erforderlich)</span>
            <Dialog header="Allgemeinen Geschäftsbedingungen"
                    closeOnEscape
                    visible={agbModalOpen}
                    modal={false}
                    onHide={() => setAgbModalOpen(false)}>
                <Agbs/>
            </Dialog>
        </span>
    )

    useEffect(() => {
        if (formikConfig.handleSubmit) {
            updateReviewFormState(formikConfig.handleSubmit, formikConfig.isValid)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formikConfig.handleSubmit, formikConfig.isValid])


    return <div className="position-relative">
        {paymentInProcess && <LoadingOverlay/>}

        <form onSubmit={formikConfig.handleSubmit}>
            <PaymentDataFormPart formikState={formikConfig} orderFragmentRef={order}/>

            <Separator/>
            <Heading>Rechtliches</Heading>
            {paymentMethodNeedsSepaMandate(order.selectedPaymentMethod?.paymentMethodId!) ?
                <div className="form-group">
                    <ValidatedFieldV2<ReviewFormState, boolean>
                        name="sepaMandate"
                        required={true}
                        component={({fieldValue, updateField, fieldName}) => {
                            return <div className="p-col-12">
                                <Checkbox
                                    className="mr-2"
                                    inputId={fieldName} onChange={e => updateField(e.checked)}
                                    checked={fieldValue}/>
                                <label htmlFor={fieldName}
                                       dangerouslySetInnerHTML={{__html: t("checkout-form.sepa-mandate")}}
                                       className="p-checkbox-label"/>
                            </div>
                        }}
                        formikConfig={formikConfig}/>
                </div> : null}

            <div className="form-group">
                <ValidatedFieldV2<ReviewFormState, boolean>
                    name="legal"
                    required={true}
                    component={({fieldValue, updateField, fieldName}) => {
                        return <div className="p-col-12">
                            <Checkbox
                                className="mr-2"
                                inputId={fieldName} onChange={e => updateField(e.checked)}
                                checked={fieldValue}/>
                            <LegalText/>
                        </div>
                    }}
                    formikConfig={formikConfig}/>
            </div>
        </form>
    </div>
}
const Heading = styled.h3`
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 15px;
  color: #184276;
`

const AgbLink = styled.span`
  margin: 0 0.2rem;
  text-decoration: underline;
  color: #0000EE;
  cursor: pointer;
`
