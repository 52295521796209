import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import React, {useState} from "react";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {
    AlternativeDatesButton_PublishedCourseFragment$key
} from "../../../../__generated__/AlternativeDatesButton_PublishedCourseFragment.graphql";
import {AlternativeDateInformation} from "./AlternativeDateInformation";

const PUBLISHED_COURSE_FRAGMENT = graphql`
    fragment AlternativeDatesButton_PublishedCourseFragment on PublishedCourse {
        rebookedCounter
        alternativeModules {
            publishedModuleId
            alternatives {
                alternativeModule {
                    moduleTemplateId

                    ...AlternativeDateInformation_PublishedCourseFragment
                }
                ... AlternativeDateInformation_AlternativeFragment
            }
        }
    }
`

interface OwnProps {
    publishedCourseFragment: AlternativeDatesButton_PublishedCourseFragment$key
    moduleTemplateId: string
}

export const AlternativeDatesButton = ({
                                           publishedCourseFragment,
                                           moduleTemplateId,
                                       }: OwnProps) => {
    const publishedCourse = useFragment<AlternativeDatesButton_PublishedCourseFragment$key>(PUBLISHED_COURSE_FRAGMENT, publishedCourseFragment)

    const [isVisible, setVisible] = useState<boolean>(false)

    return <>
        <Button
            className="p-button-raised p-button-primary"
            label={"Andere Termine"}
            onClick={() => {
                setVisible(true);
            }}/>

        {isVisible &&
            <Dialog closeOnEscape
                    visible={true}
                    modal={false}
                    header="Alternativtermine"
                    contentClassName="surface-100"
                    onHide={() => setVisible(false)}>
                <div className="surface-100">
                    {publishedCourse
                        .alternativeModules
                        .map(am => am
                            .alternatives
                            .filter(l => l.alternativeModule.moduleTemplateId === moduleTemplateId)
                            .map((a, index: number) => (
                                <AlternativeDateInformation key={a.alternativeModule.moduleTemplateId + index}
                                                            alternativeFragmentRef={a}
                                                            publishedCourseFragmentRef={a.alternativeModule}/>
                            )))}
                </div>
            </Dialog>
        }
    </>

}
