import React, {useContext} from "react";
import {OrderFlowContext} from "../../hooks/OrderFlowContext";
import styled from "styled-components";
import {Button} from "primereact/button";


export const OrderFlowFooter = () => {
    const {canAdvance, canGoBack, tryAdvance, goBack, openStage} = useContext(OrderFlowContext)

    return <FooterContainer>
        <InnerContainer>
            <Button
                disabled={!canGoBack}
                onClick={() => goBack()}
                className="p-button-rounded p-button-secondary p-button-outlined">Zurück</Button>
            <Button
                disabled={!canAdvance}
                type={"submit"}
                onClick={() => tryAdvance()}
                className="p-button-rounded ml-auto"
            >{openStage === "review" ? "Jetzt kostenpflichtig bestellen" : "Weiter"}</Button>
        </InnerContainer>
    </FooterContainer>
}

const FooterContainer = styled.div`
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  padding: 20px 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  @media (max-width: 1024px) {
    padding: 20px;
  }
`

const InnerContainer = styled.div`
  display: flex;
  max-width: 893px;
  width: 100%;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  align-items: center;

  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;

    button {
      min-width: auto;
      width: 100%;
      line-height: 1 !important;
    }
  }
`
