import React, {useEffect} from 'react'
import graphql from 'babel-plugin-relay/macro'
import {ModuleItems_CourseStateFragment$key} from "../../../__generated__/ModuleItems_CourseStateFragment.graphql";
import {useFragment} from "react-relay";
import styled from "styled-components";
import {SignOutFromModuleButton} from "./SignOutFromModuleButton";
import {AlternativeDatesButton} from "./alternativeDates/AlternativeDatesButton";
import {Card} from "primereact/card";
import {DateTimeDisplay} from "../../../components/datetime/DateTimeDisplay";
import {ModuleInformation} from "./ModuleInformation";
import {TrainingDayInformation} from "./trainingDayInformation/TrainingDayInformation";
import {useNavigate} from "react-router-dom";
import 'primeicons/primeicons.css';
import {ExamInformation} from "./exam/ExamInformation";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";
import {CONTACT_EMAIL} from "../../../App";
import {Title} from "../../../components/ui/Title";

const COURSE_STATE_FRAGMENT = graphql`
    fragment ModuleItems_CourseStateFragment on CourseState {
        id
        publishedCourse {
            rebookedCounter
            modulesViewer {
                id
                title
                startDate
                endDate
                canSeeSignOut
                canSeeAlternativeDates
                isExam
                moduleTemplateId
                trainingDays {
                    id
                    ... TrainingDayInformation_TrainingDayFragment
                }

                ...ModuleInformation_PublishedModuleFragment
                ...SignOutFromModuleButton_PublishedModuleFragment
                ...ExamInformation_PublishedModuleFragment
            }
            ...AlternativeDatesButton_PublishedCourseFragment
        }

        moduleStates {
            moduleId
            trainingDayStates {
                trainingDayId
                attendanceListViewer {
                    userId
                    attendanceStatus
                }
            }
        }

        ... TrainingDayInformation_CourseStateFragment
    }
`


interface OwnProps {
    courseStateRef: ModuleItems_CourseStateFragment$key
}

export const ModuleItems = ({courseStateRef}: OwnProps) => {
    const {t} = useTranslation("courseInformation")

    const courseState = useFragment<ModuleItems_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, courseStateRef)
    const navigate = useNavigate()

    const showMessage = courseState.publishedCourse.rebookedCounter > 6

    useEffect(() => {
        if (courseState.publishedCourse.modulesViewer.length === 0) navigate(-1)
    }, [courseState, navigate])

    return (
        <Container>
            {courseState.publishedCourse.modulesViewer.map((module, index) => (
                <Card
                    key={index}
                    className="relative p-4 w-25rem"
                    title={() => <Title title={module.title} addition={module.isExam ? " - Prüfung" : ""}/>}
                    subTitle={() => (
                        <div className="flex flex-column">
                            <ExamInformation publishedModuleFragmentRef={module}/>
                            <div className="flex">
                                <DateTimeDisplay value={module.startDate.toString()}
                                                 showTime={false}
                                                 hideTimezone/>
                                <div className="mr-1 ml-1"> -</div>
                                <DateTimeDisplay value={module.endDate.toString()}
                                                 showTime={false}
                                                 hideTimezone/>
                            </div>
                        </div>
                    )}
                    footer={() => (
                        <div className="flex flex-row w-full justify-content-between w-17rem">
                            {module.canSeeSignOut &&
                                <SignOutFromModuleButton courseStateId={courseState.id}
                                                         publishedModuleFragmentRef={module}/>}
                            {showMessage ?
                                <Button icon="pi pi-exclamation-triangle"
                                        className="p-button-rounded p-button-warning p-button-text"
                                        aria-label="Notification"
                                        tooltip={t("moduleItems.alternativeDate.over-limit")}
                                        onClick={() => window.location.href = CONTACT_EMAIL}/> :
                                module.canSeeAlternativeDates &&
                                <AlternativeDatesButton publishedCourseFragment={courseState.publishedCourse}
                                                        moduleTemplateId={module.moduleTemplateId}/>}
                        </div>
                    )}>
                    <ModuleInformation publishedModuleFragmentRef={module}/>
                    {module.trainingDays.map((day, index) => (
                        <TrainingDayInformation courseStateFragmentRef={courseState}
                                                moduleId={module.id}
                                                trainingDayFragmentRef={day}
                                                key={index}
                                                index={index}/>
                    ))}


                </Card>
            ))}
        </Container>
    )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;

  @media (max-width: 1920px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 1500px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
  }

  .p-card-footer {
    position: absolute;
    bottom: 1rem;
  }
`
