import React, {useState} from 'react'
import {graphql} from "babel-plugin-relay/macro";
import {useLazyLoadQuery, useSubscribeToInvalidationState} from "react-relay";
import {CoursesScreen_Query} from '../../__generated__/CoursesScreen_Query.graphql';
import {CourseItem} from "./parts/CourseItem";

const COURSES_QUERY = graphql`
    query CoursesScreen_Query {
        Viewer {
            States {
                CourseStatesForUserWithoutRebookedAttendence(first: null, after: null) @connection(key: "CoursesScreen_CourseStatesForUserWithoutRebookedAttendence"){
                    edges {
                        node {
                            moduleStates {
                                trainingDayStates {
                                    attendanceListViewer {
                                        userId
                                        attendanceStatus
                                        attendanceType
                                    }
                                }
                            }
                            
                            id
                            publishedCourse {
                                ...CourseItem_CourseFragment
                            }
                            ...CourseItem_CourseStateFragment
                        }
                    }
                }
            }
        }
    }
`

interface State {
    fetchKey: number
}

export const CoursesScreen = () => {
    const [state, setState] = useState<State>({fetchKey: 0})

    const query = useLazyLoadQuery<CoursesScreen_Query>(
        COURSES_QUERY,
        {},
        {
            fetchPolicy: "network-only",
            fetchKey: state.fetchKey
        }
    )

    const courseStates = query.Viewer.States.CourseStatesForUserWithoutRebookedAttendence.edges?.filter(l => !!l).map(k => k?.node)



    useSubscribeToInvalidationState(courseStates?.map(l => l!.id)|| [], () => {
        setState(state => ({...state, fetchKey: state.fetchKey + 1}))
    })

    return (
        <div className="flex flex-column align-items-center">
            <h2 className="mb-8">Meine Lehrgänge</h2>
            <div>
                {courseStates ?
                  courseStates.map((courseState, index) =>
                        courseState && <CourseItem key={index}
                                                   courseFragmentRef={courseState.publishedCourse}
                                                   courseStateFragmentRef={courseState}/>
                    )
                    : null
                }
            </div>
        </div>
    )

}
