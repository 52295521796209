import React from "react";
import {ForgotPasswordForm} from "../../components/relay/ForgotPasswordForm";
import {BaseAuthScreen} from "../../components/ui/BaseAuthScreen";


export const ForgotPasswordScreen = () => {
    return <BaseAuthScreen>
        <h1 className="text-center mb-6 text">Passwort vergessen</h1>
        <ForgotPasswordForm/>
    </BaseAuthScreen>
}
