import {DashboardScreen} from "../screens/dashboard/components/DashboardScreen";
import {RouteAuthState, SecureRouteDefinition} from "../model/SecureRouteDefinition";

export const DashboardModuleRoutes: SecureRouteDefinition[] = [
    {
        path: "/",
        element: <DashboardScreen/>,
        requiredPermissions: [],
        authState: RouteAuthState.OnlyLoggedIn
    },
];
