import {Menu} from "primereact/menu";
import {useRef} from "react";
import {Button} from "primereact/button";
import {MenuItem} from "primereact/menuitem";
import {useNavigate} from "react-router-dom";
import {useTypedDispatch, useTypedSelector} from "../../Store";
import {CurrentUserData, logout, selectCurrentUser} from "../../slices/AuthSlice";
import {COMMUNICATION_SETTINGS_ROUTE, MY_ACCOUNT_ROUTE} from "../../routes/SettingModuleRoutes";


interface OwnProps {
    className?: string
}

export const PROFILE_LINK_MENU_ITEMS = (myAccountFunction: () => void, communicationSettingsFunction: () => void, logoutFunction: () => void, onComplete: () => void): MenuItem[] => [
    {
        label: 'Mein Account',
        icon: "pi pi-user",
        command: () => {
            myAccountFunction()
        }
    },
    {
        label: 'Einstellungen',
        icon: "pi pi-sliders-h",
        command: () => {
            communicationSettingsFunction()
        }
    },
    {
        label: 'Logout',
        icon: "pi pi-sign-out",
        command: () => {
            logoutFunction()
            onComplete()
        }
    },
];

export const ProfileLink = ({className}: OwnProps) => {
    const currentUser = useTypedSelector(selectCurrentUser)
    const navigate = useNavigate()
    const dispatch = useTypedDispatch()

    const menu = useRef<Menu>()


    return <div className={className}>
        <Button
            className="p-button-text text-700 font-bold text-sm"
            label={extractName(currentUser) + " ▾"}
            icon="pi pi-user"
            onClick={(event) => menu.current!.toggle(event)}/>
        <Menu
            model={
                PROFILE_LINK_MENU_ITEMS(
                    () => {
                        navigate(MY_ACCOUNT_ROUTE)
                    },
                    () => {
                        navigate(COMMUNICATION_SETTINGS_ROUTE)
                    },
                    () => {
                        dispatch(logout())
                    },
                    () => {
                        navigate("/")
                        window.location.reload()
                    }
                )
            }
            popup
            ref={menu as any}/>
    </div>
}

export const extractName = (userData?: CurrentUserData) => {
    const lastName = userData?.user.name || ""
    return [lastName].filter(c => c.length > 0).join(" ")
}
