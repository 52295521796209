import {ReactNode} from "react";
import { Permission } from "../__generated__/CurrentUserSetter_Query.graphql";

export enum RouteAuthState {
    OnlyLoggedIn = "OnlyLoggedIn",
    OnlyLoggedOut = "OnlyLoggedOut",
    Independent = "Independent"
}

export interface SecureRouteDefinition {
    requiredPermissions: Permission[],
    path: string,
    element: ReactNode
    authState: RouteAuthState
}
