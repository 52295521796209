/**
 * @generated SignedSource<<0abd3f4e30192103c6113818f4ee81f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CustomerType = "Business" | "Private" | "%future added value";
export type PaymentMethodType = "Card" | "Giropay" | "IapApple" | "IapGoogle" | "InvoicePartner" | "InvoiceTk" | "MonthlyPartner" | "MonthlyTk" | "Paypal" | "Sepa" | "Sofort" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type usePaymentLogic_OrderFragment$data = {
  readonly billingDetails: {
    readonly city: string;
    readonly company?: string;
    readonly country: string;
    readonly customerType: CustomerType;
    readonly firstName: string;
    readonly houseNumber: string;
    readonly invoiceEmail: string;
    readonly lastName: string;
    readonly postalCode: string;
    readonly street: string;
  } | null;
  readonly cart: {
    readonly selection: {
      readonly selectedProducts: ReadonlyArray<{
        readonly amount: number;
        readonly productId: string;
      }>;
    };
    readonly totals: {
      readonly includingAllDiscounts: {
        readonly netPrice: number;
      };
    };
  } | null;
  readonly id: string;
  readonly selectedPaymentMethod: {
    readonly paymentMethodId: PaymentMethodType;
    readonly stripeData?: {
      readonly paymentIntentSecret: string;
    } | null;
  } | null;
  readonly " $fragmentType": "usePaymentLogic_OrderFragment";
};
export type usePaymentLogic_OrderFragment$key = {
  readonly " $data"?: usePaymentLogic_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"usePaymentLogic_OrderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "usePaymentLogic_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "selectedPaymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentMethodId",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StripeData",
              "kind": "LinkedField",
              "name": "stripeData",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "paymentIntentSecret",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "OneTimeSelectedPaymentMethod",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "billingDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "street",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "houseNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postalCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "country",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceEmail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customerType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "company",
              "storageKey": null
            }
          ],
          "type": "BusinessBillingDetails",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Selection",
          "kind": "LinkedField",
          "name": "selection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SelectedProduct",
              "kind": "LinkedField",
              "name": "selectedProducts",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "productId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CartTotals",
          "kind": "LinkedField",
          "name": "totals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Price",
              "kind": "LinkedField",
              "name": "includingAllDiscounts",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "netPrice",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "58a81577bd822375265d4073df5f663b";

export default node;
