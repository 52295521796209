/**
 * @generated SignedSource<<b6d8005bd652cf6fd945543ae1200a23>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CustomerType = "Business" | "Private" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BillingDetailsForm_OrderFragment$data = {
  readonly billingDetails: {
    readonly customerType: CustomerType;
    readonly " $fragmentSpreads": FragmentRefs<"PrivateForm_BillingDetailsFragment">;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "BillingDetailsForm_OrderFragment";
};
export type BillingDetailsForm_OrderFragment$key = {
  readonly " $data"?: BillingDetailsForm_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingDetailsForm_OrderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingDetailsForm_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "billingDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customerType",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PrivateForm_BillingDetailsFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "c078530e9faca856d5755b4d3e423e16";

export default node;
