import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import React from "react";
import styled, {css} from "styled-components";
import {CurrencyDisplay} from "../../../../components/currency/CurrencyDisplay";
import {
    CartTotalDisplay_CartTotalsFragment$key
} from "../../../../__generated__/CartTotalDisplay_CartTotalsFragment.graphql";

const CART_TOTAL_FRAGMENTS = graphql`
    fragment CartTotalDisplay_CartTotalsFragment on CartTotals {
        withoutDiscounts {
            grossPrice
            netPrice
            taxRatePercentage
        }
        monthlyOptions {
            monthlyRate {
                grossPrice
                netPrice
            }
            rateCount
            downPayment {
                netPrice
                grossPrice
            }
            financedAmount {
                netPrice
                grossPrice
            }
        }
        appliedDiscountPercentage
        appliedMoneyDiscount
        appliedDiscount {
            netPrice
            grossPrice
        }
        includingAllDiscounts {
            grossPrice
            netPrice
            taxRatePercentage
        }
    }
`;

interface OwnProps {
    cartTotalsFragmentRef?: CartTotalDisplay_CartTotalsFragment$key
}

export const CartTotalDisplay = ({cartTotalsFragmentRef}: OwnProps) => {


    const cartTotals = useFragment<CartTotalDisplay_CartTotalsFragment$key>(CART_TOTAL_FRAGMENTS, cartTotalsFragmentRef || null)

    const highestRateCount = cartTotals?.monthlyOptions.map(ct => ct.rateCount).sort((a, b) => b - a).find(() => true)
    const cheapestMonthly = cartTotals?.monthlyOptions.find(ct => ct.rateCount === highestRateCount)

    return cartTotals ? <div className="mb-5">
        {(cartTotals.appliedDiscountPercentage) && <Row>
            <div>Zwischensumme</div>
            <RightColumn><CurrencyDisplay
                value={cartTotals.withoutDiscounts.netPrice as number}/></RightColumn>
        </Row>}
        {cartTotals.appliedDiscount && <><Row>
            <div className="flex flex-column">
                <div className="flex flex-column">
                    {cartTotals.appliedDiscountPercentage &&
                        <div>Totaler Rabatt -{cartTotals.appliedDiscountPercentage}%</div>}
                    {cartTotals.appliedMoneyDiscount &&
                        <div>Totaler Rabatt -{cartTotals.appliedMoneyDiscount}€</div>}
                </div>
            </div>

            <DiscountColumn>-<CurrencyDisplay
                value={cartTotals.appliedDiscount.netPrice as number}/></DiscountColumn>
        </Row>
        </>}
        <TotalRow>
            <div>Gesamtpreis<br/></div>
            <RightColumn>
                <TotalDisplay hasDiscount={(cartTotals.appliedDiscountPercentage as number || 0) > 0}>
                    <CurrencyDisplay value={cartTotals.includingAllDiscounts!.netPrice}/>
                </TotalDisplay>
                {cheapestMonthly && <>Oder ab <CurrencyDisplay
                    value={cheapestMonthly.monthlyRate.netPrice as number}/> monatlich</>}
            </RightColumn>
        </TotalRow>
    </div> : null;
}


const Row = styled.div`
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const TotalRow = styled.div`
  font-weight: bold;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`

interface TotalDisplayProps {
    hasDiscount: boolean
}

const TotalDisplay = styled.div<TotalDisplayProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  };

  ${props => props.hasDiscount && css`
    color: #4ace7f;
  `}

`
const RightColumn = styled.div`
  margin-left: auto;
  text-align: right;
`

const DiscountColumn = styled(RightColumn)`
  color: #ea561c;
  display: flex;
  flex-direction: column;
  justify-content: end;
`
