import graphql from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import {useFormik} from "formik";
import * as Yup from "yup";
import {classNames} from "primereact/utils";
import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {
    ResetPasswordForm_ResetPasswordMutation
} from "../../__generated__/ResetPasswordForm_ResetPasswordMutation.graphql";
import {Password} from "primereact/password";
import {Message} from "primereact/message";
import {Button} from "primereact/button";
import {ValidatedFieldV2} from "../form/ValidatedFieldV2";
import {useTranslation} from "react-i18next";
import styled from "styled-components";


const RESET_PASSWORD_MUTATION = graphql`
    mutation ResetPasswordForm_ResetPasswordMutation($input: ResetPasswordInput!){
        Auth{
            resetPassword(input: $input) {
                clientMutationId
            }
        }
    }
`;

interface FormState {
    password: string
    password2: string
}

interface OwnProps {
    token: string
}

export const ResetPasswordForm = ({token}: OwnProps) => {
    const [resetPassword, isResetting] = useMutation<ResetPasswordForm_ResetPasswordMutation>(RESET_PASSWORD_MUTATION)
    const [passwordReset, setPasswordReset] = useState(false)
    const navigate = useNavigate()
    const {t} = useTranslation('auth')

    const formik = useFormik<FormState>({
        initialValues: {
            password: '',
            password2: ''
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            password: Yup
                .string()
                .min(8, t('reset-password.formik.password-one.validation'))
                .required(t('reset-password.formik.password-one.required')),
            password2: Yup.string()
                .oneOf([Yup.ref("password"), undefined], t('reset-password.formik.password-two.validation')),
        }),
        onSubmit: (data) => {
            resetPassword({
                variables: {
                    input: {
                        token: token,
                        newPassword: data.password!
                    }
                },
                onCompleted: response => {
                    if (response.Auth.resetPassword) {
                        setPasswordReset(true)
                    }
                },
            })
        }
    });

    return passwordReset ? <Message
            className="w-12"
            content={<div>
                <div>{t('reset-password.message')}</div>
                <div className="mt-3 flex justify-content-center">
                    <Button label={t('links.login')} className="p-button-link"
                            onClick={() => navigate("/")}/>
                </div>
            </div>}/> :
        <FormContainer onSubmit={formik.handleSubmit} className="p-fluid">
            <ValidatedFieldV2<FormState, string>
                className="mb-4"
                name={"password"}
                label={t('reset-password.formik.password-one.label')}
                required={true}
                formikConfig={formik}
                component={({fieldValue, updateField, fieldName, isValid}) => {
                    return <Password id={fieldName} name={fieldName} value={fieldValue}
                                     onChange={e => updateField(e.target.value)}
                                     toggleMask
                                     feedback={false}
                                     className={classNames({'p-invalid': !isValid})}
                    />

                }}/>

            <ValidatedFieldV2<FormState, string>
                className="mb-6"
                name={"password2"}
                label={t('reset-password.formik.password-two.label')}
                required={true}
                formikConfig={formik}
                component={({fieldValue, updateField, fieldName, isValid}) => {
                    return <Password id={fieldName} name={fieldName} value={fieldValue}
                                     onChange={e => updateField(e.target.value)}
                                     toggleMask
                                     feedback={false}
                                     className={classNames({'p-invalid': !isValid})}
                    />

                }}/>
            <Button disabled={isResetting} type="submit" label={t('reset-password.formik.submit')} className="p-mt-2"/>

            <div className="mt-5">
                <Button label={t('links.login')} className="p-button-link"
                        onClick={() => navigate("/")}/>
            </div>
        </FormContainer>
}

const FormContainer = styled.form`
  min-width: 300px;
`
