/**
 * @generated SignedSource<<1825b60dac92947690fe1d6cae9bb3fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderItem_OrderFragment$data = {
  readonly cart: {
    readonly items: ReadonlyArray<{
      readonly product?: {
        readonly icon?: {
          readonly url: string | null;
        } | null;
        readonly title?: string;
      } | null;
    }>;
    readonly totals: {
      readonly includingAllDiscounts: {
        readonly grossPrice: number;
      };
    };
  } | null;
  readonly createdAt: string;
  readonly invoiceData: {
    readonly invoiceDataBase64: string;
    readonly invoiceNumber: string;
  } | null;
  readonly " $fragmentType": "OrderItem_OrderFragment";
};
export type OrderItem_OrderFragment$key = {
  readonly " $data"?: OrderItem_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderItem_OrderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderItem_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "product",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "title",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "File",
                          "kind": "LinkedField",
                          "name": "icon",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "url",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "Product",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "CartProduct",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CartTotals",
          "kind": "LinkedField",
          "name": "totals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Price",
              "kind": "LinkedField",
              "name": "includingAllDiscounts",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "grossPrice",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InvoiceData",
      "kind": "LinkedField",
      "name": "invoiceData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceDataBase64",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "4de7d3799a6cad00d32fc77bc6bd8922";

export default node;
