import {useMutation} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import React, {useEffect} from "react";
import {OrderFlowFromCartBase64Screen_CreateOrderMutation} from "../../../__generated__/OrderFlowFromCartBase64Screen_CreateOrderMutation.graphql";
import {CartSelectionInput} from "../../../__generated__/CartStep_UpdateCartMutation.graphql";
import {useMatch, useNavigate} from "react-router-dom";
import {ORDER_FLOW_FROM_CART_BASE} from "../../../routes/BillingModuleRoutes";

export const OrderFlowFromCartBase64Screen = () => {
    const navigate = useNavigate();
    const params = useMatch(ORDER_FLOW_FROM_CART_BASE)

    const [mutation] = useMutation<OrderFlowFromCartBase64Screen_CreateOrderMutation>(graphql`
        mutation OrderFlowFromCartBase64Screen_CreateOrderMutation($createOrderInput: CreateOrderInput!) {
            Billing {
                createOrder(input: $createOrderInput) {
                    order {
                        id
                    }
                }
            }
        }
    `)

    useEffect(() => {
        let cartSelection: CartSelectionInput | undefined = undefined
        if (params?.params["cartBase64"]) {
            try {
                const newState = JSON.parse(atob(params?.params["cartBase64"]))
                if (newState.selectedProducts) {
                    cartSelection = newState
                }
            } catch {
            }
        }

        mutation({
            variables: {
                createOrderInput: {
                    cartSelection: cartSelection ? cartSelection : undefined
                }
            },
            onCompleted: response => {
                if (response.Billing.createOrder?.order.id) {
                    navigate(`/checkout/orders/${response.Billing.createOrder.order.id}`)
                }
            }
        })
        // eslint-disable-next-line
    }, [])

    return <div>
        Lade...
    </div>


}
