/**
 * @generated SignedSource<<558b5804ab63abed71596da44f063ff1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ModuleInformation_PublishedModuleFragment$data = {
  readonly isExam: boolean;
  readonly location: {
    readonly hotel: string | null;
    readonly name: string;
  };
  readonly shortDescription: string;
  readonly trainer: ReadonlyArray<{
    readonly name: string;
  }>;
  readonly " $fragmentType": "ModuleInformation_PublishedModuleFragment";
};
export type ModuleInformation_PublishedModuleFragment$key = {
  readonly " $data"?: ModuleInformation_PublishedModuleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ModuleInformation_PublishedModuleFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModuleInformation_PublishedModuleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Trainer",
      "kind": "LinkedField",
      "name": "trainer",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Location",
      "kind": "LinkedField",
      "name": "location",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hotel",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isExam",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    }
  ],
  "type": "PublishedModuleViewer",
  "abstractKey": null
};
})();

(node as any).hash = "bdb580f6101eb7bef6341caf388fc191";

export default node;
