/**
 * @generated SignedSource<<86671dee5256015420f2716a1e12ba8e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderStatus = "HasBillingDetails" | "HasCart" | "HasPaymentDetails" | "PaymentReceived" | "Purchased" | "Transient" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OrderFlowScreen_OrderFragment$data = {
  readonly billingDetails: {
    readonly " $fragmentSpreads": FragmentRefs<"BillingDetailsStepClosed_BillingDetailsFragment">;
  } | null;
  readonly cart: {
    readonly items: ReadonlyArray<{
      readonly hasProduct?: boolean;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"CartStepClosed_CartFragment" | "CartStep_CartFragment">;
  } | null;
  readonly id: string;
  readonly status: OrderStatus;
  readonly " $fragmentSpreads": FragmentRefs<"BillingDetailsStep_OrderFragment" | "OrderFlowContextProvider_OrderFragment" | "PaymentStepClosed_OrderFragment" | "PaymentStep_OrderFragment" | "ReviewStep_OrderFragment">;
  readonly " $fragmentType": "OrderFlowScreen_OrderFragment";
};
export type OrderFlowScreen_OrderFragment$key = {
  readonly " $data"?: OrderFlowScreen_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderFlowScreen_OrderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderFlowScreen_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CartStep_CartFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CartStepClosed_CartFragment"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasProduct",
                  "storageKey": null
                }
              ],
              "type": "CartProduct",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "billingDetails",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BillingDetailsStepClosed_BillingDetailsFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrderFlowContextProvider_OrderFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingDetailsStep_OrderFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PaymentStep_OrderFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PaymentStepClosed_OrderFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReviewStep_OrderFragment"
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "d119c71089302ed1cc8f2617c8e0d15c";

export default node;
