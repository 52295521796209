import React from 'react';

interface OwnProps {
    trainer: string[]
}

export const TrainerInformation = ({trainer}: OwnProps) => {
    return (
        <div>
            <b>Ausbilder: </b>
            <span>{trainer.join(", ")}</span>
        </div>
    )

}
