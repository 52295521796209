import React from "react";
import {BillingStepContainer} from "./common/BillingStep";

export const AlreadyPurchased = () => {
    return <BillingStepContainer>
        <div className="d-flex flex-row">
            <h3>Nach unseren Informationen hast Du diesen Lehrgang bereits gebucht. Eine erneute Buchung ist nicht
                möglich, bitte wende Dich ggf. direkt an <a
                    href="mailto:betrieb@thekey.community">betrieb@thekey.community</a> oder Deinen
                Ausbildungsberater.</h3>
        </div>

    </BillingStepContainer>
}
