import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import React from "react";
import {CurrencyDisplay} from "../../../../components/currency/CurrencyDisplay";
import styled from "styled-components";
import {CartStepClosed_CartFragment$key} from "../../../../__generated__/CartStepClosed_CartFragment.graphql";


interface OwnProps {
    cartFragmentRef: CartStepClosed_CartFragment$key | null
}

export const CartStepClosed = ({cartFragmentRef}: OwnProps) => {
    const cart = useFragment<CartStepClosed_CartFragment$key>(graphql`
        fragment CartStepClosed_CartFragment on Cart {
            totals {
                includingAllDiscounts {
                    grossPrice
                    netPrice
                }
            }
        }
    `, cartFragmentRef)


    return cart?.totals.includingAllDiscounts?.netPrice ? <ClosedStateContainer>
        Warenwert <CurrencyDisplay value={cart?.totals.includingAllDiscounts?.netPrice as number}/>
    </ClosedStateContainer> : null;
}

const ClosedStateContainer = styled.div`
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  line-height: 1.38;
  color: #184276;
  margin-top: 5px;
`
