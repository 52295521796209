import React from 'react';
import graphql from 'babel-plugin-relay/macro'
import {
    TrainingDayInformation_TrainingDayFragment$key
} from "../../../../__generated__/TrainingDayInformation_TrainingDayFragment.graphql";
import {
    TrainingDayInformation_CourseStateFragment$key
} from "../../../../__generated__/TrainingDayInformation_CourseStateFragment.graphql";
import {useFragment} from "react-relay";
import {DateTimeDisplay} from "../../../../components/datetime/DateTimeDisplay";
import {ATTENDANCE_STATUS} from "../../../../components/keyToValue/ATTENDANCE_STATUS";
import {AdditionalTrainingDayInformationButton} from "./AdditionalTrainingDayInformationButton";
import {useTypedSelector} from "../../../../Store";
import {selectCurrentUser} from "../../../../slices/AuthSlice";

const TRAINING_DAY_FRAGMENT = graphql`
    fragment TrainingDayInformation_TrainingDayFragment on PublishedTrainingDay {
        id
        date

        ... AdditionalTrainingDayInformationButton_TrainingDayFragment
    }
`

const COURSE_STATE_FRAGMENT = graphql`
    fragment TrainingDayInformation_CourseStateFragment on CourseState {
        moduleStates {
            moduleId
            trainingDayStates {
                trainingDayId
                attendanceListViewer {
                    userId
                    attendanceStatus
                }
            }
        }
    }
`


interface OwnProps {
    trainingDayFragmentRef: TrainingDayInformation_TrainingDayFragment$key
    courseStateFragmentRef: TrainingDayInformation_CourseStateFragment$key
    moduleId: string
    index: number
}

export const TrainingDayInformation = ({
                                           index,
                                           moduleId,
                                           trainingDayFragmentRef,
                                           courseStateFragmentRef,
                                       }: OwnProps) => {
    const trainingDay = useFragment<TrainingDayInformation_TrainingDayFragment$key>(TRAINING_DAY_FRAGMENT, trainingDayFragmentRef)
    const courseState = useFragment<TrainingDayInformation_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, courseStateFragmentRef)

    const currentUser = useTypedSelector(selectCurrentUser)

    const attendanceStatus = courseState.moduleStates
        .find(ms => ms.moduleId === moduleId)?.trainingDayStates.find(tds => tds.trainingDayId === trainingDay.id)?.attendanceListViewer
        .find(al => al.userId === currentUser?.user.id)?.attendanceStatus.toString() || ""

    return <div className="flex flex-row mb-6 justify-content-between ">
        <div className="flex flex-column">
            <div className="flex flex-row">
                <b className="mr-1">Tag {index + 1}:</b>
                <DateTimeDisplay value={trainingDay.date.toString()} showTime={false} hideTimezone/>
            </div>
            <div>
                <b className="ml-5 mr-1">- Teilgenommen: </b>
                <span>{ATTENDANCE_STATUS[attendanceStatus]}</span>
            </div>
        </div>
        <AdditionalTrainingDayInformationButton trainingDayFragmentRef={trainingDay}/>
    </div>
}
