import React from "react";
import currency from "currency.js"

interface OwnProps {
    value: number;
}

export const CurrencyDisplay = ({value}: OwnProps) => {
    return <>{currency(value).format({symbol: "", decimal: ",", separator: "."})} EUR</>
}
