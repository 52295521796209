/**
 * @generated SignedSource<<704d0e932b9608d85882b634ec7ad6dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AvailablePaymentDates_OrderFragment$data = {
  readonly cart: {
    readonly paymentDates: ReadonlyArray<string>;
  } | null;
  readonly id: string;
  readonly selectedPaymentDate: string | null;
  readonly " $fragmentType": "AvailablePaymentDates_OrderFragment";
};
export type AvailablePaymentDates_OrderFragment$key = {
  readonly " $data"?: AvailablePaymentDates_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AvailablePaymentDates_OrderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AvailablePaymentDates_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentDates",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selectedPaymentDate",
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "921f786803c3f4a9a4e29aa878a2f014";

export default node;
