import React from 'react';
import graphql from 'babel-plugin-relay/macro'
import {DateSelectionDropDown} from "../../../../components/ui/DateSelectionDropDown";
import {useFragment, useMutation} from "react-relay";
import {
    AvailablePaymentDates_OrderFragment$key
} from "../../../../__generated__/AvailablePaymentDates_OrderFragment.graphql";
import {
    AvailablePaymentDates_SetPaymentDateMutation
} from "../../../../__generated__/AvailablePaymentDates_SetPaymentDateMutation.graphql";
import {toast} from "react-toastify";

const ORDER_FRAGMENT = graphql`
    fragment AvailablePaymentDates_OrderFragment on Order {
        id
        cart {
            paymentDates
        }

        selectedPaymentDate
    }
`

const SET_PAYMENT_DATE = graphql`
    mutation AvailablePaymentDates_SetPaymentDateMutation($input: ChangePaymentDateInput!){
        Billing {
            changePaymentDate(input: $input) {
                order {
                    ...PaymentStep_OrderFragment
                }
            }
        }
    }
`

interface OwnProps {
    orderFragmentRef: AvailablePaymentDates_OrderFragment$key
}

export const AvailablePaymentDates = ({orderFragmentRef}: OwnProps) => {
    const order = useFragment<AvailablePaymentDates_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef)
    const [setPaymentDate] = useMutation<AvailablePaymentDates_SetPaymentDateMutation>(SET_PAYMENT_DATE)

    const selectedPaymentDate = order.selectedPaymentDate || ""
    const availablePaymentDate = order.cart?.paymentDates.map(date => date) || []

    return (
        <div className="mb-4">
            <span className="mr-2"> Wann möchtest du Zahlen? </span>
            <DateSelectionDropDown placeholder="Zahlungsbeginn wählen"
                                   selectedDate={selectedPaymentDate}
                                   setSelectedDate={l => setPaymentDate({
                                       variables: {
                                           input: {
                                               orderId: order.id,
                                               paymentDate: l
                                           }
                                       },
                                       onError: () => {
                                           toast.error("Es ist ein Fehler aufgetreten")
                                       }
                                   })}
                                   dates={availablePaymentDate}/>
        </div>
    )
}
