/**
 * @generated SignedSource<<9679405c324e558dd2369b96dc123b01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CustomerType = "Business" | "Private" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PrivateForm_BillingDetailsFragment$data = {
  readonly city: string;
  readonly country: string;
  readonly customerType: CustomerType;
  readonly dateOfBirth?: string;
  readonly firstName: string;
  readonly houseNumber: string;
  readonly invoiceEmail: string;
  readonly lastName: string;
  readonly postalCode: string;
  readonly salutation: string;
  readonly street: string;
  readonly " $fragmentType": "PrivateForm_BillingDetailsFragment";
};
export type PrivateForm_BillingDetailsFragment$key = {
  readonly " $data"?: PrivateForm_BillingDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PrivateForm_BillingDetailsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PrivateForm_BillingDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salutation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "street",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "houseNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerType",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dateOfBirth",
          "storageKey": null
        }
      ],
      "type": "PrivateBillingDetails",
      "abstractKey": null
    }
  ],
  "type": "BillingDetails",
  "abstractKey": "__isBillingDetails"
};

(node as any).hash = "f2c72e6a528445a32f9dbe65c25fda57";

export default node;
