/**
 * @generated SignedSource<<d0ac13724999a598976d2541021ce365>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CourseItem_CourseFragment$data = {
  readonly endDateForUser: string | null;
  readonly icon: {
    readonly url: string | null;
  } | null;
  readonly id: string;
  readonly name: string;
  readonly secondName: string;
  readonly startDateForUser: string | null;
  readonly " $fragmentType": "CourseItem_CourseFragment";
};
export type CourseItem_CourseFragment$key = {
  readonly " $data"?: CourseItem_CourseFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CourseItem_CourseFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseItem_CourseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "icon",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "secondName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDateForUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDateForUser",
      "storageKey": null
    }
  ],
  "type": "PublishedCourse",
  "abstractKey": null
};

(node as any).hash = "7fa262d4a20054f10b18746477168fdf";

export default node;
