/**
 * @generated SignedSource<<d66369394d56480b2cf5dbe021fd458c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ItemType = "CartBulkDiscount" | "CartDiscount" | "CartProduct" | "%future added value";
export type OrderStatus = "HasBillingDetails" | "HasCart" | "HasPaymentDetails" | "PaymentReceived" | "Purchased" | "Transient" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OrderFlowContextProvider_OrderFragment$data = {
  readonly cart: {
    readonly items: ReadonlyArray<{
      readonly itemType: ItemType;
    }>;
  } | null;
  readonly status: OrderStatus;
  readonly " $fragmentType": "OrderFlowContextProvider_OrderFragment";
};
export type OrderFlowContextProvider_OrderFragment$key = {
  readonly " $data"?: OrderFlowContextProvider_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderFlowContextProvider_OrderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderFlowContextProvider_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "itemType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "625d7567f4c995a38ce34ae7cade51ff";

export default node;
