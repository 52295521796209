import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import {useFragment} from 'react-relay'
import {Card} from "primereact/card";
import {CourseItem_CourseFragment$key} from "../../../__generated__/CourseItem_CourseFragment.graphql";
import styled from "styled-components";
import {DateTimeDisplay} from "../../../components/datetime/DateTimeDisplay";
import {useNavigate} from "react-router-dom";
import {Tooltip} from 'primereact/tooltip';
import {
    CourseItem_CourseStateFragment$key
} from "../../../__generated__/CourseItem_CourseStateFragment.graphql";

const COURSE_FRAGMENT = graphql`
    fragment CourseItem_CourseFragment on PublishedCourse {
        id
        icon {
            url
        }
        name
        secondName
        startDateForUser
        endDateForUser
    }
`

const COURSE_STATE_FRAGMENT = graphql`
    fragment CourseItem_CourseStateFragment on CourseState {
        publishedCourse {
            registeredModules
            modulesViewer {
                id
                title
                startDate
                endDate
                canSeeSignOut
                canSeeAlternativeDates
                isExam
                moduleTemplateId
                trainingDays {
                    id
                    ... TrainingDayInformation_TrainingDayFragment
                }

                ...ModuleInformation_PublishedModuleFragment
                ...SignOutFromModuleButton_PublishedModuleFragment
                ...ExamInformation_PublishedModuleFragment
            }
        }
        moduleStates {
            trainingDayStates {
                attendanceListViewer {
                    userId
                    attendanceStatus
                    attendanceType
                }
            }
        }
    }
`

interface OwnProps {
    courseFragmentRef: CourseItem_CourseFragment$key
    courseStateFragmentRef: CourseItem_CourseStateFragment$key
}

export const CourseItem = ({courseFragmentRef, courseStateFragmentRef}: OwnProps) => {
    const navigate = useNavigate()

    const course = useFragment<CourseItem_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef)
    const courseState = useFragment<CourseItem_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, courseStateFragmentRef)

    return (
        <div data-pr-tooltip="Für weitere Informationen, klicke hier." onClick={() => navigate(`/courses/${course.id}`)}
             className="cursor-pointer tooltip-track">
            <Card title={`${course.name} - ${course.secondName}`} className="m-3">
                <div className="flex flex-row justify-content-between">
                    <Image src={course.icon?.url || ""} className="mr-7"/>
                    <div className="flex flex-column">
                        <div className="flex">
                            <div className="m-3 mt-0 ml-0">
                                <p className="mt-0">
                                    <b>Start:</b>
                                </p>
                                <DateTimeDisplay value={course.startDateForUser?.toString()!} showTime={false}/>
                            </div>
                            <div className="m-3 mt-0">
                                <p className="mt-0">
                                    <b>Ende:</b>
                                </p>
                                <DateTimeDisplay value={course.endDateForUser?.toString()!} showTime={false}/>
                            </div>
                        </div>
                        <RegisteredModuleDisplay>Bei Modulen
                            registriert: {courseState.publishedCourse.registeredModules}</RegisteredModuleDisplay>
                    </div>
                </div>
                <Tooltip target=".tooltip-track" position={"right"}/>
            </Card>
        </div>
    )

}

const Image = styled.img`
  height: 100px;
  max-width: 200px;
`

const RegisteredModuleDisplay = styled.div`
  font-weight: bold;
`

