import React from "react";
import {ValidatedFieldV2} from "../../../../../components/form/ValidatedFieldV2";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import styled from "styled-components";
import {PrivateFormState} from "./PrivateForm";
import {Dropdown} from "primereact/dropdown";
import Countries from "./countries.json";

interface OwnProps {
    formik: any
}

export const DifferentInvoiceAddressFormPart = ({formik}: OwnProps) => {
    return (
        <div>
            <h3 className="tk-academy-large tk-color-waterblue-80 tk-academy-bold mb-2">Rechnungsadresse</h3>
            <FormContainer onSubmit={formik.handleSubmit} className="p-fluid">
                <ValidatedFieldV2<PrivateFormState, string>
                    name={"invoiceCompanyName"}
                    label={"Firmenname"}
                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <InputText id={fieldName} name={fieldName} value={fieldValue}
                                          onChange={e => updateField(e.target.value)}
                                          className={classNames({'p-invalid': !isValid})}/>

                    }}/>
                <ValidatedFieldV2<PrivateFormState, string>
                    name={"invoiceCompanyDetails"}
                    label={"ggf. Kostenstelle"}
                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <InputText id={fieldName} name={fieldName} value={fieldValue}
                                          onChange={e => updateField(e.target.value)}
                                          className={classNames({'p-invalid': !isValid})}/>

                    }}/>
                <ValidatedFieldV2<PrivateFormState, string>
                    name={"invoiceFirstName"}
                    label={"Vorname"}
                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <InputText id={fieldName} name={fieldName} value={fieldValue}
                                          onChange={e => updateField(e.target.value)}
                                          className={classNames({'p-invalid': !isValid})}/>

                    }}/>
                <ValidatedFieldV2<PrivateFormState, string>
                    name={"invoiceLastName"}
                    label={"Nachname"}
                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <InputText id={fieldName} name={fieldName} value={fieldValue}
                                          onChange={e => updateField(e.target.value)}
                                          className={classNames({'p-invalid': !isValid})}/>

                    }}/>

                <ValidatedFieldV2<PrivateFormState, string>
                    name={"invoiceStreet"}
                    label={"Straße"}
                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <InputText id={fieldName} name={fieldName} value={fieldValue}
                                          onChange={e => updateField(e.target.value)}
                                          className={classNames({'p-invalid': !isValid})}/>

                    }}/>
                <ValidatedFieldV2<PrivateFormState, string>
                    name={"invoiceHouseNumber"}
                    label={"Hausnummer"}
                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <InputText id={fieldName} name={fieldName} value={fieldValue}
                                          onChange={e => updateField(e.target.value)}
                                          className={classNames({'p-invalid': !isValid})}/>

                    }}/>
                <ValidatedFieldV2<PrivateFormState, string>
                    name={"invoicePostalCode"}
                    label={"Postleitzahl"}
                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <InputText id={fieldName} name={fieldName} value={fieldValue}
                                          onChange={e => updateField(e.target.value)}
                                          className={classNames({'p-invalid': !isValid})}/>

                    }}/>
                <ValidatedFieldV2<PrivateFormState, string>
                    name={"invoiceCity"}
                    label={"Ort/Stadt"}
                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <InputText id={fieldName} name={fieldName} value={fieldValue}
                                          onChange={e => updateField(e.target.value)}
                                          className={classNames({'p-invalid': !isValid})}/>

                    }}/>
                <ValidatedFieldV2<PrivateFormState, string>
                    name={"invoiceCountry"}
                    label={"Land"}
                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <div>
                            <Dropdown
                                name={fieldName}
                                value={fieldValue}
                                onChange={e => updateField(e.target.value)}
                                options={Object.entries(Countries).map(entry => {
                                    return {
                                        value: entry[0].toLowerCase(),
                                        label: entry[1]
                                    }
                                })}
                                optionValue={"value"}
                                optionLabel={"label"}
                                className={classNames({'p-invalid': !isValid})}
                            />
                        </div>

                    }}/>
            </FormContainer>
        </div>
    )
}


const FormContainer = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`
