import React from 'react'
import styled from "styled-components";

export const Agbs = () => {
    return <div style={{display: "flex", flexDirection: "column"}}>
        <SubHeader>
            1. GELTUNGSBEREICH UND VERTRAGSSPRACHE
        </SubHeader>
        <p>
            1.1. Die folgenden Allgemeinen Geschäftsbedingungen regeln die Vertragsbeziehungen zwischen the key -
            Unternehmensgruppe GmbH (Friedrichstraße 88, 10117 Berlin, HRB-Nummer: 133791 B, Umsatzsteuer-ID-Nummer:
            DE277156764, im Folgenden „the key“ genannt) vertreten durch den Geschäftsführer Philipp A. Scholze und
            dem
            Teilnehmer an Seminaren und Workshops (im Folgenden „Teil- nehmer“ genannt, gemeinsam die
            „Vertragsparteien“
            genannt).
        </p>
        <p>
            1.1. Die folgenden Allgemeinen Geschäftsbedingungen regeln die Vertragsbeziehungen zwischen the key -
            Unternehmensgruppe GmbH (Friedrichstraße 88, 10117 Berlin, HRB-Nummer: 133791 B, Umsatzsteuer-ID-Nummer:
            DE277156764, im Folgenden „the key“ genannt) vertreten durch den Geschäftsführer Philipp A. Scholze und
            dem
            Teilnehmer an Seminaren und Workshops (im Folgenden „Teil- nehmer“ genannt, gemeinsam die
            „Vertragsparteien“
            genannt).
        </p>
        <p>
            1.2. Abweichende oder ergänzende Geschäftsbedingungen des Teilnehmers werden nicht Vertragsbestandteil,
            es
            sei
            denn, the key stimmt ihrer Geltung ausdrücklich zu.
        </p>
        <p>
            1.3. Die für den Vertragsschluss zur Verfügung stehende Sprache ist ausschließlich Deutsch.
            Übersetzungen
            dieser
            Geschäftsbedin- gungen in andere Sprachen dienen lediglich Ihrer Information. Bei etwaigen Unterschieden
            zwischen den Sprachfassungen hat der deutsche Text Vorrang.
        </p>
        <SubHeader>
            2. VERTRAGSSCHLUSS
        </SubHeader>
        <p>
            Der Vertrag kommt durch die Annahme des von the key zugesandten Antrages auf Durchführung des Kurses
            zustande.
            Der Interessent füllt das auf der Website enthaltene Kontaktformular aus, woraufhin eine Kontaktaufnahme
            durch
            the key sowie die Zusendung eines Links erfolgt, über den das Antragsformular versandt wird. Dieses wird
            vom
            Interessenten ausgefüllt, z.B. mit Adobe Sign digital unterschrieben und per E-Mail an the key
            verschickt.
            Das
            Absenden des ausgefüllten Anmeldeformulars führt zu einem Vertragsschluss zwischen dem Teilnehmer und
            the
            key.
            Der Teilnehmer erhält eine Anmeldebestäti- gung sowie eine Rechnung für den gebuchten Kurs.
        </p>
        <SubHeader>
            3. WIDERRUFSRECHT FÜR VERBRAUCHER BEI FERNABSATZVERTRÄGEN
        </SubHeader>
        <p>
            Verbrauchern steht bei Fernabsatzverträgen ein gesetzliches Widerrufsrecht zu. Verbraucher ist jede
            natürliche
            Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch
            ihrer
            selbständigen beruflichen Tätigkeit zugerechnet werden können.

        </p>
        <Header>
            WIDERRUFSBELEHRUNG
        </Header>
        <SubHeader>
            WIDERRUFSRECHT
        </SubHeader>
        <p>
            Sie haben das Recht, diesen Vertrag binnen vierzehn Tagen ohne Angabe von Gründen zu widerrufen.
        </p>
        <SubHeader>
            WIDERRUFSFRIST
        </SubHeader>
        <p>
            Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertrags- schlusses. Um Ihr Widerrufsrecht
            auszuüben,
            müssen Sie uns,
            <b>
                the key – Unternehmensgruppe GmbH, Friedrichstraße 88, 10117 Berlin, Telefon: 030/86200066, Telefax:
                030408173450, E-Mail: <a href="mailto:kontakt@thekey.community">kontakt@thekey.community</a>
            </b>
            , mittels einer eindeutigen Erklärung (z.B. E-Mail, Telefax,
            Brief
            oder Telefonanruf) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür
            das
            beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrie- ben ist. Zur Wahrung der
            Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf
            der
            Widerrufsfrist absenden.
        </p>
        <SubHeader>
            FOLGEN DES WIDERRUFS
        </SubHeader>
        <p>
            1. Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlun- gen, die wir von Ihnen erhalten
            haben,
            einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie
            eine
            andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben),
            unverzüglich
            und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen,
            an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung
            verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es
            sei
            denn,
            mit Ihnen wurde ausdrücklich etwas anderes vereinbart. In keinem Fall werden Ihnen wegen dieser
            Rückzahlung
            Entgelte berechnet.
        </p>
        <p>
            2. Haben wir mit der Dienstleistung auf Ihr Verlangen hin bereits während der Widerrufsfrist begonnen,
            so
            haben
            Sie uns einen ange- messenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns
            von
            der
            Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten
            Dienstleistungen
            im
            Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienst- leistungen entspricht.
        </p>
        <SubHeader>
            BESONDERER HINWEIS
        </SubHeader>
        <p>
            Ihr Widerrufsrecht erlischt mit Ihrer Zustimmung vorzeitig, wenn der Vertrag von beiden Seiten auf Ihren
            ausdrücklichen Wunsch vollständig erfüllt ist, bevor Sie Ihr Widerrufsrecht ausgeübt haben.
        </p>
        <SubHeader>
            4. VERHINDERUNG DES TEILNEHMERS
        </SubHeader>
        <p>
            Dem Teilnehmer wird die Möglichkeit eingeräumt, bis zu drei Ausbildungsmodule seines Lehrplans nicht
            wahrzunehmen und zu einem späteren Zeitpunkt in einem der anderen Ausbildungszyklen nachzuholen. Punkt
            6.
            bleibt
            unberührt. Insbesondere wird der Ausbil- dungspreis mit der Anmeldung fällig.
        </p>
        <SubHeader>
            5. VERHINDERUNGEN SEITENS THE KEY
        </SubHeader>
        <p>
            5.1. The key behält sich das Recht vor, den Ausbildungsbeginn um bis zu 6 Monate zu verschieben. Der
            Teilnehmer
            wird in diesem Fall unverzüglich in Textform benachrichtigt.
        </p>
        <p>
            5.2. Ebenfalls behält sich the key das Recht vor, aus organisatorischen Gründen, insbesondere im Falle
            des
            gesundheitlichen Ausfalls des Referenten, oder infolge höherer Gewalt die Workshops abzusagen. Der
            Teilnehmer
            wird hierüber unverzüglich in Textform benachrichtigt. Der Workshop kann zu einem anderen Zeitpunkt
            nachgeholt
            werden. Für Schäden, die dem Teilnehmer durch eine Absage seitens the key entstehen, kommt the key nur
            unter
            den
            Voraussetzungen und in den Grenzen der Bestimmungen des Abschnitts „Haftung“ auf.
        </p>
        <p>
            5.3. Geringfügige Änderungen im Ausbildungsprogramm werden vorbehalten.
        </p>
        <p>
            5.4. Es besteht kein Anspruch auf Durchführung der Ausbildung an dem in der Broschüre genannten
            Ausbildungsort.
            The key wird sich im Fall der notwendigen Abweichung von dem geplanten Ausbil- dungsort um einen
            vergleichbaren
            Ausbildungsort, der nicht weiter als 35 km Luftlinie von dem geplanten Ausbildungsort entfernt liegt,
            bemühen.
        </p>
        <SubHeader>
            6. ZAHLUNGSBEDINGUNGEN UND RATEN- FINANZIERUNG DER AUSBILDUNGSKOSTEN
        </SubHeader>
        <p>
            6.1. Der Ausbildungspreis wird sofort mit der Anmeldung fällig. Die Zahlung des Ausbildungspreises
            erfolgt
            durch
            Banküberweisung oder mit Zustimmung des Teilnehmers durch Einziehung von dem Konto des Teilnehmers
            mittels
            Lastschrift. Die Bankverbindung von the key lautet: Landesbank Berlin, IBAN: DE57 1005 0000 01907316 80,
            BIC:
            BELADEBEXXX. Sofern aufgrund fehlender Deckung des Bankkontos des Teilnehmers eine Rücklastschrift
            erforderlich
            wird, ist the key berechtigt, dem Teilnehmer die hierfür tatsächlich ent- standenen Kosten der
            Rücklastschrift
            in Rechnung zu stellen. Eine Rechnung wird dem Teilnehmer per E-Mail übermittelt.
        </p>
        <p>
            6.2. Ist zwischen den Vertragsparteien Ratenfinanzierung hinsichtlich der Ausbildungskosten vereinbart
            worden,
            steht the key bei begrün- deten Zweifeln an der Bonität des Teilnehmers ein Rücktrittsrecht bis zur
            Zahlung
            der
            ersten Finanzierungsrate zu. Zahlt der Teilnehmer drei aufeinanderfolgende Finanzierungsraten nicht, so
            kann
            the
            key die noch ausstehenden Finanzierungsraten verlangen.
        </p>
        <p>
            6.3. Im Falle eines Anstiegs des durch das statistische Bundesamt ermittelten Verbraucherpreisindex von
            mehr
            als
            2,5 % zum Vorjah- resmonat behält sich die the key – Unternehmensgruppe GmbH das Recht vor, diese
            Teuerungsrate
            auf die im Bewertungsmonat verbleibende Restschuld des Teilnehmers umzulegen und deren Wert anzupassen.
        </p>
        <SubHeader>
            7. HAFTUNGSBESCHRÄNKUNG
        </SubHeader>
        <p>
            7.1. The key haftet für Vorsatz und grobe Fahrlässigkeit. Ferner haftet the key für die fahrlässige
            Verletzung
            von Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglichen,
            deren
            Verletzung die Erreichung des Vertragszwecks gefährdet und auf deren Einhaltung der Teilnehmer
            regelmäßig
            ver-
            trauen darf. Im letztgenannten Fall haftet the key jedoch nur für den vorhersehbaren, vertragstypischen
            Schaden.
            The key haftet nicht für die leicht fahrlässige Verletzung anderer als der in den vorstehenden Sätzen
            genannten
            Pflichten. Die vorstehenden Haftungsausschlüsse gelten nicht bei Verletzung von Leben, Körper und
            Gesundheit.
        </p>
        <p>
            7.2. Die Datenkommunikation über das Internet kann nach dem derzeitigen Stand der Technik nicht
            fehlerfrei
            und/oder jederzeit verfügbar gewährleistet werden. The key haftet insoweit nicht für die ständige und
            ununterbrochene Verfügbarkeit der Homepage.
        </p>
        <SubHeader>
            8. DATENSCHUTZ
        </SubHeader>
        <p>
            8.1. Zur Durchführung und Abwicklung des Vertrages benötigt the key von dem Teilnehmer die folgenden
            Daten:
        </p>
        <p>
            <List>
                <li>
                    <ListArrow>»</ListArrow>
                    Vor- und Nachname
                </li>
                <li>
                    <ListArrow>»</ListArrow>
                    Geburtsdatum
                </li>
                <li>
                    <ListArrow>»</ListArrow>
                    Berufsbezeichnung
                </li>
                <li>
                    <ListArrow>»</ListArrow>
                    Branche
                </li>
                <li>
                    <ListArrow>»</ListArrow>
                    Telefonnummer
                </li>
                <li>
                    <ListArrow>»</ListArrow>
                    E-Mail-Adresse
                </li>
                <li>
                    <ListArrow>»</ListArrow>
                    Anschrift
                </li>
            </List>
        </p>
        <p>
            8.2. Die von dem Teilnehmer mitgeteilten Daten verwendet the key ohne gesonderte Einwilligung des
            Teilnehmers
            ausschließlich zur Erfüllung und Abwicklung des Vertrages. The key verwendet auch die
            Bankverbindungsdaten
            des
            Teilnehmers zur Zahlungsabwick- lung. Eine darüber hinausgehende Nutzung der personenbezoge- nen Daten
            für
            Zwecke der Werbung, der Marktforschung oder zur bedarfsgerechten Gestaltung der Angebote bedarf der
            ausdrück-
            lichen Einwilligung des Teilnehmers. Dieser hat die Möglichkeit, diese Einwilligung vor Erklärung der
            Anmeldung
            zu erteilen. Diese Einwilligungserklärung erfolgt völlig freiwillig und kann jederzeit widerrufen
            werden.
        </p>
        <p>
            8.3. Die von dem Teilnehmer mitgeteilten Daten werden gelöscht, so- bald deren Speicherung für die
            Erfüllung
            des
            ursprünglichen Zwecks nicht mehr erforderlich ist und keine gesetzlichen Aufbewahrungs- fristen mehr
            bestehen.
            Die gesetzlichen Aufbewahrungsfristen bilden letztlich das Kriterium für die endgültige Dauer der
            Speicherung
            von personenbezogenen Daten. Nach Ablauf der Frist werden die entsprechenden Daten routinemäßig
            gelöscht.
            Beim
            Bestehen von Aufbewahrungsfristen erfolgt eine Einschränkung der Verarbeitung in Form der Sperrung der
            Daten.
        </p>
        <p>
            8.4. Soweit sich die persönlichen Angaben des Teilnehmers ändern, hat er dies the key unverzüglich
            mitzuteilen.
        </p>
        <p>
            8.5. Weitere Informationen zur Datenerhebung können der Daten- schutzerklärung entnommen werden.
        </p>
        <SubHeader>
            9. SCHLUSSBESTIMMUNGEN
        </SubHeader>
        <p>
            9.1. Änderungen, Ergänzungen und die Aufhebung dieses Vertrages bedürfenzuihrer
            WirksamkeitderSchriftform.Diesgiltauchfürdie Änderung dieser Schriftformklausel selbst.
        </p>
        <p>
            9.2. Mündliche Nebenabreden bestehen nicht.
        </p>
        <p>
            9.3. The key kann diese AGB jederzeit ohne die Angabe von Gründen schriftlich oder per E-Mail für die
            Zukunft
            ändern. Die geänderten AGB gelten mit dem von the key bestimmten Datum des Inkraft- tretens als
            anerkannt,
            sofern der Teilnehmer diesen nicht innerhalb von 6 Wochen nach Zugang der Mitteilung widerspricht. Auf
            das
            Widerspruchsrecht und die Rechtsfolgen des Schweigens wird der Teilnehmer im Falle der Änderung der
            Geschäftsbedingungen noch gesondert hingewiesen.
        </p>
        <p>
            9.4. Es gilt das Recht der Bundesrepublik Deutschland. Für den
            Fall, dass der Teilnehmer seinen gewöhnlichen Aufenthalt in einem Mitgliedsland der Europäischen Union
            hat,
            gilt
            ebenfalls die Anwend- barkeit des deutschen Rechts, wobei zwingende Bestimmungen des Staates, in dem der
            Teilnehmer seinen gewöhnlichen Aufenthalt hat, unberührt bleiben.
        </p>
        <p>
            9.5. Soweit der Teilnehmer bei Abschluss des Vertrages seinen Wohnsitz oder gewöhnlichen Aufenthalt in
            Deutschland hatte und entweder zum Zeitpunkt der Klageerhebung durch the key aus Deutschland verlegt hat
            oder
            seinen Wohnsitz oder sein gewöhnli- cher Aufenthaltsort zu diesem Zeitpunkt unbekannt ist, ist Gerichts-
            stand
            für alle Streitigkeiten der Sitz von the key in Berlin.
        </p>
        <p>
            9.6. The key weist darauf hin, dass der Teilnehmer neben dem ordent- lichen Rechtsweg auch die
            Möglichkeit
            einer
            außergerichtlichen Bei- legung von Streitigkeiten gemäß Verordnung (EU) Nr. 524/2013 hat. Einzelheiten
            dazu
            finden sich in Verordnung (EU) Nr. 524/2013 und
            unter der Internetadresse: http://ec.europa.eu/consumers/odr. Die E-Mail-Adresse von the key lautet:
            <a href="mailto:kontakt@thekey.community">kontakt@thekey.community</a>. The key weist nach § 36 VSBG darauf hin, dass the key nicht verpflichtet ist,
            an
            einem
            außergerichtlichen Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
        <p>
            9.7. Sollten einzelne Bestimmungen dieses Vertrages ganz oder teilweise unwirksam sein oder werden,
            bleibt
            die
            Wirksamkeit der übrigen Bestimmungen unberührt. Die Vertragsparteien sind im Falle einer unwirksamen
            Bestimmung
            verpflichtet, über eine wirksame und zumutbare Ersatzregelung zu verhandeln, die dem von den Vertrags-
            parteien
            mit der unwirksamen Bestimmung verfolgten wirtschaftli- chen Zweck möglichst nahe kommt.
        </p>
    </div>
}

const Header = styled.h2`
  color: #3b7cb3 !important;
  margin: 2rem 0;
  
`
const SubHeader = styled.h3`
  margin-top: 2rem;
  color: #3b7cb3 !important;
`

const List = styled.ul`
  list-style-type: none;
`

const ListArrow = styled.span`
  color: #3b7cb3 !important;
  margin-right: 1rem

`
