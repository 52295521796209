/**
 * @generated SignedSource<<c53a73d7e91f00b817cae5f2086fce9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ForgotPasswordInput = {
  clientMutationId?: string | null;
  email: string;
};
export type ForgotPasswordForm_ForgotPasswordMutation$variables = {
  input: ForgotPasswordInput;
};
export type ForgotPasswordForm_ForgotPasswordMutation$data = {
  readonly Auth: {
    readonly forgotPassword: {
      readonly clientMutationId: string | null;
    } | null;
  };
};
export type ForgotPasswordForm_ForgotPasswordMutation = {
  response: ForgotPasswordForm_ForgotPasswordMutation$data;
  variables: ForgotPasswordForm_ForgotPasswordMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuthMutations",
    "kind": "LinkedField",
    "name": "Auth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "ForgotPasswordPayload",
        "kind": "LinkedField",
        "name": "forgotPassword",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientMutationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ForgotPasswordForm_ForgotPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ForgotPasswordForm_ForgotPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d4cb05a92ab07e4f701fd0918feee8c4",
    "id": null,
    "metadata": {},
    "name": "ForgotPasswordForm_ForgotPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation ForgotPasswordForm_ForgotPasswordMutation(\n  $input: ForgotPasswordInput!\n) {\n  Auth {\n    forgotPassword(input: $input) {\n      clientMutationId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5035cfb07c5cd180adc9346af7602cd2";

export default node;
