import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {Button} from "primereact/button";
import {useFormik} from "formik";
import {ChangeEmailForm_ChangeEmailMutation} from "../../../../../__generated__/ChangeEmailForm_ChangeEmailMutation.graphql";
import {ChangeEmailForm_UserFragment$key} from "../../../../../__generated__/ChangeEmailForm_UserFragment.graphql";
import {ValidatedField} from "../../../../../components/form/ValidatedField";
import {RenderConfig} from "../../../../../components/form/ValidatedFieldV2";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import * as Yup from "yup";
import {toast} from "react-toastify";

const USER_FRAGMENT = graphql`
    fragment ChangeEmailForm_UserFragment on User {
        id
        email
    }
`;

const UPDATE_META_VALUE_MUTATION = graphql`
    mutation ChangeEmailForm_ChangeEmailMutation($input: ChangeEmailInput!) {
        Auth {
            changeEmail(input: $input){
                clientMutationId
            }
        }
    }
`

interface OwnProps {
    userFragmentRef: ChangeEmailForm_UserFragment$key
}

interface FormState {
    value: string
}

export const ChangeEmailForm = ({userFragmentRef}: OwnProps) => {
    const user = useFragment<ChangeEmailForm_UserFragment$key>(USER_FRAGMENT, userFragmentRef)
    const [changeEmail, isInFlight] = useMutation<ChangeEmailForm_ChangeEmailMutation>(UPDATE_META_VALUE_MUTATION)
    const formik = useFormik<FormState>({
        validationSchema: Yup.object().shape({
            value: Yup
                .string()
                .required(""),
        }),
        initialValues: {
            value: user.email
        },
        onSubmit: values => {
            changeEmail({
                variables: {
                    input: {
                        newEmail: values.value
                    }
                },
                onCompleted: () => {
                    toast.success("E-Mail wurde erfolgreich geändert")
                },
                onError: () => {
                    toast.error("Fehler beim ")
                }
            })
        }
    })

    return <form className="field grid" onSubmit={formik.handleSubmit}>
        <label className="col-fixed font-bold" style={{width: 150}}>
            E-Mail
        </label>
        <div className="col">
            <ValidatedField
                className="mb-0"
                name={"value"}
                formikConfig={formik}
                component={
                    ({fieldName, fieldValue, updateField, isValid}: RenderConfig<string>) => {
                        return (
                            <InputText id={fieldName} name={fieldName} value={fieldValue}
                                       onChange={e => updateField(e.target.value)}
                                       placeholder={"Neue E-Mail"}
                                       className={`${classNames({"p-invalid": !isValid})} w-20rem h-3rem`}/>
                        )
                    }}/>
        </div>
        <div className="flex align-items-center">
            <Button
                type="submit"
                disabled={isInFlight || user.email === formik.values.value}
                className="h-3rem" icon={"pi pi-save"}/>
        </div>

    </form>
}
