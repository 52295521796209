import {PROFILE_LINK_MENU_ITEMS, ProfileLink} from "./ProfileLink";
import community from '../../assets/images/community.png'
import {NavLink, useNavigate} from "react-router-dom";
import {Menu} from "primereact/menu";
import { Button } from "primereact/button";
import {useRef} from "react";
import {MAIN_MENU_ITEMS} from "./MainMenu";
import {logout} from "../../slices/AuthSlice";
import {useTypedDispatch} from "../../Store";
import {COMMUNICATION_SETTINGS_ROUTE, MY_ACCOUNT_ROUTE} from "../../routes/SettingModuleRoutes";

interface OwnProps {
    className?: string
}

export const MainTopBar = ({className}: OwnProps) => {
    const navigate = useNavigate()
    const dispatch = useTypedDispatch()

    const menuRef = useRef<Menu | null>(null)

    return <div
        className={`border-bottom-1 border-300 p-3 flex white-background align-items-center ${className ? className : ""}`}>
        <div className="p-4 block md:hidden flex-grow-1 flex">
            <NavLink className="flex align-items-center" to="/">
                <img alt="Community" width={200} src={community}/>
            </NavLink>

            <Menu ref={menuRef} popup={true} model={[...MAIN_MENU_ITEMS(false), ...PROFILE_LINK_MENU_ITEMS(
                () => {
                    navigate(MY_ACCOUNT_ROUTE)
                },
                () => {
                    navigate(COMMUNICATION_SETTINGS_ROUTE)
                },
                () => {
                    dispatch(logout())
                },
                () => {
                    navigate("/")
                }
            )]}/>
            <Button className="ml-auto" icon="pi pi-align-justify" onClick={e => menuRef.current?.toggle(e)}/>
        </div>
        <div className=" ml-auto hidden md:block">
            <ProfileLink />
        </div>
    </div>
}
