import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import coreDE from "./core.de.json";
import authDE from "./auth.de.json";
import billing from './billing.de.json'
import courseInformation from './course-information.de.json'

// the translations
const resources = {
    de: {
        core: coreDE,
        billing: billing,
        auth: authDE,
        courseInformation: courseInformation
    }
};


i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "de",
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });

i18n.changeLanguage("de");
