import React, {useEffect, useRef} from "react";
import {MonthlyPaymentCategory} from "./MonthlyPaymentCategory";
import {OneTimePaymentMethodCategory} from "./OneTimePaymentMethodCategory";
import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import styled from "styled-components";
import {AvailablePaymentDates} from "./AvailablePaymentDates";
import {PaymentStep_OrderFragment$key} from "../../../../__generated__/PaymentStep_OrderFragment.graphql";
import {EmployerInvoicePaymentMethodCategory} from "./EmployerInvoicePaymentMethodCategory";

const ORDER_FRAGMENT = graphql`
    fragment PaymentStep_OrderFragment on Order {
        selectedPaymentMethod {
            paymentMethodId
            selectedPaymentMethodType
        }
        ...AvailablePaymentDates_OrderFragment
        ...MonthlyPaymentCategory_OrderFragment
        ...OneTimePaymentMethodCategory_OrderFragment
        ...EmployerInvoicePaymentMethodCategory_OrderFragment
    }
`

interface OwnProps {
    orderFragmentRef: any
}

export const PaymentStep = ({orderFragmentRef}: OwnProps) => {
    const order = useFragment<PaymentStep_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef)

    const ref = useRef<any>(null)

    useEffect(() => {
        ref.current?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }, [])

    return <PaymentContainer ref={ref}>
        <Separator/>

        <Heading>Wie möchtest du bezahlen?</Heading>

        <AvailablePaymentDates orderFragmentRef={order}/>

        <OneTimePaymentMethodCategory orderFragmentRef={order}/>

        <MonthlyPaymentCategory orderFragmentRef={order}/>

        <EmployerInvoicePaymentMethodCategory orderFragmentRef={order}/>

    </PaymentContainer>
}

export const Separator = styled.hr`
  margin-top: 25px;
  border-top: solid 1px #e7ebf1;
`

const Heading = styled.h3`
  font-size: 24px;
  line-height: 1.42;
  color: #184276;
  margin-bottom: 25px;

  @media (max-width: 1024px) {
    font-size: 18px;
  }
`

const PaymentContainer = styled.div`
  width: 100%;
  position: relative;
  scroll-margin: 50px;
`
