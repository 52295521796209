import React from "react";
import {useFragment, useMutation} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {
    PaymentOptionCategoryContainer,
    PaymentOptionHeaderContainer,
    PaymentOptionHeading,
    PaymentOptionHeadingPrice,
} from "./common/StyledComponents";
import {LoadingOverlay} from "../cart/LoadingOverlay";
import {CurrencyDisplay} from "../../../../components/currency/CurrencyDisplay";
import {
    EmployerInvoicePaymentMethodCategory_OrderFragment$key
} from "../../../../__generated__/EmployerInvoicePaymentMethodCategory_OrderFragment.graphql";
import {
    EmployerInvoicePaymentMethodCategory_ChangePaymentMethodMutation
} from "../../../../__generated__/EmployerInvoicePaymentMethodCategory_ChangePaymentMethodMutation.graphql";

const ORDER_FRAGMENT = graphql`
    fragment EmployerInvoicePaymentMethodCategory_OrderFragment on Order {
        id
        selectedPaymentMethod {
            paymentMethodId
            selectedPaymentMethodType
        }
        cart {
            totals {
                includingAllDiscounts{
                    netPrice
                    grossPrice
                }
            }
        }
    }
`;

const CHANGE_PAYMENT_METHOD_MUTATION = graphql`
    mutation EmployerInvoicePaymentMethodCategory_ChangePaymentMethodMutation($input: ChangePaymentMethodInput!) {
        Billing {
            changePaymentMethod(input: $input) {
                order {
                    ...OrderFlowScreen_OrderFragment
                }
            }
        }
    }
`;

interface OwnProps {
    orderFragmentRef: EmployerInvoicePaymentMethodCategory_OrderFragment$key
}

export const EmployerInvoicePaymentMethodCategory = ({orderFragmentRef}: OwnProps) => {
    const order = useFragment<EmployerInvoicePaymentMethodCategory_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef)
    const [changePaymentMethod, isInFlight] = useMutation<EmployerInvoicePaymentMethodCategory_ChangePaymentMethodMutation>(CHANGE_PAYMENT_METHOD_MUTATION)

    const isOpen = order.selectedPaymentMethod?.selectedPaymentMethodType === "EmployerInvoice"

    return <>
        <PaymentOptionCategoryContainer onClick={() => {
            if (!isOpen) {
                changePaymentMethod({
                    variables: {
                        input: {
                            orderId: order.id,
                            paymentType: "EmployerInvoice",
                            paymentMethodType: "InvoiceTk",
                        }
                    }
                })
            }
        }}>
            <PaymentOptionHeaderContainer>
                <PaymentOptionHeading>Zahlung per Rechnungslegung an den Arbeitgeber</PaymentOptionHeading>

                {order.cart?.totals.includingAllDiscounts && <PaymentOptionHeadingPrice>
                    einmalig <CurrencyDisplay value={order.cart.totals.includingAllDiscounts.netPrice}/>
                </PaymentOptionHeadingPrice>}
            </PaymentOptionHeaderContainer>
            {isOpen && <div>Hierzu wird nach dem Kauf eine Kostenübernahmeerklärung per Mail zugesandt, welche durch den Arbeitgeber auszufüllen ist.</div>}

        </PaymentOptionCategoryContainer>

        {isInFlight && <LoadingOverlay/>}
    </>
}
