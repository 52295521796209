/**
 * @generated SignedSource<<2afd3d0a453228c7152c9ffe9c7514be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RefusalReason = "DuplicatedSystemCode" | "DuplicatedUserCode" | "Expired" | "OverLimit" | "OverUserLimit" | "WrongAccount" | "WrongProducts" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CodeRefusalExplanation_RefusalsFragment$data = {
  readonly discountCodeRefusals: ReadonlyArray<{
    readonly code: string;
    readonly refusalReason: RefusalReason;
    readonly title: string;
  }>;
  readonly " $fragmentType": "CodeRefusalExplanation_RefusalsFragment";
};
export type CodeRefusalExplanation_RefusalsFragment$key = {
  readonly " $data"?: CodeRefusalExplanation_RefusalsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CodeRefusalExplanation_RefusalsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CodeRefusalExplanation_RefusalsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Refusal",
      "kind": "LinkedField",
      "name": "discountCodeRefusals",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "refusalReason",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Cart",
  "abstractKey": null
};

(node as any).hash = "a796957b71a184e45d14dc9a6ba9a9ec";

export default node;
