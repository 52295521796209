import React, {ReactNode, useContext} from "react";
import {FLOW_STAGES, FlowStage, OrderFlowContext} from "../../hooks/OrderFlowContext";
import styled, {css} from "styled-components";

interface OwnProps {
    renderOpen: () => ReactNode;
    renderClosed?: () => ReactNode;

    stage: FlowStage;
}

export const BillingStep = ({
                                renderOpen,
                                stage,
                                renderClosed
                            }: OwnProps) => {

    const {openStage, goToStage, tryAdvance} = useContext(OrderFlowContext)
    const isOpen = openStage === stage;

    let title = "";
    switch (stage) {
        case "cart":
            title = "Warenkorb"
            break
        case "billing":
            title = "Rechnungsdaten"
            break;
        case "payment":
            title = "Bezahlmethode"
            break;
        case "review":
            title = "Übersicht und Zahlung"
            break;
    }
    const hasEditLink = FLOW_STAGES.indexOf(stage) < FLOW_STAGES.indexOf(openStage)
    const isLocked = FLOW_STAGES.indexOf(stage) > FLOW_STAGES.indexOf(openStage)
    const isCurrent = stage === openStage
    const isPassed = !isLocked && !isCurrent

    return <BillingStepContainer isClickable={isLocked || isPassed} onClick={() => {
        if (isLocked) {
            tryAdvance()
        } else if (isPassed) {
            goToStage(stage);
        }
    }
    }>
        <BillingStepHeader>
            <BillingStepIcon isLocked={isLocked} isCurrent={isCurrent}
                             className={isLocked ? "icon-a_lock" : isCurrent ? "icon-a_forward_circle" : "icon-a_tick_circle"}/>
            <BillingStepHeading>
                {title}
            </BillingStepHeading>
            {hasEditLink && <EditLink onClick={() => {
                goToStage(stage)
            }}>bearbeiten</EditLink>}
        </BillingStepHeader>
        {isOpen ? <div className="d-flex flex-row">
            {renderOpen()}
        </div> : null}
        {isPassed && renderClosed ? <div className="d-flex flex-row">
            {renderClosed()}
        </div> : null}
    </BillingStepContainer>
}

interface BillingStepContainerProps {
    isClickable?: boolean
}

export const BillingStepContainer = styled.div<BillingStepContainerProps>`
  padding: 25px;
  border-radius: 6px;
  border: solid 1px #e7ebf1;
  margin-bottom: 25px;

  @media (max-width: 1024px) {
    padding: 15px;
  };

  ${props => props.isClickable && css`
    cursor: pointer;
  `}
`

export const BillingStepHeader = styled.div`
  display: flex;
  align-items: center;
`

interface IconProps {
    isLocked: boolean
    isCurrent: boolean
}

export const BillingStepIcon = styled.span<IconProps>`
  display: flex;
  align-items: center;
  margin-right: 10px;

  ${props => !props.isLocked && css`
    color: #4ACE7F;
  `}
  ${props => props.isLocked && css`
    color: #a2b3c8;
  `}
  ${props => props.isCurrent && css`
    color: #247ac7;
  `}
`

const EditLink = styled.a`
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: left;
  color: #247dc5;
  margin-left: auto;
`

export const BillingStepHeading = styled.div`
  font-family: "Nunito Sans", sans-serif;
  font-size: 20px;
  font-weight: 800;
  line-height: 1.2;
  color: #184276;

`

