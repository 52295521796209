/**
 * @generated SignedSource<<f04695bca521c68ceb0beb36894b1682>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PaymentMethodType = "Card" | "Giropay" | "IapApple" | "IapGoogle" | "InvoicePartner" | "InvoiceTk" | "MonthlyPartner" | "MonthlyTk" | "Paypal" | "Sepa" | "Sofort" | "%future added value";
export type SelectedPaymentMethodType = "EmployerInvoice" | "MonthlyPayment" | "OneTimePayment" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ReviewCartTotalsDisplay_OrderFragment$data = {
  readonly cart: {
    readonly totals: {
      readonly appliedDiscountPercentage: number | null;
      readonly includingAllDiscounts: {
        readonly grossPrice: number;
        readonly netPrice: number;
        readonly taxRatePercentage: number;
      };
      readonly withoutDiscounts: {
        readonly grossPrice: number;
        readonly netPrice: number;
        readonly taxRatePercentage: number;
      };
    };
  } | null;
  readonly selectedPaymentMethod: {
    readonly chosenOption?: {
      readonly closingRate: {
        readonly netPrice: number;
      };
      readonly downPayment: {
        readonly grossPrice: number;
        readonly netPrice: number;
        readonly taxRatePercentage: number;
      };
      readonly financedAmount: {
        readonly grossPrice: number;
        readonly netPrice: number;
        readonly taxRatePercentage: number;
      };
      readonly monthlyRate: {
        readonly grossPrice: number;
        readonly netPrice: number;
        readonly taxRatePercentage: number;
      };
      readonly rateCount: number;
      readonly totalAmount: {
        readonly grossPrice: number;
        readonly netPrice: number;
        readonly taxRatePercentage: number;
      };
    };
    readonly paymentMethodId: PaymentMethodType;
    readonly selectedPaymentMethodType: SelectedPaymentMethodType;
  } | null;
  readonly " $fragmentType": "ReviewCartTotalsDisplay_OrderFragment";
};
export type ReviewCartTotalsDisplay_OrderFragment$key = {
  readonly " $data"?: ReviewCartTotalsDisplay_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReviewCartTotalsDisplay_OrderFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netPrice",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "grossPrice",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "taxRatePercentage",
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v2/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewCartTotalsDisplay_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "selectedPaymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentMethodId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "selectedPaymentMethodType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MonthlyOption",
              "kind": "LinkedField",
              "name": "chosenOption",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Price",
                  "kind": "LinkedField",
                  "name": "closingRate",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Price",
                  "kind": "LinkedField",
                  "name": "totalAmount",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v0/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Price",
                  "kind": "LinkedField",
                  "name": "downPayment",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Price",
                  "kind": "LinkedField",
                  "name": "financedAmount",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Price",
                  "kind": "LinkedField",
                  "name": "monthlyRate",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rateCount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "MonthlySelectedPaymentMethod",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CartTotals",
          "kind": "LinkedField",
          "name": "totals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "appliedDiscountPercentage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Price",
              "kind": "LinkedField",
              "name": "includingAllDiscounts",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Price",
              "kind": "LinkedField",
              "name": "withoutDiscounts",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
})();

(node as any).hash = "75aded89c210fb4f7daafb3cb78e8b71";

export default node;
