import graphql from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import {useFormik} from "formik";
import * as Yup from "yup";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {
    ForgotPasswordForm_ForgotPasswordMutation
} from "../../__generated__/ForgotPasswordForm_ForgotPasswordMutation.graphql";
import {ValidatedFieldV2} from "../form/ValidatedFieldV2";
import {Button} from "primereact/button";
import {Message} from "primereact/message";
import {useTranslation} from "react-i18next";
import styled from "styled-components";


const FORGOT_PASSWORD_MUTATION = graphql`
    mutation ForgotPasswordForm_ForgotPasswordMutation($input: ForgotPasswordInput!){
        Auth{
            forgotPassword(input: $input) {
                clientMutationId
            }
        }
    }
`;

interface FormState {
    email: string
}

export const ForgotPasswordForm = () => {
    const [forgotPassword, isLoggingIn] = useMutation<ForgotPasswordForm_ForgotPasswordMutation>(FORGOT_PASSWORD_MUTATION)
    const [emailSent, setEmailSent] = useState(false)
    const {t} = useTranslation("auth")
    const navigate = useNavigate();

    const formik = useFormik<FormState>({
        initialValues: {
            email: '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            email: Yup
                .string()
                .email(t('forgot-password.formik.email.validation'))
                .required(t('forgot-password.formik.email.required')),
        }),
        onSubmit: (data) => {
            forgotPassword({
                variables: {
                    input: {
                        email: data.email,
                    }
                },
                onCompleted: response => {
                    if (response.Auth.forgotPassword) {
                        setEmailSent(true)
                    }
                },
            })
        }
    });

    return emailSent ? <Message
            content={t('forgot-password.message')}/> :
        <FormContainer onSubmit={formik.handleSubmit} className="p-fluid">
            <ValidatedFieldV2<FormState, string>
                className="mb-4"
                name={"email"}
                iconClass={"pi-envelope"}
                label={t('forgot-password.formik.email.label')}
                required={true}
                formikConfig={formik}
                component={({fieldValue, updateField, fieldName, isValid}) => {
                    return <InputText id={fieldName} name={fieldName} value={fieldValue}
                                      onChange={e => updateField(e.target.value)}
                                      className={classNames({'p-invalid': !isValid})}/>

                }}/>
            <Button disabled={isLoggingIn} type="submit" label={t('forgot-password.formik.submit')} className="p-mt-2"/>
            <div className="mt-5">
                <Button label={t('links.login')} className="p-button-link"
                        onClick={() => navigate("/")}/>
            </div>
        </FormContainer>
}

const FormContainer = styled.form`
  min-width: 300px;
`
