import {PaymentMethodType} from "../../../__generated__/usePaymentLogic_OrderFragment.graphql";

export const paymentMethodIsMonthly = (method: PaymentMethodType): boolean => {
    switch (method) {
        case "Sepa":
            return true;
        case "InvoiceTk":
            return true;
    }
    return false;
}


export const paymentMethodNeedsSepaMandate = (method: PaymentMethodType): boolean => {
    switch (method) {
        case "Sepa":
            return true;
        case "InvoiceTk":
            return false;
    }
    return false;
}
