import React from 'react'
import {graphql} from "babel-plugin-relay/macro";
import {useLazyLoadQuery} from "react-relay";
import {SettingsScreen_Query} from "../../../../__generated__/SettingsScreen_Query.graphql";
import {ChangeEmailForm} from "./data/ChangeEmailForm";
import {ChangePasswordForm} from "./data/ChangePasswordForm";

const SETTINGS_QUERY = graphql`
    query SettingsScreen_Query {
        Viewer {
            User {
                User {
                    ...ChangeEmailForm_UserFragment
                }
            }
        }
    }
`

export const SettingsScreen = () => {
    const user = useLazyLoadQuery<SettingsScreen_Query>(
        SETTINGS_QUERY,
        {}
    ).Viewer.User.User

    return (
            <div className="flex flex-column align-items-center">
                <h2 className="mb-8">Einstellungen</h2>
                <ChangeEmailForm userFragmentRef={user!}/>
                <ChangePasswordForm/>
            </div>
    )
}
