import {useLazyLoadQuery} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import React from "react";
import {OrderFlow} from "./OrderFlowScreen";
import {OrderFlowFromIdScreen_Query} from "../../../__generated__/OrderFlowFromIdScreen_Query.graphql";
import {useMatch} from "react-router-dom";
import {ORDER_FLOW_FROM_ID} from "../../../routes/BillingModuleRoutes";

export const OrderFlowFromIdScreen = () => {
    const {params: {orderId}} = useMatch(ORDER_FLOW_FROM_ID)!;

    const query = useLazyLoadQuery<OrderFlowFromIdScreen_Query>(graphql`
        query OrderFlowFromIdScreen_Query($orderId: ID!) {
            node(id: $orderId) {
                ... on Order {
                    id
                    ...OrderFlowScreen_OrderFragment
                }
            }
        }
    `, {orderId: orderId!})


    return query?.node ? <OrderFlow
        orderFragmentRef={query.node}
    /> : <h2>Leider ist ein Fehler aufgetreten. Nimm jetzt Kontakt zu <a href="mailto:betrieb@thekey.community">uns</a> auf</h2>
}
