import React, {useEffect, useRef, useState} from "react";
import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {BillingLogin} from "./forms/BillingLogin";
import {Separator} from "../cart/CartStep";
import styled, {css} from "styled-components";
import {BillingDetailsForm} from "./forms/BillingDetailsForm";
import {BillingDetailsStep_OrderFragment$key} from "../../../../__generated__/BillingDetailsStep_OrderFragment.graphql";
import {useTypedSelector} from "../../../../Store";
import {selectIsLoggedIn} from "../../../../slices/AuthSlice";

const ORDER_FRAGMENT = graphql`
    fragment BillingDetailsStep_OrderFragment on Order{
        id
        ...BillingDetailsForm_OrderFragment
    }
`;

interface OwnProps {
    orderFragmentRef: BillingDetailsStep_OrderFragment$key
}

export const BillingDetailsStep = ({orderFragmentRef}: OwnProps) => {
    const order = useFragment<BillingDetailsStep_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef)

    const isLoggedIn = useTypedSelector(selectIsLoggedIn)

    const [hasAccount, setHasAccount] = useState<boolean | undefined>(undefined)

    const ref = useRef<any>(null)

    useEffect(() => {
        ref.current?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }, [])

    return <Container ref={ref}>
        <Separator/>
        {!isLoggedIn ? <>
            <Heading>Hast Du bereits ein Teilnehmenden-Konto bei thekey.community?</Heading>

            <LoginSelectorContainer>
                <LoginNeededSelector selected={!!hasAccount} onClick={() => setHasAccount(true)}>Ja ich habe bereits ein
                    Konto.</LoginNeededSelector>
                <LoginNeededSelector selected={!hasAccount} onClick={() => setHasAccount(false)}>Nein, noch
                    nicht.</LoginNeededSelector>
            </LoginSelectorContainer>

            {hasAccount ? <BillingLogin /> :
                <BillingDetailsForm orderFragmentRef={order} />}
        </> : <>
            <BillingDetailsForm orderFragmentRef={order} />
        </>}


    </Container>
}

const Container = styled.div`
  width: 100%;
  scroll-margin: 50px;
`

const Heading = styled.h3`
  font-family: "Nunito Sans", sans-serif;

  font-size: 24px;
  line-height: 1.42;
  color: #184276;
  font-weight: normal;
  margin-bottom: 25px;

  @media (max-width: 1024px) {
    font-size: 18px;
  }
`

const LoginSelectorContainer = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 270px 270px;
  margin-bottom: 30px;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }

`

interface LoginNeededSelectorProps {
    selected: boolean
}

const LoginNeededSelector = styled.div<LoginNeededSelectorProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 270px;
  height: 70px;
  border-radius: 6px;
  border: solid 2px #e7ebf1;
  background-color: #f3f5f8;
  cursor: pointer;

  @media (max-width: 1024px) {
    margin-bottom: 20px;
    width: 100%;
  }

  ${props => props.selected && css`
    background-color: white;
    border: solid 2px #4ace7f;
  `}
`
