import Countries from "./countries.json";
import {useFormik} from "formik";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import * as Yup from "yup";
import {useFragment, useMutation} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {OrderFlowContext} from "../../../hooks/OrderFlowContext";
import moment from "moment";
import {ValidatedFieldV2} from "../../../../../components/form/ValidatedFieldV2";
import {
    PrivateForm_BillingDetailsFragment$key
} from "../../../../../__generated__/PrivateForm_BillingDetailsFragment.graphql";
import {
    PrivateForm_UpdateBillingDetailsMutation,
    SignupInput,
} from "../../../../../__generated__/PrivateForm_UpdateBillingDetailsMutation.graphql";
import {Dropdown} from "primereact/dropdown";
import {classNames} from "primereact/utils";
import {InputText} from "primereact/inputtext";
import {Calendar} from "primereact/calendar";
import {Card} from "primereact/card";
import styled from "styled-components";
import {PrivateSignupFormPart} from "./PrivateSignupFormPart";
import {Checkbox} from "primereact/checkbox";
import {InputSwitch} from "primereact/inputswitch";
import {DifferentInvoiceAddressFormPart} from "./DifferentInvoiceAddressFormPart";
import {useTypedSelector} from "../../../../../Store";
import {selectCurrentUser, setExtension} from "../../../../../slices/AuthSlice";
import {useDispatch} from "react-redux";

const BILLING_DETAILS_FRAGMENT = graphql`
    fragment PrivateForm_BillingDetailsFragment on BillingDetails {
        salutation
        firstName
        lastName
        street
        houseNumber
        postalCode
        city
        invoiceEmail
        country
        customerType
        ... on PrivateBillingDetails {
            dateOfBirth
        }
    }
`;

const UPDATE_BILLING_DETAILS_MUTATION = graphql`
    mutation PrivateForm_UpdateBillingDetailsMutation(
        $billingDetails: UpdatePrivateBillingDetailsInput!
    ) {
        Billing {
            updateBillingDetailsPrivate(input: $billingDetails) {
                order {
                    ...OrderFlowScreen_OrderFragment
                }
            }
        }
    }
`;

interface OwnProps {
    orderId: string;
    billingDetailsFragmentRef: PrivateForm_BillingDetailsFragment$key | null;
}

export interface PrivateFormState {
    userId?: string;
    salutation: string;
    firstName: string;
    lastName: string;
    street: string;
    houseNumber: string;
    postalCode: string;
    city: string;
    jobTitle: string;
    branche: string;
    invoiceCompanyName: string;
    invoiceCompanyDetails: string;
    invoiceFirstName: string;
    invoiceLastName: string;
    invoiceStreet: string;
    invoiceHouseNumber: string;
    invoicePostalCode: string;
    invoiceCity: string;
    invoiceCountry: string;
    email: string;
    email2: string;
    password: string;
    password2: string;
    legal: boolean;
    country: "de";
    dateOfBirth?: Date;
    adsOptIn: boolean;
    approveCorrectAddress: boolean;
}

export const PrivateForm = ({
                                orderId,
                                billingDetailsFragmentRef,
                            }: OwnProps) => {
    const {t} = useTranslation(["billing", "core"]);
    const dispatch = useDispatch()

    const {setSubmitBillingForm, advance} = useContext(OrderFlowContext);

    const [hasDifferentAddress, setHasDifferentAddress] =
        useState<boolean>(false);

    const billingDetails = useFragment<PrivateForm_BillingDetailsFragment$key>(
        BILLING_DETAILS_FRAGMENT,
        billingDetailsFragmentRef
    );
    const user = useTypedSelector(selectCurrentUser);

    const [updateBillingDetails, isInFlight] =
        useMutation<PrivateForm_UpdateBillingDetailsMutation>(
            UPDATE_BILLING_DETAILS_MUTATION
        );

    const monthNavigatorTemplate = (e: any) => {
        return (
            <Dropdown
                value={e.value}
                options={e.options}
                onChange={(event) => e.onChange(event.originalEvent, event.value)}
                style={{lineHeight: 1}}
            />
        );
    };

    const yearNavigatorTemplate = (e: any) => {
        return (
            <Dropdown
                value={e.value}
                options={e.options}
                onChange={(event) => e.onChange(event.originalEvent, event.value)}
                className="p-ml-2"
                style={{lineHeight: 1}}
            />
        );
    };


    const [submitted, setSubmitted] = useState(false);
    /* TODO
    * @RON: wenn framework-scala/backend geupdated
    * Asana Ticket: https://app.asana.com/0/1200989582214353/1203780025963466
    * initialValues : {
    * ...
    * branche: billingDetails?.meta?.find((e)=>e.key === "branche"))?.value
    * jobTitle: billingDetails?.meta?.find((e)=>e.key === "jobTitle"))?.value
    * ...
    * }
    * */

    const formik = useFormik<PrivateFormState>({
        initialValues: {
            userId: user?.user.id,
            salutation: billingDetails?.salutation || "",
            firstName: billingDetails?.firstName || "",
            lastName: billingDetails?.lastName || "",
            street: billingDetails?.street || "",
            houseNumber: billingDetails?.houseNumber || "",
            postalCode: billingDetails?.postalCode || "",
            city: billingDetails?.city || "",
            branche: user?.user.branche || "",
            jobTitle: user?.user.jobTitle || "",
            invoiceCity: "",
            invoiceFirstName: "",
            invoiceLastName: "",
            invoiceHouseNumber: "",
            invoicePostalCode: "",
            invoiceStreet: "",
            invoiceCompanyDetails: "",
            invoiceCompanyName: "",
            invoiceCountry: "de",
            email: "",
            email2: "",
            password: "",
            password2: "",
            legal: false,
            country: "de",
            dateOfBirth: billingDetails?.dateOfBirth
                ? new Date(billingDetails.dateOfBirth.replace(/\[UTC\]/gi, ""))
                : undefined,
            adsOptIn: false,
            approveCorrectAddress: false,
        },
        enableReinitialize: true,
        validationSchema: yup.object().shape({
            salutation: Yup.string().required(
                t("core:forms.required-field", {
                    fieldName: t("checkout-form.salutation"),
                })
            ),
            firstName: Yup.string().required(
                t("core:forms.required-field", {
                    fieldName: t("checkout-form.first-name"),
                })
            ),
            lastName: Yup.string().required(
                t("core:forms.required-field", {
                    fieldName: t("checkout-form.last-name"),
                })
            ),
            street: Yup.string().required(
                t("core:forms.required-field", {fieldName: t("checkout-form.street")})
            ),
            houseNumber: Yup.string()
                .max(8, t("core:forms.max-length-housenumber"))
                .required(
                    t("core:forms.required-field", {
                        fieldName: t("checkout-form.house-number"),
                    })
                ),
            postalCode: Yup.string()
                .matches(new RegExp("^[0-9]{5}$"), "Das ist keine gültige PLZ")
                .required(
                    t("core:forms.required-field", {
                        fieldName: t("checkout-form.postal-code"),
                    })
                ),
            city: Yup.string().required(
                t("core:forms.required-field", {fieldName: t("checkout-form.city")})
            ),
            country: Yup.string().required(
                t("core:forms.required-field", {
                    fieldName: t("checkout-form.country"),
                })
            ),
            approveCorrectAddress: Yup.boolean().test(
                "test",
                "test",
                function (value) {
                    if (!value) {
                        return this.createError({
                            path: "approveCorrectAddress",
                            message: t("core:forms.required-field", {fieldName: ""}),
                        });
                    }
                    return true;
                }
            ),
            email: Yup.string()
                .email(t("checkout-form.email-error"))
                .test("test", "test", function (value) {
                    if (!user?.user && !value) {
                        return this.createError({
                            path: "email",
                            message: t("core:forms.required-field", {
                                fieldName: t("checkout-form.email"),
                            }),
                        });
                    }
                    return true;
                }),
            email2: Yup.string().oneOf(
                [Yup.ref("email")],
                t("checkout-form.email2-error")
            ),
            password: Yup.string()
                .min(8, t("checkout-form.password-error"))
                .test("test", "test", function (value) {
                    if (!user?.user && !value) {
                        return this.createError({
                            path: "password",
                            message: t("core:forms.required-field", {
                                fieldName: t("checkout-form.password"),
                            }),
                        });
                    }
                    return true;
                }),
            password2: Yup.string().oneOf(
                [Yup.ref("password")],
                t("checkout-form.password2-error")
            ),
            legal: Yup.boolean().test("test", "test", function (value) {
                if (!user?.user && !value) {
                    return this.createError({
                        path: "legal",
                        message: t("core:forms.required-field", {
                            fieldName: "Nutzungsbedingungen & Datenschutz",
                        }),
                    });
                }
                return true;
            }),
            dateOfBirth: Yup.string().required(
                t("core:forms.required-field", {fieldName: "Geburtsdatum"})
            ).test("dateOfBirth", "Du musst mindest 18 Jahre alt sein.", function (value) {
                if (!value) return true;

                const date = new Date(value);
                const date18YearsAgo = moment().subtract(18, "years").toDate()

                return moment(date).isBefore(date18YearsAgo);
            })
        }),
        onSubmit: (values, {setSubmitting, ...helpers}) => {
            updateBillingDetails({
                variables: {
                    billingDetails: {
                        orderId: orderId,
                        privateBillingDetails: {
                            salutation: values.salutation,
                            firstName: values.firstName,
                            lastName: values.lastName,
                            street: values.street,
                            city: values.city,
                            country: values.country,
                            postalCode: values.postalCode,
                            houseNumber: values.houseNumber,
                            //@ts-ignore
                            dateOfBirth: values.dateOfBirth || "",
                            invoiceEmail:
                                billingDetails?.invoiceEmail ||
                                values.email ||
                                user?.user.email ||
                                "",
                            invoiceAddress: !hasDifferentAddress
                                ? undefined
                                : {
                                    postalCode: values.invoicePostalCode,
                                    companyDetails: values.invoiceCompanyDetails,
                                    city: values.invoiceCity,
                                    country: values.invoiceCountry,
                                    firstName: values.invoiceFirstName,
                                    lastName: values.invoiceLastName,
                                    street: values.invoiceStreet,
                                    houseNumber: values.houseNumber,
                                    companyName: values.invoiceCompanyName,
                                },
                        },
                        jobTitle: values.jobTitle,
                        branche: values.branche,
                        signup: !user?.user
                            ? ({
                                email: values.email!,
                                firstName: values.firstName!,
                                lastName: values.lastName!,
                                password: values.password!,
                                adsOptIn: values.adsOptIn,
                            } as SignupInput)
                            : undefined,
                    },
                },
                onCompleted: () => {
                    if (!user?.user) return setSubmitted(true);
                    advance();
                },
            });

            dispatch(setExtension({branche: values.branche, jobTitle: values.jobTitle}))
            setSubmitting(false);
        },
    });

    useEffect(() => {
        setSubmitBillingForm(formik.handleSubmit);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isInFlight ? (
        <div>Lade...</div>
    ) : (
        <div>
            <Card>
                <FormContainer
                    onSubmit={() => {
                        formik.handleSubmit();
                    }}
                    className="p-fluid"
                >
                    <FormRow>
                        <ValidatedFieldV2<PrivateFormState, string>
                            name={"salutation"}
                            label={"Anrede"}
                            formikConfig={formik}
                            component={({fieldValue, updateField, fieldName, isValid}) => {
                                return (
                                    <div className={"w-6"}>
                                        <Dropdown
                                            name={fieldName}
                                            value={fieldValue}
                                            onChange={(e) => updateField(e.target.value)}
                                            options={[
                                                {label: "Herr", value: "Mr"},
                                                {label: "Frau", value: "Mrs"},
                                                {label: "Divers", value: "Divers"},
                                            ]}
                                            optionValue={"value"}
                                            optionLabel={"label"}
                                            className={classNames({"p-invalid": !isValid})}
                                        />
                                    </div>
                                );
                            }}
                        />
                    </FormRow>
                    <ValidatedFieldV2<PrivateFormState, string>
                        name={"firstName"}
                        label={"Vorname"}
                        formikConfig={formik}
                        component={({fieldValue, updateField, fieldName, isValid}) => {
                            return (
                                <InputText
                                    id={fieldName}
                                    name={fieldName}
                                    value={fieldValue}
                                    onChange={(e) => updateField(e.target.value)}
                                    className={classNames({"p-invalid": !isValid})}
                                />
                            );
                        }}
                    />
                    <ValidatedFieldV2<PrivateFormState, string>
                        name={"lastName"}
                        label={"Nachname"}
                        formikConfig={formik}
                        component={({fieldValue, updateField, fieldName, isValid}) => {
                            return (
                                <InputText
                                    id={fieldName}
                                    name={fieldName}
                                    value={fieldValue}
                                    onChange={(e) => updateField(e.target.value)}
                                    className={classNames({"p-invalid": !isValid})}
                                />
                            );
                        }}
                    />
                    <ValidatedFieldV2<PrivateFormState, string>
                        name={"jobTitle"}
                        label={"Berufsbezeichnung"}
                        formikConfig={formik}
                        component={({fieldValue, updateField, fieldName, isValid}) => {
                            return (
                                <InputText
                                    id={fieldName}
                                    name={fieldName}
                                    value={fieldValue}
                                    onChange={(e) => updateField(e.target.value)}
                                    className={classNames({"p-invalid": !isValid})}
                                />
                            );
                        }}
                    />
                    <ValidatedFieldV2<PrivateFormState, string>
                        name={"branche"}
                        label={"Branche"}
                        formikConfig={formik}
                        component={({fieldValue, updateField, fieldName, isValid}) => {
                            return (
                                <InputText
                                    id={fieldName}
                                    name={fieldName}
                                    value={fieldValue}
                                    onChange={(e) => updateField(e.target.value)}
                                    className={classNames({"p-invalid": !isValid})}
                                />
                            );
                        }}
                    />
                    <ValidatedFieldV2<PrivateFormState, string>
                        name={"street"}
                        label={"Straße"}
                        formikConfig={formik}
                        component={({fieldValue, updateField, fieldName, isValid}) => {
                            return (
                                <InputText
                                    id={fieldName}
                                    name={fieldName}
                                    value={fieldValue}
                                    onChange={(e) => updateField(e.target.value)}
                                    className={classNames({"p-invalid": !isValid})}
                                />
                            );
                        }}
                    />
                    <ValidatedFieldV2<PrivateFormState, string>
                        name={"houseNumber"}
                        label={"Hausnummer"}
                        formikConfig={formik}
                        component={({fieldValue, updateField, fieldName, isValid}) => {
                            return (
                                <InputText
                                    id={fieldName}
                                    name={fieldName}
                                    value={fieldValue}
                                    onChange={(e) => updateField(e.target.value)}
                                    className={classNames({"p-invalid": !isValid})}
                                />
                            );
                        }}
                    />
                    <ValidatedFieldV2<PrivateFormState, string>
                        name={"postalCode"}
                        label={"Postleitzahl"}
                        formikConfig={formik}
                        component={({fieldValue, updateField, fieldName, isValid}) => {
                            return (
                                <InputText
                                    id={fieldName}
                                    name={fieldName}
                                    value={fieldValue}
                                    onChange={(e) => updateField(e.target.value)}
                                    className={classNames({"p-invalid": !isValid})}
                                />
                            );
                        }}
                    />
                    <ValidatedFieldV2<PrivateFormState, string>
                        name={"city"}
                        label={"Ort/Stadt"}
                        formikConfig={formik}
                        component={({fieldValue, updateField, fieldName, isValid}) => {
                            return (
                                <InputText
                                    id={fieldName}
                                    name={fieldName}
                                    value={fieldValue}
                                    onChange={(e) => updateField(e.target.value)}
                                    className={classNames({"p-invalid": !isValid})}
                                />
                            );
                        }}
                    />
                    <ValidatedFieldV2<PrivateFormState, string>
                        name={"country"}
                        label={"Land"}
                        formikConfig={formik}
                        component={({fieldValue, updateField, fieldName, isValid}) => {
                            return (
                                <div>
                                    <Dropdown
                                        name={fieldName}
                                        value={fieldValue}
                                        onChange={(e) => updateField(e.target.value)}
                                        options={Object.entries(Countries).map((entry) => {
                                            return {
                                                value: entry[0].toLowerCase(),
                                                label: entry[1],
                                            };
                                        })}
                                        optionValue={"value"}
                                        optionLabel={"label"}
                                        className={classNames({"p-invalid": !isValid})}
                                    />
                                </div>
                            );
                        }}
                    />
                    <ValidatedFieldV2<PrivateFormState, Date>
                        name={"dateOfBirth"}
                        label={"Geburtstag"}
                        formikConfig={formik}
                        component={({fieldValue, updateField, fieldName, isValid}) => {
                            return (
                                <Calendar
                                    id={fieldName}
                                    name={fieldName}
                                    value={fieldValue}
                                    yearRange={"1930:2050"}
                                    monthNavigator
                                    yearNavigator
                                    touchUI
                                    monthNavigatorTemplate={monthNavigatorTemplate}
                                    yearNavigatorTemplate={yearNavigatorTemplate}
                                    dateFormat={"dd.mm.yy"}
                                    onChange={(e) =>
                                        updateField(e.target.value as Date | undefined)
                                    }
                                    className={classNames({"p-invalid": !isValid})}
                                />
                            );
                        }}
                    />
                    <ValidatedFieldV2<PrivateFormState, boolean>
                        name={"approveCorrectAddress"}
                        formikConfig={formik}
                        component={({fieldValue, updateField, fieldName, isValid}) => {
                            return (
                                <div className="flex">
                                    <InputSwitch
                                        className="mr-2 flex-shrink-0"
                                        checked={fieldValue}
                                        onChange={(e) => updateField(e.target.value)}
                                    />
                                    <label htmlFor={fieldName} className="w-20rem">
                                        Ich bestätige, dass meine Angaben korrekt sind, da diese wie oben aufgeführt auf
                                        dem IHK-Zertifikat erscheinen. Mir ist bewusst, dass ich Änderungen bis
                                        spätestens 6 Wochen vor der Prüfung anmelden muss, da sonst Extrakosten
                                        entstehen können.
                                    </label>
                                </div>
                            );
                        }}
                    />
                </FormContainer>
                <div className="p-col-12 flex">
                    <Checkbox
                        name="hasDifferentAddress"
                        className="mr-2"
                        onChange={(e) => setHasDifferentAddress(e.checked)}
                        checked={hasDifferentAddress}
                    />
                    <label htmlFor="hasDifferentAddress" className="p-checkbox-label">
                        Abweichende Rechnungsadresse
                    </label>
                </div>
                {hasDifferentAddress ? (
                    <DifferentInvoiceAddressFormPart formik={formik}/>
                ) : null}
                {!user ? <PrivateSignupFormPart formik={formik} submitted={submitted}/> : null}
            </Card>
        </div>
    );
};

const FormContainer = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`;

const FormRow = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  display: inline-grid;
  grid-template-columns: 100%;
`;
