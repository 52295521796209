import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import {useFragment, useMutation} from "react-relay";
import {
    AlternativeDateInformation_PublishedCourseFragment$key
} from "../../../../__generated__/AlternativeDateInformation_PublishedCourseFragment.graphql";
import {DateTimeDisplay} from "../../../../components/datetime/DateTimeDisplay";
import {Card} from "primereact/card";
import {Button} from 'primereact/button';
import {DialogResult, useDialogLogic} from "../../../../components/dialog/useDialogLogic";
import {toast} from "react-toastify";
import {
    AlternativeDateInformation_RegisterToAnotherModuleMutation
} from "../../../../__generated__/AlternativeDateInformation_RegisterToAnotherModuleMutation.graphql";
import {
    AlternativeDateInformation_AlternativeFragment$key
} from "../../../../__generated__/AlternativeDateInformation_AlternativeFragment.graphql";
import {LocationInformation} from "../../../../components/ui/LocationInformation";
import {Title} from "../../../../components/ui/Title";

const ALTERNATIVE_FRAGMENT = graphql`
    fragment AlternativeDateInformation_AlternativeFragment on Alternative {
        courseStateId
        rebookedFull
        alternativeModule {
            id
            location {
                name
                hotel
            }
            canSignIn

        }
    }
`

const PUBLISHED_COURSE_FRAGMENT = graphql`
    fragment AlternativeDateInformation_PublishedCourseFragment on PublishedModuleViewer {
        id
        title
        shortTitle
        trainingDays {
            id
            date
        }
    }
`

const REGISTER_TO_ANOTHER_MODULE_MUTATION = graphql`
    mutation AlternativeDateInformation_RegisterToAnotherModuleMutation ($input: RegisterToAnotherModuleInput!) {
        Viewer {
            States {
                registerToAnotherModule(input: $input) {
                    courseState {
                        node {
                            ... ModuleItems_CourseStateFragment
                        }
                    }
                }
            }
        }
    }
`

interface OwnProps {
    publishedCourseFragmentRef: AlternativeDateInformation_PublishedCourseFragment$key
    alternativeFragmentRef: AlternativeDateInformation_AlternativeFragment$key
}

export const AlternativeDateInformation = ({publishedCourseFragmentRef, alternativeFragmentRef}: OwnProps) => {
    const publishedCourse = useFragment<AlternativeDateInformation_PublishedCourseFragment$key>(PUBLISHED_COURSE_FRAGMENT, publishedCourseFragmentRef)
    const alternative = useFragment<AlternativeDateInformation_AlternativeFragment$key>(ALTERNATIVE_FRAGMENT, alternativeFragmentRef)

    const [registerToAnotherModule, isRegistering] = useMutation<AlternativeDateInformation_RegisterToAnotherModuleMutation>(REGISTER_TO_ANOTHER_MODULE_MUTATION)
    const {showDialog, dialogComponent} = useDialogLogic();

    const dialogContent = (canSignIn: boolean) => {
        return canSignIn ? (
            <div>
                <span>Möchtest du dich wirklich bei diesem Modul anmelden?</span>
                <br/>
                <span>Du wirst von dem aktuellen registrierten Modul abgemeldet</span>
                <p>Bei Unklarheiten wende dich gerne per E-Mail an <a
                    href="mailto:termine@thekey.community">termine@thekey.community</a></p>
            </div>
        ) : (
            <div>
                <span>Leider kannst du dich nur 2 Tage vor dem Start des Moduls anmelden</span>
                <p>Bei Fragen oder Unklarheiten wende dich gerne per E-Mail an <a
                    href="mailto:termine@thekey.community">termine@thekey.community</a></p>
            </div>
        )

    }

    const registerAsRebooked = () => {
        showDialog({
            title: "Abmelden",
            content: dialogContent(alternative.alternativeModule.canSignIn),
            affirmativeText: "anmelden",
            affirmativeButtonDisabled: !alternative.alternativeModule.canSignIn,
            dialogCallback: (dialogResult: DialogResult) => {
                if (dialogResult === "Accept") {
                    registerToAnotherModule({
                        variables: {
                            input: {
                                destinationCourseStateId: alternative.courseStateId,
                                destinationModuleId: alternative.alternativeModule.id,
                                attendanceType: "Rebooked"
                            }
                        },
                        onCompleted: () => {
                            toast.success("Erfolgreich abgemeldet")
                        }
                    })
                }
            }
        })
    }

    const registerOnWaitingList = () => {
        showDialog({
            title: "Aus Warteliste setzen",
            content: "Dieses Modul ist leider voll \n\n Du kannst dich aber auf die Warteliste setzen und informiert werden, sobald platz frei wird",
            affirmativeText: "anmelden",
            affirmativeButtonDisabled: !alternative.alternativeModule.canSignIn,
            dialogCallback: (dialogResult: DialogResult) => {
                if (dialogResult === "Accept") {
                    registerToAnotherModule({
                        variables: {
                            input: {
                                destinationCourseStateId: alternative.courseStateId,
                                destinationModuleId: alternative.alternativeModule.id,
                                attendanceType: "WaitingList"
                            }
                        },
                        onCompleted: () => {
                            toast.success("Erfolgreich abgemeldet")
                        }
                    })
                }
            }
        })
    }

    return (
        <Card className="flex flex-column m-3">
            {dialogComponent}
            <Title title={publishedCourse.title} shortTitle={publishedCourse.shortTitle}/>
            <LocationInformation locationName={alternative.alternativeModule.location.name} hotel={alternative.alternativeModule.location.hotel}/>
            <div className="flex flex-column mb-2">
                {publishedCourse.trainingDays.map(({id, date}, index: number) => (
                    <div key={id} className="flex flex-row mt-2">
                        <b className="mr-1">Tag {index + 1}:</b>
                        <DateTimeDisplay value={date.toString()} showTime={false} hideTimezone/>
                    </div>
                ))}
            </div>
            <div>
                {alternative.rebookedFull ?
                    <Button className="p-button-raised p-button-success" label="Auf Warteliste setzen" disabled={isRegistering}
                            onClick={registerOnWaitingList}/> :
                    <Button className="p-button-raised p-button-success" label="Anmelden" disabled={isRegistering}
                            onClick={registerAsRebooked}/>}

            </div>
        </Card>
    )
}
