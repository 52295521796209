import {SettingsScreen} from "../screens/settings/components/communication/SettingsScreen";
import {AccountScreen} from "../screens/accountSettingsScreen/AccountScreen";
import {RouteAuthState, SecureRouteDefinition} from "../model/SecureRouteDefinition";

export const MY_ACCOUNT_ROUTE = "/my-account"
export const COMMUNICATION_SETTINGS_ROUTE = "/communication-settings"

export const SettingModuleRoutes: SecureRouteDefinition[] = [
    {
        path: MY_ACCOUNT_ROUTE,
        element: <AccountScreen/>,
        requiredPermissions: [],
        authState: RouteAuthState.OnlyLoggedIn
    },
    {
        path: COMMUNICATION_SETTINGS_ROUTE,
        element: <SettingsScreen/>,
        requiredPermissions: [],
        authState: RouteAuthState.OnlyLoggedIn
    },
];
