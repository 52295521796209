import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import {
    ModuleInformation_PublishedModuleFragment$key
} from "../../../__generated__/ModuleInformation_PublishedModuleFragment.graphql";
import {useFragment} from "react-relay";
import {LocationInformation} from "../../../components/ui/LocationInformation";
import {TrainerInformation} from "../../../components/ui/TrainerInformation";

const PUBLISHED_MODULE_FRAGMENT = graphql`
    fragment ModuleInformation_PublishedModuleFragment on PublishedModuleViewer {
        trainer {
            name
        }
        location {
            name
            hotel
        }
        isExam
        shortDescription
    }
`

interface OwnProps {
    publishedModuleFragmentRef: ModuleInformation_PublishedModuleFragment$key
}

export const ModuleInformation = ({publishedModuleFragmentRef}: OwnProps) => {
    const publishedModule = useFragment<ModuleInformation_PublishedModuleFragment$key>(PUBLISHED_MODULE_FRAGMENT, publishedModuleFragmentRef)
    const trainer = publishedModule.trainer
        .map(trainer => trainer.name)

    return (
        <div>
            <TrainerInformation trainer={trainer}/>
            <LocationInformation locationName={publishedModule.location.name} hotel={publishedModule.location.hotel}/>
            <p style={{whiteSpace: "pre-line"}}>{publishedModule.shortDescription} </p>
        </div>
    )
}
