import React, {useContext} from "react";
import {InputText} from "primereact/inputtext";
import {FaqSearchContext} from "./FaqSearchContainer";

export const FaqFilter = () => {
    const {filters, setFilters, clearFilters} = useContext(FaqSearchContext)

    return <div className="flex mb-3 flex-wrap ">
        <div className="m-1 flex">
            <span className="p-input-icon-left p-input-icon-right">
                    <i className="pi pi-search"/>
                    <InputText value={filters?.filterByQuestion || ""} onChange={(e) => setFilters({
                        ...filters,
                        filterByQuestion: e.target.value
                    })} placeholder="Suchen..."/>
                    <i className="pi pi-times cursor-pointer" onClick={() => clearFilters()}/>
                </span>
        </div>
    </div>
}
