import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import {useFragment} from 'react-relay'
import {AdditionalTrainingDayInformation_TrainingDayFragment$key} from "../../../../__generated__/AdditionalTrainingDayInformation_TrainingDayFragment.graphql";

const TRAINING_DAY_FRAGMENT = graphql`
    fragment AdditionalTrainingDayInformation_TrainingDayFragment on PublishedTrainingDay {
        additionalInformation
    }
`

interface OwnProps {
    trainingDayFragmentRef: any
}

export const AdditionalTrainingDayInformation = ({trainingDayFragmentRef}: OwnProps) => {
    const trainingDay = useFragment<AdditionalTrainingDayInformation_TrainingDayFragment$key>(TRAINING_DAY_FRAGMENT, trainingDayFragmentRef)
    return <div>
        <h4>Zusätzliche Informationen:</h4>
        <p dangerouslySetInnerHTML={{__html: trainingDay.additionalInformation!}}/>
    </div>
}
