/**
 * @generated SignedSource<<d0b2364e088c5e2185ab6d6260ce3ddb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ActivateUserInput = {
  clientMutationId?: string | null;
  token: string;
};
export type ActivationScreen_ActivationMutation$variables = {
  input: ActivateUserInput;
};
export type ActivationScreen_ActivationMutation$data = {
  readonly Auth: {
    readonly activateUser: {
      readonly clientMutationId: string | null;
    } | null;
  };
};
export type ActivationScreen_ActivationMutation = {
  response: ActivationScreen_ActivationMutation$data;
  variables: ActivationScreen_ActivationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuthMutations",
    "kind": "LinkedField",
    "name": "Auth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "ActivateUserPayload",
        "kind": "LinkedField",
        "name": "activateUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientMutationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ActivationScreen_ActivationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ActivationScreen_ActivationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8648edd7322f354f5b0680089e8b5923",
    "id": null,
    "metadata": {},
    "name": "ActivationScreen_ActivationMutation",
    "operationKind": "mutation",
    "text": "mutation ActivationScreen_ActivationMutation(\n  $input: ActivateUserInput!\n) {\n  Auth {\n    activateUser(input: $input) {\n      clientMutationId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b12c3a4a654b45a932d1146d0d4169de";

export default node;
