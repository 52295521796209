import {FormikState} from "formik";
import React, {ReactNode} from "react";

import {classNames} from "primereact/utils";
import styled from "styled-components";

export interface RenderConfig<FieldType> {
    fieldValue: FieldType | undefined,
    updateField: (newValue: FieldType | undefined) => void,
    fieldName: string,
    required: boolean;
    isValid: boolean
}

interface ValidatedFieldPropsV2<State, FieldType> {
    label?: string;
    name: keyof State & string;
    required?: boolean;
    className?: string

    formikConfig: FormikState<State> & {
        setFieldTouched: (field: string, touched?: boolean, shouldValidate?: boolean | undefined) => any;
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
    }

    iconClass?: string;
    helpText?: ReactNode;

    component: (renderConfig: RenderConfig<FieldType>) => ReactNode
}

export function ValidatedFieldV2<State, FieldType>({
                                                     iconClass,
                                                     name,
                                                     label,
                                                     formikConfig,
                                                     helpText,
                                                     component,
                                                     required,
                                                     className
                                                 }: ValidatedFieldPropsV2<State, FieldType>) {
    const hasError = formikConfig.errors[name] && formikConfig.touched[name];

    const value = formikConfig.values[name] as unknown as FieldType

    const updateValue = (updatedValue: FieldType | undefined) => {
        formikConfig.setFieldTouched(name, true);
        formikConfig.setFieldValue(name, updatedValue);
    }

    const FieldContent = <>
        {iconClass && <Icon className={`pi ${iconClass}`}/>}

        {component({
            fieldValue: value,
            isValid: !hasError,
            fieldName: name,
            updateField: updateValue,
            required: required || false
        })}
    </>

    return (
        <div className={`field ${className ? className : ""}`}>
            {label ?
                <label htmlFor={name}
                       className={classNames({'p-error': hasError})}>{label}{required ? "*" : ""}</label> : null}

            {iconClass ? <span className="p-input-icon-right">{FieldContent}</span> : FieldContent}

            {hasError ? (
                <small className="p-error">{(formikConfig.errors as any)[name]}</small>
            ) : null}

            {helpText ? (
                <small>{helpText}</small>
            ) : null}
        </div>);
}

const Icon = styled.i`
  z-index: 1;
`
