import React, {useContext, useState} from 'react'
import graphql from 'babel-plugin-relay/macro'
import {usePaginationFragment} from "react-relay";
import {FaqsTable_Refetch} from "../../../__generated__/FaqsTable_Refetch.graphql";
import {FaqsTable_Faqs$key} from "../../../__generated__/FaqsTable_Faqs.graphql";
import {FaqSearchContext, FaqsFilters} from "./filter/FaqSearchContainer";
import {useDebounceFilters} from "../../../components/hooks/useDebounceFilter";
import {Accordion, AccordionTab} from "primereact/accordion";
import styled from "styled-components";

const FAQS_FRAGMENT = graphql`
    fragment FaqsTable_Faqs on Query @refetchable(queryName: "FaqsTable_Refetch") @argumentDefinitions(
        first: {type: "Int"},
        after: {type: "String"},
        filterByQuestion: {type: "String"}
    ){
        Viewer {
            Faq {
                Faqs(first: $first, after: $after, filterByQuestion: $filterByQuestion) @connection(key: "FaqsTable_Faqs") {
                    pageInfo {
                        startCursor
                        hasPreviousPage
                        hasNextPage
                        endCursor
                    }
                    edges {
                        node {
                            id
                            question
                            answer
                        }
                    }
                }
            }
        }

    }
`;


interface OwnProps {
    faqsFragmentRef: any
}

export const FaqsTable = ({faqsFragmentRef}: OwnProps) => {
    const {filters} = useContext(FaqSearchContext)
    const [activeIndex, setActiveIndex] = useState<number>()

    const {
        data: {Viewer: {Faq: {Faqs: {edges: faqs}}}},
        refetch,

    } = usePaginationFragment<FaqsTable_Refetch, FaqsTable_Faqs$key>(FAQS_FRAGMENT, faqsFragmentRef)

    useDebounceFilters<FaqsFilters>(filters!, refetch)

    const items = faqs?.map(l => l!.node!)

    return (
        <Container>
            <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                {items?.map((faq, index) => (
                    <AccordionTab header={faq.question} key={index}>
                        <p dangerouslySetInnerHTML={{__html: faq.answer}}/>
                    </AccordionTab>
                ))}
            </Accordion>
        </Container>
    )
}

const Container = styled.div`
  .p-accordion-tab {
    width: 30rem;
    margin-bottom: 20px;
  }
  
  .p-accordion-header-link {
    outline: none;
  }
`
