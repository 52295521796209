import React from 'react'
import styled from "styled-components";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";

interface OwnProps {
    value?: string;
    onChange: (value: string) => void
    isValid: boolean
}

export const IbanElement = ({value, onChange, isValid}: OwnProps) => {
    return (
        <IbanContainer className="p-float-label">
            <IbanInputText id="iban" value={value} onChange={(e) => onChange(e.target.value)} className={classNames({'p-invalid': !isValid})}/>
            <label htmlFor="iban">IBAN</label>
        </IbanContainer>
    )
}

const IbanContainer = styled.div`
  width: 100%;
  height: 2rem;
  background-color: cadetblue;
`

const IbanInputText = styled(InputText)`
  width: 100%
`
