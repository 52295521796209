import React, {Suspense} from "react";
import {BillingBase} from "./common/BillingBase";
import {BillingStep} from "./common/BillingStep";
import {BillingDetailsStep} from "./billing/BillingDetailsStep";
import {CartStep} from "./cart/CartStep";
import {OrderFlowContextProvider} from "../hooks/OrderFlowContext";
import {PaymentStep} from "./payment/PaymentStep";
import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {ReviewStep} from "./review/ReviewStep";
import {OrderFlowFooter} from "./footer/OrderFlowFooter";
import {BillingDetailsStepClosed} from "./billing/BillingDetailsStepClosed";
import {CartStepClosed} from "./cart/CartStepClosed";
import {PaymentStepClosed} from "./payment/PaymentStepClosed";
import {ThankYou} from "./ThankYou";
import {OrderFlowScreen_OrderFragment$key} from "../../../__generated__/OrderFlowScreen_OrderFragment.graphql";
import {AlreadyPurchased} from "./AlreadyPurchased";

const ORDER_FRAGMENT = graphql`
    fragment OrderFlowScreen_OrderFragment on Order {
        id
        status
        cart {
            ...CartStep_CartFragment
            ...CartStepClosed_CartFragment

            items {
                ... on CartProduct {
                    hasProduct
                }
            }
        }
        billingDetails{
            ...BillingDetailsStepClosed_BillingDetailsFragment
        }
        ...OrderFlowContextProvider_OrderFragment
        ...BillingDetailsStep_OrderFragment
        ...PaymentStep_OrderFragment
        ...PaymentStepClosed_OrderFragment
        ...ReviewStep_OrderFragment
    }
`;

interface OrderFlowProps {
    orderFragmentRef: OrderFlowScreen_OrderFragment$key
}


export const OrderFlow = ({orderFragmentRef}: OrderFlowProps) => {
    const order = useFragment<OrderFlowScreen_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef)

    if (!order) {
        return <div>Bestellung nicht gefunden.</div>;
    }

    if (order.status === "PaymentReceived" || order.status === "Purchased") {
        return <BillingBase mainSlot={
            <ThankYou/>
        }/>
    }

    if (order.cart?.items[0].hasProduct) {
        return <BillingBase mainSlot={
            <AlreadyPurchased/>
        }/>
    }

    return <OrderFlowContextProvider orderFragmentRef={order}>
        <BillingBase mainSlot={<div>
            <BillingStep
                stage={"cart"}
                renderOpen={() => <Suspense fallback={<div>Lade...</div>}>
                    <CartStep orderId={order.id} cartFragmentRef={order.cart}/>
                </Suspense>}
                renderClosed={() => <Suspense fallback={<div>Lade...</div>}>
                    <CartStepClosed cartFragmentRef={order.cart}/>
                </Suspense>}
            />

            <BillingStep
                stage={"billing"}
                renderOpen={() => {
                    return <Suspense fallback={<div>Lade</div>}>
                        <BillingDetailsStep orderFragmentRef={order}/></Suspense>
                }}
                renderClosed={() => <Suspense fallback={<div>Lade</div>}>
                    <BillingDetailsStepClosed billingDetailsFragmentRef={order.billingDetails}/>
                </Suspense>}
            />

            <BillingStep
                stage={"payment"}
                renderOpen={() => {
                    return <Suspense fallback={<div>Lade</div>}><PaymentStep orderFragmentRef={order}/></Suspense>
                }}
                renderClosed={() => <Suspense fallback={<div>Lade</div>}>
                    <PaymentStepClosed orderFragmentRef={order}/>
                </Suspense>}
            />

            <BillingStep
                stage={"review"}
                renderOpen={() => {
                    return <Suspense fallback={<div>Lade</div>}><ReviewStep orderFragmentRef={order}/></Suspense>
                }}/>
            <OrderFlowFooter/>
        </div>}/>
    </OrderFlowContextProvider>
}

