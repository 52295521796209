import {RegistrationFlowScreen} from "../screens/registration/RegistrationFlowScreen";
import {LoginScreen} from "../screens/login/LoginScreen";
import {ActivationScreen} from "../screens/activation/ActivationScreen";
import React from "react";
import {RouteAuthState, SecureRouteDefinition} from "../model/SecureRouteDefinition";
import {ForgotPasswordScreen} from "../screens/forgot-password/ForgotPasswordScreen";
import {ResetPasswordScreen} from "../screens/reset-password/ResetPasswordScreen";

export const ACTIVATION_PATH = "/activate/:token"
export const RESET_PASSWORD_PATH = "/reset-password/:token"

export const AuthModuleRoutes: SecureRouteDefinition[] = [
    {
        path: "/registration",
        element: <RegistrationFlowScreen />,
        requiredPermissions: [],
        authState: RouteAuthState.OnlyLoggedOut
    },
    {
        path: "/forgot-password",
        element: <ForgotPasswordScreen />,
        requiredPermissions: [],
        authState: RouteAuthState.OnlyLoggedOut
    },{
        path: RESET_PASSWORD_PATH,
        element: <ResetPasswordScreen />,
        requiredPermissions: [],
        authState: RouteAuthState.OnlyLoggedOut
    },
    {
        path: "/",
        element: <LoginScreen />,
        requiredPermissions: [],
        authState: RouteAuthState.OnlyLoggedOut
    },
    {
        path: ACTIVATION_PATH,
        element: <ActivationScreen />,
        requiredPermissions: [],
        authState: RouteAuthState.OnlyLoggedOut
    },
];
