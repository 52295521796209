import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import React from "react";
import styled from "styled-components";
import {CurrencyDisplay} from "../../../../components/currency/CurrencyDisplay";
import {ReviewCartTotalsDisplay_OrderFragment$key} from "../../../../__generated__/ReviewCartTotalsDisplay_OrderFragment.graphql";

const ORDER_FRAGMENT = graphql`
    fragment ReviewCartTotalsDisplay_OrderFragment on Order {
        selectedPaymentMethod {
            paymentMethodId
            selectedPaymentMethodType
            ... on MonthlySelectedPaymentMethod {
                chosenOption {
                    closingRate {
                        netPrice
                    }
                    totalAmount{
                        grossPrice
                    }
                    downPayment {
                        netPrice
                        taxRatePercentage
                        grossPrice
                    }
                    financedAmount {
                        netPrice
                        taxRatePercentage
                        grossPrice
                    }
                    monthlyRate {
                        netPrice
                        taxRatePercentage
                        grossPrice
                    }
                    totalAmount {
                        netPrice
                        taxRatePercentage
                        grossPrice
                    }
                    rateCount
                }
            }
        }
        cart {
            totals {
                appliedDiscountPercentage
                includingAllDiscounts {
                    netPrice
                    taxRatePercentage
                    grossPrice
                }
                withoutDiscounts {
                    netPrice
                    taxRatePercentage
                    grossPrice
                }
            }
        }

    }
`;

interface OwnProps {
    orderFragmentRef: ReviewCartTotalsDisplay_OrderFragment$key
}

export const ReviewCartTotalsDisplay = ({orderFragmentRef}: OwnProps) => {
    const order = useFragment<ReviewCartTotalsDisplay_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef)

    return <div className="mb-4">
        {order.selectedPaymentMethod?.selectedPaymentMethodType === "MonthlyPayment" && <>
            <TotalRow>
                <div>Anzahlung</div>
                <RightColumn><CurrencyDisplay
                    value={order.selectedPaymentMethod.chosenOption?.downPayment.netPrice! as number}/></RightColumn>
            </TotalRow>
            <SmallRow>
            </SmallRow>
            <TotalRow>
                <div>{order.selectedPaymentMethod.chosenOption?.rateCount} Monatliche Zahlungen je</div>
                <RightColumn>
                    <TotalDisplay>
                        <CurrencyDisplay
                            value={order.selectedPaymentMethod.chosenOption?.monthlyRate.netPrice! as number}/>
                    </TotalDisplay>
                </RightColumn>
            </TotalRow>
            <SmallRow>
            </SmallRow>
            {
                order.selectedPaymentMethod.chosenOption?.closingRate.netPrice !== 0 ? (
                        <SmallRow fullWidth={true}>
                            Die Anzahlung wird mit der ersten monatlichen Zahlung fällig.
                            {
                                order.selectedPaymentMethod.chosenOption?.totalAmount.netPrice ?
                                    <p>
                                        Mit dem Aufschlag für monatliche Zahlweise ergibt sich eine Gesamtzahlsumme
                                        von <CurrencyDisplay
                                        value={order.selectedPaymentMethod.chosenOption?.totalAmount.netPrice}/>
                                    </p>
                                    : null
                            }
                        </SmallRow>
                    ) :
                    null
            }
        </>}

        {order.selectedPaymentMethod?.selectedPaymentMethodType === "OneTimePayment" && <>
            <TotalRow>
                <div>Gesamtbetrag</div>
                <RightColumn>
                    <TotalDisplay>
                        {order.cart?.totals.appliedDiscountPercentage &&
                        <StrikePrice><CurrencyDisplay
                            value={order.cart?.totals.withoutDiscounts.netPrice as number}/></StrikePrice>}
                        <CurrencyDisplay
                            value={order.cart?.totals.includingAllDiscounts?.netPrice! as number}/>
                    </TotalDisplay>
                </RightColumn>
            </TotalRow>
            <SmallRow>
            </SmallRow>
        </>}

    </div>
}

interface SmallRowProps {
    fullWidth?: boolean
}

const SmallRow = styled.div<SmallRowProps>`
  display: grid;
  grid-template-columns: ${props => props && props.fullWidth ? '1fr' : '1fr 1fr'};
  font-size: 14px;
  margin-bottom: 10px;
`

const TotalRow = styled.div`
  font-weight: bold;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const TotalDisplay = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`

const StrikePrice = styled.div`
  text-decoration: line-through;
  font-weight: normal;
  margin-right: 5px;
  @media (max-width: 1024px) {
    margin-right: 0;
  }
`

const RightColumn = styled.div`
  margin-left: auto;
  text-align: right;
  display: flex;
  flex-direction: row;
`
