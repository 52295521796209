import {useFragment, useMutation} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import React, {useEffect, useRef} from "react";
import {CartItem} from "./CartItem";
import {CartTotalDisplay} from "./CartTotalDisplay";
import {DiscountCodeInput} from "./DiscountCodeInput";
import {CodeRefusalExplanation} from "./CodeRefusalExplanation";
import styled from "styled-components";
import {LoadingOverlay} from "./LoadingOverlay";
import {CartStep_CartFragment$key} from "../../../../__generated__/CartStep_CartFragment.graphql";
import {CartStep_UpdateCartMutation} from "../../../../__generated__/CartStep_UpdateCartMutation.graphql";

const CART_FRAGMENT = graphql`
    fragment CartStep_CartFragment on Cart {
        selection {            
            selectedDiscountCodes
            selectedProducts {
                amount
                productId
            }
        }
        totals {
            includingAllDiscounts {
                netPrice
            }
            ...CartTotalDisplay_CartTotalsFragment
        }
        items {
            ...CartItem_CartItemFragment
            ... on CartDiscount {
                code
            }
        }
        ...CodeRefusalExplanation_RefusalsFragment
    }
`;

const UPDATE_CART_MUTATION = graphql`
    mutation CartStep_UpdateCartMutation($updateCartInput: UpdateCartInput!) {
        Billing {
            updateCart(input: $updateCartInput) {
                order {
                    id
                    cart {
                        totals {
                            includingAllDiscounts {
                                netPrice
                            }
                        }
                        items {
                            ... on CartProduct {
                                product {
                                    ... on Product {
                                        id
                                        title
                                    }
                                }
                            }
                        }
                    }
                    ...OrderFlowScreen_OrderFragment
                }
            }
        }
    }
`;

interface OwnProps {
    orderId: string
    cartFragmentRef: CartStep_CartFragment$key | null
}

export const CartStep = ({orderId, cartFragmentRef}: OwnProps) => {
    const cart = useFragment<CartStep_CartFragment$key>(CART_FRAGMENT, cartFragmentRef)


    const [changeCart, isInFlight] = useMutation<CartStep_UpdateCartMutation>(UPDATE_CART_MUTATION)

    const ref = useRef<any>(null)

    useEffect(() => {
        ref.current?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }, [])


    const discountsInCart = cart?.items.map(i => i.code).filter(c => c !== undefined) as string[]
    return <Container ref={ref}>
        {isInFlight && <LoadingOverlay/>}

        <Separator/>

        <CodeRefusalExplanation codeRefusalsFragmentRef={cart}/>

        <CartTable>
            {cart?.items.map((cartItem, index) => {
                return <CartItem
                    cartItemFragmentRef={cartItem} key={index}/>
            })}
        </CartTable>

        <CartTotalDisplay cartTotalsFragmentRef={cart?.totals}/>

        <Separator/>

        <DiscountCodeInput onAddedDiscount={code => {
            changeCart({
                variables: {
                    updateCartInput: {
                        orderId: orderId,
                        cartSelection: {
                            selectedProducts: cart?.selection.selectedProducts ? [...cart?.selection.selectedProducts] : [],
                            selectedDiscountCodes: [...(cart?.selection.selectedDiscountCodes?.filter(c => c !== code) || []), code],
                        }
                    }
                }
            })
        }} discountCodesInCart={discountsInCart}/>

    </Container>
}

export const Separator = styled.hr`
  margin-top: 25px;
  border-top: solid 1px #e7ebf1;
`

const CartTable = styled.div`
  width: 100%;
  margin-bottom: 20px;
`
const Container = styled.div`
  scroll-margin: 50px;
  position: relative;
  width: 100%;
`
