import SepaImage from "./images/sepa.png";
import InvoiceImage from "./images/invoice.png";
import {useTranslation} from "react-i18next";
import React from "react";
import styled from "styled-components";


interface OwnProps {
    paymentMethod: any
}

export const PaymentOptionDisplay = ({paymentMethod}: OwnProps) => {
    const {t} = useTranslation("billing")

    let Image: any;
    switch (paymentMethod) {
        case "Sepa":
            Image = SepaImage;
            break;
        case "InvoiceTk":
        case "InvoicePartner":
            Image = InvoiceImage;
            break;
    }

    const title = t("payment-methods." + paymentMethod);


    return <PaymentMethodContainer>
        <PaymentMethodIcon src={Image} alt={title}/> {title}
    </PaymentMethodContainer>
}

const PaymentMethodIcon = styled.img`
  width: 45px;
  height: 25px;
  margin-right: 10px;
`

const PaymentMethodContainer = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  color: #000;
  display: flex;
  align-items: center;
`
