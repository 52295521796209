/**
 * @generated SignedSource<<63cd8bc85bc489f12ca4d46d9330a306>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FaqScreen_Query$variables = {};
export type FaqScreen_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FaqsTable_Faqs">;
};
export type FaqScreen_Query = {
  response: FaqScreen_Query$data;
  variables: FaqScreen_Query$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FaqScreen_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "FaqsTable_Faqs"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FaqScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FaqViewerSchema",
            "kind": "LinkedField",
            "name": "Faq",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FaqsConnection",
                "kind": "LinkedField",
                "name": "Faqs",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FaqsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Faq",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "question",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "answer",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": [
                  "filterByQuestion"
                ],
                "handle": "connection",
                "key": "FaqsTable_Faqs",
                "kind": "LinkedHandle",
                "name": "Faqs"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ef748412ee771575d2105d62be982ffc",
    "id": null,
    "metadata": {},
    "name": "FaqScreen_Query",
    "operationKind": "query",
    "text": "query FaqScreen_Query {\n  ...FaqsTable_Faqs\n}\n\nfragment FaqsTable_Faqs on Query {\n  Viewer {\n    Faq {\n      Faqs {\n        pageInfo {\n          startCursor\n          hasPreviousPage\n          hasNextPage\n          endCursor\n        }\n        edges {\n          node {\n            id\n            question\n            answer\n            __typename\n          }\n          cursor\n        }\n      }\n    }\n  }\n}\n"
  }
};

(node as any).hash = "11cb3053564a68e1ca48165c5845abb9";

export default node;
