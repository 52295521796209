import React from "react";
import {ValidatedFieldV2} from "../../../../../components/form/ValidatedFieldV2";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import styled from "styled-components";
import {PrivateFormState} from "./PrivateForm";
import {Password} from "primereact/password";
import {InputSwitch} from "primereact/inputswitch";

interface OwnProps {
    formik: any
    submitted: boolean;
}

export const PrivateSignupFormPart = ({formik, submitted}: OwnProps) => {
    return (
        <div>
            <h3 className="tk-academy-large tk-color-waterblue-80 tk-academy-bold mb-2">Konto erstellen</h3>
            <FormContainer onSubmit={formik.handleSubmit} className="p-fluid">
                <ValidatedFieldV2<PrivateFormState, string>
                    name={"email"}
                    label={"E-Mail"}
                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <InputText id={fieldName} name={fieldName} value={fieldValue}
                                          onChange={e => updateField(e.target.value)}
                                          className={classNames({'p-invalid': !isValid})}/>

                    }}/>
                <ValidatedFieldV2<PrivateFormState, string>
                    name={"email2"}
                    label={"E-Mail wiederholen"}
                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <InputText id={fieldName} name={fieldName} value={fieldValue}
                                          onChange={e => updateField(e.target.value)}
                                          className={classNames({'p-invalid': !isValid})}/>

                    }}/>
                <ValidatedFieldV2<PrivateFormState, string>
                    name={"password"}
                    label={"Passwort"}
                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <Password id={fieldName} name={fieldName} value={fieldValue}
                                         onChange={e => updateField(e.target.value)}
                                         className={classNames({'p-invalid': !isValid})}/>

                    }}/>
                <ValidatedFieldV2<PrivateFormState, string>
                    name={"password2"}
                    label={"Passwort wiederholen"}
                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <Password id={fieldName} name={fieldName} value={fieldValue}
                                         onChange={e => updateField(e.target.value)}
                                         className={classNames({'p-invalid': !isValid})}/>

                    }}/>
                <ValidatedFieldV2<PrivateFormState, boolean>
                    name={"legal"}
                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <div className="flex">
                            <InputSwitch
                                className="mr-2 flex-shrink-0"
                                checked={fieldValue}
                                onChange={e => updateField(e.target.value)}/>
                            <label className="w-20rem">
                                Mit der Anmeldung stimmst du der <a
                                rel="noopener noreferrer"
                                href='https://www.thekey.community/datenschutz/gi'
                                target='_blank'>Datenschutzerklärung</a> zu.
                            </label>
                        </div>

                    }}/>
                <ValidatedFieldV2<PrivateFormState, boolean>
                    name={"adsOptIn"}
                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName}) => {
                        return <div className="flex">
                            <InputSwitch
                                className="mr-2 flex-shrink-0"
                                checked={fieldValue}
                                onChange={e => updateField(e.target.value)}/>
                            <label htmlFor={fieldName}
                                   className="w-20rem">
                                thekey.community-Brieffreundschaft: Ich freue mich, über Aktionen, Rabatte und
                                Neuigkeiten per E-Mail informiert zu werden.
                            </label>
                        </div>

                    }}/>
            </FormContainer>
            {submitted &&
                <Information><RedText>Hinweis: Vielen Dank für die Registrierung. Bitte überprüfen Sie Ihre E-Mails und
                    aktivieren Sie Ihr Konto.</RedText></Information>
            }
        </div>
    )
}


const FormContainer = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`
const RedText = styled.span`
  color: rgb(252, 35, 39)`;

const Information = styled.div`
  border: solid 2px #e7ebf1;
  border-radius: 6px;
  padding: 1rem;
  margin: 1rem 0;
`
