import React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Card} from "primereact/card";
import {ValidatedFieldV2} from "../../../../../components/form/ValidatedFieldV2";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import styled from "styled-components";
import graphql from "babel-plugin-relay/macro";
import {useTypedDispatch} from "../../../../../Store";
import {useMutation} from "react-relay";
import {LoginScreen_LoginMutation} from "../../../../../__generated__/LoginScreen_LoginMutation.graphql";
import {LoginData, setLoggedIn} from "../../../../../slices/AuthSlice";

const LOGIN_MUTATION = graphql`
    mutation BillingLogin_LoginMutation($input: LoginInput!){
        Auth{
            login(input: $input) {
                userId
                token
                groupAssociations {
                    account {
                        id
                    }
                }
            }
        }
    }
`;

interface FormState {
    email: string
    password: string
}


export const BillingLogin = () => {
    const [login, isLoggingIn] = useMutation<LoginScreen_LoginMutation>(LOGIN_MUTATION)
    const dispatch = useTypedDispatch()

    const formik = useFormik<FormState>({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup
                .string()
                .email("Bitte geben Sie eine gültige E-Mail ein")
                .required("Das Feld E-Mail wird benötigt."),
            password: Yup
                .string()
                .min(8, "Ein Passwort muss mindestens 8 Stellen lang sein.")
                .required("Das Feld Passwort wird benötigt."),
        }),
        onSubmit: (data) => {
            login({
                variables: {
                    input: {
                        email: data.email,
                        password: data.password
                    }
                },
                onCompleted: response => {
                    if (response.Auth.login) {
                        dispatch(setLoggedIn({loginData: response.Auth.login as LoginData}))
                        window.location.reload()
                    }
                }
            })
        }
    });

    return <div>
        <Card>
            <FormContainer onSubmit={formik.handleSubmit} className="p-fluid">
                <ValidatedFieldV2<FormState, string>
                    name={"email"}
                    label={"E-Mail"}
                    iconClass={"pi-envelope"}
                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <InputText id={fieldName} name={fieldName} value={fieldValue}
                                          onChange={e => updateField(e.target.value)}
                                          className={classNames({'p-invalid': !isValid})}/>

                    }}/>
                <ValidatedFieldV2<FormState, string>
                    name={"password"}
                    label={"Passwort"}
                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <Password id={fieldName} name={fieldName} value={fieldValue}
                                         onChange={e => updateField(e.target.value)}
                                         toggleMask
                                         feedback={false}
                                         className={classNames({'p-invalid': !isValid})}
                        />

                    }}/>
                <Button disabled={isLoggingIn} type="button" onClick={() => formik.handleSubmit()} label="Einloggen" className="mt-2 mb-2"/>
            </FormContainer>
        </Card>
    </div>
}

const FormContainer = styled.form`
  min-width: 400px;

    @media (max-width: 1024px) {
        min-width: unset;
    }
`
