/**
 * @generated SignedSource<<146b2d8ff3e931182cc90a0b944054c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TrainingDayInformation_TrainingDayFragment$data = {
  readonly date: string;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"AdditionalTrainingDayInformationButton_TrainingDayFragment">;
  readonly " $fragmentType": "TrainingDayInformation_TrainingDayFragment";
};
export type TrainingDayInformation_TrainingDayFragment$key = {
  readonly " $data"?: TrainingDayInformation_TrainingDayFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TrainingDayInformation_TrainingDayFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TrainingDayInformation_TrainingDayFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdditionalTrainingDayInformationButton_TrainingDayFragment"
    }
  ],
  "type": "PublishedTrainingDay",
  "abstractKey": null
};

(node as any).hash = "f13c61c260846dd6e0dc1dbe44e12fb6";

export default node;
