/**
 * @generated SignedSource<<a0a1ade6e14bd9366ca4b447bbc91ec4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AttendanceStatus = "Absent" | "CanceledByUser" | "CanceledTooLate" | "CourseCanceledByAdmin" | "NotPresent" | "TookPart" | "Unknown" | "WaitingList" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TrainingDayInformation_CourseStateFragment$data = {
  readonly moduleStates: ReadonlyArray<{
    readonly moduleId: string;
    readonly trainingDayStates: ReadonlyArray<{
      readonly attendanceListViewer: ReadonlyArray<{
        readonly attendanceStatus: AttendanceStatus;
        readonly userId: string;
      }>;
      readonly trainingDayId: string;
    }>;
  }>;
  readonly " $fragmentType": "TrainingDayInformation_CourseStateFragment";
};
export type TrainingDayInformation_CourseStateFragment$key = {
  readonly " $data"?: TrainingDayInformation_CourseStateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TrainingDayInformation_CourseStateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TrainingDayInformation_CourseStateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ModuleState",
      "kind": "LinkedField",
      "name": "moduleStates",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "moduleId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TrainingDayState",
          "kind": "LinkedField",
          "name": "trainingDayStates",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "trainingDayId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AttendanceTypeViewer",
              "kind": "LinkedField",
              "name": "attendanceListViewer",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "userId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "attendanceStatus",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CourseState",
  "abstractKey": null
};

(node as any).hash = "36d3727f114224c11d280dce47442d71";

export default node;
