import React, {useEffect, useState} from 'react';
import graphql from 'babel-plugin-relay/macro'
import {Button} from "primereact/button";
import {Dialog} from 'primereact/dialog';
import {AdditionalTrainingDayInformation} from './AdditionalTrainingDayInformation';
import {useFragment} from "react-relay";
import {
    AdditionalTrainingDayInformationButton_TrainingDayFragment$key
} from "../../../../__generated__/AdditionalTrainingDayInformationButton_TrainingDayFragment.graphql";
import {DateTimeDisplay} from "../../../../components/datetime/DateTimeDisplay";

const TRAINING_DAY_FRAGMENT = graphql`
    fragment AdditionalTrainingDayInformationButton_TrainingDayFragment on PublishedTrainingDay {
        date
        additionalInformation
        ... AdditionalTrainingDayInformation_TrainingDayFragment
    }
`

interface OwnProps {
    trainingDayFragmentRef: any
}

export const AdditionalTrainingDayInformationButton = ({trainingDayFragmentRef}: OwnProps) => {
    const trainingDay = useFragment<AdditionalTrainingDayInformationButton_TrainingDayFragment$key>(TRAINING_DAY_FRAGMENT, trainingDayFragmentRef)
    const [isVisible, setVisible] = useState<boolean>(false)
    const [showButton, setShowButton] = useState<boolean>(true)

    useEffect(() => {
        if(!trainingDay.additionalInformation) {
            setShowButton(false)
        }
    }, [trainingDay])

    return showButton ? (
            <>
                <Button
                    onClick={() => {
                        setVisible(true);
                    }}
                    icon="pi pi-search-plus"
                    className="ml-2"/>

                {(isVisible) &&
                    <Dialog header={() => <DateTimeDisplay value={trainingDay.date.toString()} showTime={false} hideTimezone/>}
                            closeOnEscape
                            visible={true}
                            modal={false}
                            onHide={() => setVisible(false)}>
                        <AdditionalTrainingDayInformation trainingDayFragmentRef={trainingDay}/>
                    </Dialog>}
            </>
        ) : null
}
