/**
 * @generated SignedSource<<48b2c312ca48fa6c305760a91823b2e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReviewStep_OrderFragment$data = {
  readonly cart: {
    readonly " $fragmentSpreads": FragmentRefs<"ReviewCart_CartFragment">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ReviewCartTotalsDisplay_OrderFragment" | "ReviewForm_OrderFragment">;
  readonly " $fragmentType": "ReviewStep_OrderFragment";
};
export type ReviewStep_OrderFragment$key = {
  readonly " $data"?: ReviewStep_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReviewStep_OrderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewStep_OrderFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReviewForm_OrderFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReviewCartTotalsDisplay_OrderFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ReviewCart_CartFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "6643111b60e2a67e687e4fc88a8ee7c1";

export default node;
