import {FaqScreen} from "../screens/faq/components/FaqScreen";
import {RouteAuthState, SecureRouteDefinition} from "../model/SecureRouteDefinition";

export const FaqModuleRoutes: SecureRouteDefinition[] = [
    {
        path: "/faqs",
        element: <FaqScreen/>,
        requiredPermissions: [],
        authState: RouteAuthState.OnlyLoggedIn
    },
];
