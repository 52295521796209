import {graphql} from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import React from "react";
import {useTranslation} from "react-i18next";
import {FormikProps} from "formik";
import styled from "styled-components";
import {ReviewFormState} from "./ReviewForm";
import {PaymentDataFormPart_OrderFragment$key} from "../../../../../__generated__/PaymentDataFormPart_OrderFragment.graphql";
import {IbanElement} from "./IbanElement";

interface OwnProps {
    orderFragmentRef: PaymentDataFormPart_OrderFragment$key
    formikState: FormikProps<ReviewFormState>
}

export const PaymentDataFormPart = ({orderFragmentRef, formikState}: OwnProps) => {
    const {t} = useTranslation("billing")
    const order = useFragment<PaymentDataFormPart_OrderFragment$key>(graphql`
        fragment PaymentDataFormPart_OrderFragment on Order {
            selectedPaymentMethod {
                paymentMethodId
            }
        }
    `, orderFragmentRef)

    let PaymentOptionDisplay = null

    const error = formikState.errors.iban

    switch (order.selectedPaymentMethod?.paymentMethodId) {
        case "Sepa":
            PaymentOptionDisplay = <IbanElement value={formikState.values.iban}
                                                onChange={(value: string) => formikState.setFieldValue("iban", value)}
                                                isValid={!error}/>
            break;
        default:
            break;
    }

    return PaymentOptionDisplay ? <Container>
        <Heading>Zahlung per {t("payment-methods." + order.selectedPaymentMethod?.paymentMethodId)}</Heading>

        <PaymentInputWrapper>
            {PaymentOptionDisplay}
        </PaymentInputWrapper>

        {error ? (
            <ErrorContainer>
                <div/>
                <div/>
                <div>
                    {error}
                </div>
            </ErrorContainer>
        ) : null}
    </Container> : null;

}

const Heading = styled.h3`
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 15px;
  color: #184276;
`

const Container = styled.div`
  margin-bottom: 25px;
  /*border-top: solid 1px #e7ebf1;*/
  padding-top: 25px;
`

const PaymentInputWrapper = styled.div`
  margin-top: 2rem
`

const ErrorContainer = styled.div`
  margin-top: 1rem;
  color: red;
`
