import styled, {css} from "styled-components";

export const PaymentOptionHeaderContainer = styled.div`
  display: flex;
  
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`

export const PaymentOptionHeading = styled.h4`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  color: #000;
`

export const PaymentOptionHeadingPrice = styled.div`
  font-weight: bold;
  margin-left: auto;
  font-size: 16px;
  color: black;

  @media (max-width: 1024px) {
    margin-left: 0;
  }
`


export const PaymentOptionCategoryContainer = styled.div`
  padding: 25px;
  border-radius: 6px;
  border: solid 2px #e7ebf1;
  width: 100%;
  background-color: #f3f5f8;
  cursor: pointer;


  &:not(:last-of-type) {
    margin-bottom: 25px;    
  }

  @media (max-width: 1024px) {
    padding: 15px;
  }

`

export const PaymentOptionsContainer = styled.div`
  margin-top: 20px;
`

interface MonthlyOptionContainerProps {
    isSelected: boolean
}

export const PaymentOptionContainer = styled.div<MonthlyOptionContainerProps>`
  padding: 25px;
  border-radius: 6px;
  border: solid 2px #e7ebf1;
  width: 100%;
  background-color: white;
  margin-bottom: 20px;

  ${props => props.isSelected && css`
  border: solid 2px #4ace7f;
`};

  @media (max-width: 1024px) {
    padding: 15px;
  }
`
