import React from "react";
import {Button} from 'primereact/button'
import {BillingStepContainer, BillingStepHeader, BillingStepHeading, BillingStepIcon} from "./common/BillingStep";
import {Separator} from "./payment/PaymentStep";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";

export const ThankYou = () => {
    const navigate = useNavigate();

    return <BillingStepContainer>
        <BillingStepHeader>
            <BillingStepIcon isLocked={false} isCurrent={false}
                             className={"icon-a_tick_circle"}/>
            <BillingStepHeading>
                Vielen Dank für Deine Bestellung
            </BillingStepHeading>
        </BillingStepHeader>
        <div className="d-flex flex-row">
            <PaymentContainer>
                <Separator/>
                <Text>Deine Rechnung schicken wir Dir umgehend an Deine hinterlegte E-Mail Adresse.</Text>
                <div>
                    <Button onClick={() => navigate("/")}>Zum Dashboard</Button>
                </div>
            </PaymentContainer>


        </div>

    </BillingStepContainer>
}

const PaymentContainer = styled.div`
  width: 100%;
  position: relative;
`

const Text = styled.div`
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  line-height: 1.2;
  color: #184276;
  margin-bottom: 20px;
`
