import React from "react";
import {useFragment, useMutation} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {
    PaymentOptionCategoryContainer,
    PaymentOptionContainer,
    PaymentOptionHeaderContainer,
    PaymentOptionHeading,
    PaymentOptionHeadingPrice,
    PaymentOptionsContainer
} from "./common/StyledComponents";
import {LoadingOverlay} from "../cart/LoadingOverlay";
import {PaymentOptionDisplay} from "./PaymentOptionDisplay";
import {OneTimePaymentMethodCategory_OrderFragment$key} from "../../../../__generated__/OneTimePaymentMethodCategory_OrderFragment.graphql";
import {OneTimePaymentMethodCategory_ChangePaymentMethodMutation} from "../../../../__generated__/OneTimePaymentMethodCategory_ChangePaymentMethodMutation.graphql";
import {CurrencyDisplay} from "../../../../components/currency/CurrencyDisplay";

const ORDER_FRAGMENT = graphql`
    fragment OneTimePaymentMethodCategory_OrderFragment on Order {
        id
        selectedPaymentMethod {
            paymentMethodId
            selectedPaymentMethodType
        }
        allowedPaymentMethods

        cart {
            totals {
                includingAllDiscounts{
                    netPrice
                    grossPrice
                }
            }
        }
    }
`;

const CHANGE_PAYMENT_METHOD_MUTATION = graphql`
    mutation OneTimePaymentMethodCategory_ChangePaymentMethodMutation($input: ChangePaymentMethodInput!) {
        Billing {
            changePaymentMethod(input: $input) {
                order {
                    ...OrderFlowScreen_OrderFragment
                }
            }
        }
    }
`;

interface OwnProps {
    orderFragmentRef: OneTimePaymentMethodCategory_OrderFragment$key
}

export const OneTimePaymentMethodCategory = ({orderFragmentRef}: OwnProps) => {
    const order = useFragment<OneTimePaymentMethodCategory_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef)
    const [changePaymentMethod, isInFlight] = useMutation<OneTimePaymentMethodCategory_ChangePaymentMethodMutation>(CHANGE_PAYMENT_METHOD_MUTATION)

    const isOpen = order.selectedPaymentMethod?.selectedPaymentMethodType === "OneTimePayment"

    return <>
        <PaymentOptionCategoryContainer onClick={() => {
            if (!isOpen) {
                changePaymentMethod({
                    variables: {
                        input: {
                            orderId: order.id,
                            paymentType: "OneTimePayment",
                            paymentMethodType: "Sepa",
                        }
                    }
                })
            }
        }}>
            <PaymentOptionHeaderContainer>
                <PaymentOptionHeading>Einmalige Zahlung</PaymentOptionHeading>

                {order.cart?.totals.includingAllDiscounts && <PaymentOptionHeadingPrice>
                    einmalig <CurrencyDisplay value={order.cart.totals.includingAllDiscounts.netPrice} />
                </PaymentOptionHeadingPrice>}
            </PaymentOptionHeaderContainer>

            {isOpen && <PaymentOptionsContainer>
                {order.allowedPaymentMethods.map(paymentMethod => {
                    switch (paymentMethod) {
                        case "Sepa":
                        case "InvoiceTk":
                            return <PaymentOptionContainer
                                key={paymentMethod}
                                isSelected={paymentMethod === order.selectedPaymentMethod?.paymentMethodId}
                                onClick={() => {
                                    changePaymentMethod({
                                        variables: {
                                            input: {
                                                orderId: order.id,
                                                paymentType: "OneTimePayment",
                                                paymentMethodType: paymentMethod
                                            }
                                        }
                                    })
                                }}>
                                <PaymentOptionDisplay paymentMethod={paymentMethod}/>
                            </PaymentOptionContainer>
                        default:
                            return null
                    }
                })}

            </PaymentOptionsContainer>}
        </PaymentOptionCategoryContainer>

        {isInFlight && <LoadingOverlay/>}
    </>
}
