import React, {createContext, ReactNode, useEffect, useState} from "react";
import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {useLocation, useNavigate} from "react-router-dom";
import {
    OrderFlowContextProvider_OrderFragment$key,
    OrderStatus
} from "../../../__generated__/OrderFlowContextProvider_OrderFragment.graphql";
import {useTypedSelector} from "../../../Store";
import {CurrentUserData, selectCurrentUser} from "../../../slices/AuthSlice";

export type FlowStage = "cart" | "billing" | "payment" | "review"
export const FLOW_STAGES: FlowStage[] = ["cart", "billing", "payment", "review"]

export interface OrderContext {
    openStage: FlowStage
    canAdvance: boolean
    canGoBack: boolean
    tryAdvance: () => void
    advance: () => void
    goBack: () => void
    goToStage: (stage: FlowStage) => void
    reviewFormValid: boolean
    setReviewFormValid: (valid: boolean) => void
    setSubmitBillingForm: (submitFunction: () => void) => void
    updateReviewFormState: (submitFunction: () => void, isValid: boolean) => void
}


export const OrderFlowContext = createContext<OrderContext>(null as any)

const ORDER_FRAGMENT = graphql`
    fragment OrderFlowContextProvider_OrderFragment on Order {
        status
        cart {
            items {
                itemType
            }
        }
    }`

interface OwnProps {
    orderFragmentRef: OrderFlowContextProvider_OrderFragment$key
    children: ReactNode
}

const openStageFromOrderStatus = (orderStatus: OrderStatus, user: CurrentUserData | undefined): FlowStage => {
    switch (orderStatus) {
        case "Transient":
            return "cart"
        case "HasCart":
            return "cart";
        case "HasBillingDetails":
            if (!user) return "billing"
            return "payment"
        case "HasPaymentDetails":
            if (!user) return "billing"
            return "review"
        default:
            return "cart"
    }
};

interface FormState {
    requiredDiscountCode?: string
    reviewFormValid: boolean
    submitReviewForm?: () => void;
    submitBillingForm?: () => void;
}


export const OrderFlowContextProvider = ({orderFragmentRef, children}: OwnProps) => {

    const order = useFragment<OrderFlowContextProvider_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef);
    const currentUser = useTypedSelector(selectCurrentUser);

    const [openStage, setOpenStage] = useState<FlowStage>(openStageFromOrderStatus(order.status, currentUser));

    const [formState, setFormState] = useState<FormState>({
        requiredDiscountCode: window.sessionStorage.getItem("requiredCode") || "",
        reviewFormValid: false,
    })

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (openStage === "payment" && location.search.length > 0) navigate(location.pathname, {
            replace: true,
            state: location.state
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openStage])

    const canGoBack = openStage !== "cart"
    let goBack = () => {
    }
    let canAdvance = false
    let tryAdvance = () => {
    }
    let advance = () => {
    }

    switch (openStage) {
        case "cart":
            canAdvance = (["HasCart", "HasBillingDetails", "HasPaymentDetails", "PaymentReceived"] as OrderStatus[]).includes(order.status)
                && order.cart?.items.find(c => c.itemType === "CartProduct") !== undefined
            advance = () => {
                setOpenStage("billing")
            }
            tryAdvance = advance
            break;
        case "billing":
            canAdvance = true
            tryAdvance = () => {
                if (formState.submitBillingForm) {
                    formState.submitBillingForm()
                }
            }
            advance = () => {
                setOpenStage("payment")

            }
            goBack = () => {
                setOpenStage("cart")
            }
            break;
        case "payment":
            canAdvance = (["HasPaymentDetails", "PaymentReceived"] as OrderStatus[]).includes(order.status)
            tryAdvance = () => {
                setOpenStage("review")
            }
            advance = tryAdvance
            goBack = () => {
                setOpenStage("billing")
            }
            break;
        case "review":
            canAdvance = true
            tryAdvance = () => {
                if (formState.submitReviewForm) {
                    formState.submitReviewForm()
                }
            }
            advance = () => tryAdvance()
            goBack = () => {
                setOpenStage("payment")
            }
    }

    return <OrderFlowContext.Provider value={{
        canGoBack: canGoBack,
        canAdvance: canAdvance,
        advance: advance,
        goBack: goBack,
        tryAdvance: tryAdvance,
        openStage: openStage,
        goToStage: stage => {
            setOpenStage(stage)
        },
        reviewFormValid: formState.reviewFormValid,
        setReviewFormValid: valid => setFormState({...formState, reviewFormValid: valid}),
        setSubmitBillingForm: submitFunction => {
            setFormState(() => ({...formState, submitBillingForm: submitFunction}))
        },
        updateReviewFormState: (submitFunction, isValid) => {
            setFormState({...formState, reviewFormValid: isValid, submitReviewForm: submitFunction})
        }
    }}>
        {children}
    </OrderFlowContext.Provider>
}

