/**
 * @generated SignedSource<<4c0dfffdc84d01895c7a5af1ec061762>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PaymentMethodType = "Card" | "Giropay" | "IapApple" | "IapGoogle" | "InvoicePartner" | "InvoiceTk" | "MonthlyPartner" | "MonthlyTk" | "Paypal" | "Sepa" | "Sofort" | "%future added value";
export type SelectedPaymentMethodType = "EmployerInvoice" | "MonthlyPayment" | "OneTimePayment" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EmployerInvoicePaymentMethodCategory_OrderFragment$data = {
  readonly cart: {
    readonly totals: {
      readonly includingAllDiscounts: {
        readonly grossPrice: number;
        readonly netPrice: number;
      };
    };
  } | null;
  readonly id: string;
  readonly selectedPaymentMethod: {
    readonly paymentMethodId: PaymentMethodType;
    readonly selectedPaymentMethodType: SelectedPaymentMethodType;
  } | null;
  readonly " $fragmentType": "EmployerInvoicePaymentMethodCategory_OrderFragment";
};
export type EmployerInvoicePaymentMethodCategory_OrderFragment$key = {
  readonly " $data"?: EmployerInvoicePaymentMethodCategory_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployerInvoicePaymentMethodCategory_OrderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployerInvoicePaymentMethodCategory_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "selectedPaymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentMethodId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "selectedPaymentMethodType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CartTotals",
          "kind": "LinkedField",
          "name": "totals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Price",
              "kind": "LinkedField",
              "name": "includingAllDiscounts",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "netPrice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "grossPrice",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "e36a0f9d777a7ee7b73117b26d3ba33e";

export default node;
