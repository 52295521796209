import React, {useState} from 'react'
import {graphql} from "babel-plugin-relay/macro";
import {useLazyLoadQuery, useSubscribeToInvalidationState} from "react-relay";
import {CourseItem} from "./parts/CourseItem";
import {RebookedCoursesScreen_Query} from "../../__generated__/RebookedCoursesScreen_Query.graphql";

const COURSES_QUERY = graphql`
    query RebookedCoursesScreen_Query {
        Viewer {
            States {
		            CourseStatesForUserWithRebookedAttendence(first: null, after: null) @connection(key: "RebookedCoursesScreen_CourseStatesForUserWithRebookedAttendence") {
				            edges {
						            node {
                            id
                            publishedCourse {
                                ...CourseItem_CourseFragment
                            }
                            ...CourseItem_CourseStateFragment
                        }
                    }
		            }
            }
        }
    }
`

interface State {
	fetchKey: number
}

export const RebookedCoursesScreen = () => {
	const [state, setState] = useState<State>({fetchKey: 0})

	const query = useLazyLoadQuery<RebookedCoursesScreen_Query>(
		COURSES_QUERY,
		{},
		{
			fetchPolicy: "network-only",
			fetchKey: state.fetchKey
		}
	)

	const coursesStates = query.Viewer.States.CourseStatesForUserWithRebookedAttendence.edges?.filter(l=>!!l).map(k=>k?.node);

	useSubscribeToInvalidationState(coursesStates?.map(l => l!.id) || [], () => {
		setState(state => ({...state, fetchKey: state.fetchKey + 1}))
	})


	return (
		<div className="flex flex-column align-items-center">
			<h2 className="mb-8">Meine Lehrgänge</h2>
			<div>
				{coursesStates ?
					coursesStates.map((courseState, index) =>
						courseState && <CourseItem key={index}
						                           courseFragmentRef={courseState.publishedCourse}
						                           courseStateFragmentRef={courseState}/>
					)
					: null
				}
			</div>
		</div>
	)

};
