import React from 'react';
import graphql from 'babel-plugin-relay/macro'
import {useFragment} from "react-relay";
import {
    ExamInformation_PublishedModuleFragment$key
} from "../../../../__generated__/ExamInformation_PublishedModuleFragment.graphql";
import {Accordion, AccordionTab} from "primereact/accordion";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const PUBLISHED_MODULE_FRAGMENT = graphql`
    fragment ExamInformation_PublishedModuleFragment on PublishedModuleViewer {
        remainingDaysForExam
        isExam
    }
`

interface OwnProps {
    publishedModuleFragmentRef: ExamInformation_PublishedModuleFragment$key
}

export const ExamInformation = ({publishedModuleFragmentRef}: OwnProps) => {
    const {t} = useTranslation("courseInformation")
    const tNamespace = "moduleItems.examInformation"

    const {
        isExam,
        remainingDaysForExam
    } = useFragment<ExamInformation_PublishedModuleFragment$key>(PUBLISHED_MODULE_FRAGMENT, publishedModuleFragmentRef)

    const metRequirements = remainingDaysForExam === 0

    return isExam ? (
        <Container metRequirements={metRequirements}>
            <Accordion className="mb-4">
                <AccordionTab disabled={metRequirements}
                              header={metRequirements ?
                                  t(`${tNamespace}.requirementsMet`) :
                                  t(`${tNamespace}.requirementsNotMet`)}>
                    {remainingDaysForExam === 1 ?
                        t(`${tNamespace}.information.singular`) :
                        t(`${tNamespace}.information.plural`, {missingPoints: remainingDaysForExam})}
                </AccordionTab>
            </Accordion>
        </Container>
    ) : null
}

const Container = styled.div<{
    metRequirements: boolean
}>`
  .p-accordion,
  .p-accordion-tab:last-child,
  .p-accordion-header:not(.p-highlight),
  .p-accordion-header-link {
    background-color: ${props => props.metRequirements ? "lightgreen" : "#D32F2F"} !important;
    color: ${props => props.metRequirements ? "black" : "white"} !important;
    font-size: 0.9rem;
  }

  .p-accordion-toggle-icon {
    display: ${props => props.metRequirements ? "none" : "block"} !important;
  }
`
