import {CoursesScreen} from "../screens/courses/CoursesScreen";
import {CourseInformationScreen} from "../screens/courseInformation/CourseInformationScreen";
import {RouteAuthState, SecureRouteDefinition} from "../model/SecureRouteDefinition";
import {RebookedCoursesScreen} from "../screens/courses/RebookedCoursesScreen";

export const COURSES = "/courses"
export const REBOOKED_COURSES = "/rebooked-courses"
export const COURSE_INFORMATION = "/courses/:publishedCourseId"

export const CoursesModuleRoutes: SecureRouteDefinition[] = [
    {
        path: COURSES,
        element: <CoursesScreen/>,
        requiredPermissions: [],
        authState: RouteAuthState.OnlyLoggedIn
    },
    {
        path: COURSE_INFORMATION,
        element: <CourseInformationScreen/>,
        requiredPermissions: [],
        authState: RouteAuthState.OnlyLoggedIn
    },
    {
        path: REBOOKED_COURSES,
        element: <RebookedCoursesScreen />,
        requiredPermissions: [],
        authState: RouteAuthState.OnlyLoggedIn
    },


];
