import React, {ReactNode} from 'react';
import {Card} from "primereact/card";

interface OwnProps {
    children: ReactNode
}

export const BaseAuthScreen = ({children}: OwnProps) => {
    return (
        <div className="flex justify-content-center">
            <Card>
                {children}
            </Card>
        </div>
    )
}
