import React from "react";
import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import styled from "styled-components";
import {BillingDetailsStepClosed_BillingDetailsFragment$key} from "../../../../__generated__/BillingDetailsStepClosed_BillingDetailsFragment.graphql";

interface OwnProps {
    billingDetailsFragmentRef: BillingDetailsStepClosed_BillingDetailsFragment$key | null | undefined
}

export const BillingDetailsStepClosed = ({billingDetailsFragmentRef}: OwnProps) => {
    const billingDetails = useFragment<BillingDetailsStepClosed_BillingDetailsFragment$key>(graphql`
        fragment BillingDetailsStepClosed_BillingDetailsFragment on BusinessBillingDetails {
            firstName
            lastName
            customerType
            company

            ... on BusinessBillingDetails{
                company
            }
        }
    `, billingDetailsFragmentRef || null)

    return <ClosedStateContainer>
        {billingDetails?.customerType === "Private" && <>
            Private Bestellung für {billingDetails.firstName} {billingDetails.lastName}
        </>}
    </ClosedStateContainer>
}

const ClosedStateContainer = styled.div`
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  line-height: 1.38;
  color: #184276;
  margin-top: 5px;
`
