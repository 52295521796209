import React, {Suspense} from 'react';
import * as Sentry from '@sentry/react';
import {Loader} from "./components/loading/Loader";
import {ToastContainer} from "react-toastify";
import {addLocale, locale} from "primereact/api";
import "./i18n/i18n";
import {Provider} from "react-redux";
import {ReduxStore} from './Store';
import {RelayEnvironmentProvider} from "react-relay";
import {RelayEnvironment} from "./RelayEnvironment";
import {RootNavigation} from "./navigation/RootNavigation";

locale("de")

addLocale('de', {
    firstDayOfWeek: 0,
    dayNames: ['Montag', 'Dienst', 'Mittwoche', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
    dayNamesShort: ['Mon', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
    dayNamesMin: ['Mon', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
    monthNames: ['Januar', 'Feburar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    monthNamesShort: ['Jan', 'Feb', 'März', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    today: 'Heute',
    clear: 'Zurücksetzen'
});

export const CONTACT_EMAIL = "mailto:termine@thekey.community"


function App() {
    return (
        <Provider store={ReduxStore}>
            <ToastContainer autoClose={5000} newestOnTop={true}/>
            <Sentry.ErrorBoundary onError={e => console.error(e)}>
                <Suspense fallback={<Loader/>}>
                    <RelayEnvironmentProvider environment={RelayEnvironment}>
                            <RootNavigation />
                    </RelayEnvironmentProvider>
                </Suspense>
            </Sentry.ErrorBoundary>
        </Provider>
    );
}

export default App;
