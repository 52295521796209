import {useFragment} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import React from "react";
import styled from "styled-components";
import {ProductTitle} from "../cart/CartItem";
import {ReviewCartItem_CartItemFragment$key} from "../../../../__generated__/ReviewCartItem_CartItemFragment.graphql";
import {ProductImage} from "../cart/ProductImage";

const CART_ITEM_FRAGMENT = graphql`
    fragment ReviewCartItem_CartItemFragment on Item {
        itemType
        ... on CartProduct {
            product {
                ... on Product {
                    title
                    icon {
                        url
                    }
                }
            }
            totalPrice {
                netPrice
                grossPrice
            }

            individualPrice {
                netPrice
                grossPrice
            }
            amount
        }
    }
`;
interface OwnProps {
    cartItemFragmentRef: ReviewCartItem_CartItemFragment$key
}

export const ReviewCartItem = ({cartItemFragmentRef}: OwnProps) => {
    const cartItem = useFragment<ReviewCartItem_CartItemFragment$key>(CART_ITEM_FRAGMENT, cartItemFragmentRef)

    return <CartItemContainer>
        {cartItem.itemType === "CartProduct" &&
        <>
            <ProductImage url={cartItem.product?.icon?.url || undefined}/>
            <ProductTitleWrapper>
                <ProductTitle>
                        {cartItem.product?.title}
                </ProductTitle>
            </ProductTitleWrapper>

            <RightSideWrapper>
                <PriceDisplay>
                    {cartItem?.amount} {"Teilnahme-Platz"}
                </PriceDisplay>
            </RightSideWrapper>

        </>}
    </CartItemContainer>

}

const CartItemContainer = styled.div`
  display: grid;
  grid-template-columns: 75px 2fr 1fr;
  grid-column-gap: 20px;

  padding-bottom: 20px;
  border-bottom: solid 1px #e7ebf1;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    grid-template-columns: 75px 1fr;
    grid-row-gap: 20px;
  }
`

const ProductTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const RightSideWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 1024px) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`

export const PriceDisplay = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: "Nunito Sans", sans-serif;
  line-height: 1.57;
  color: #031d3c;
`

