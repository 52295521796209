import React from 'react';

interface OwnProps {
    title: string;
    shortTitle?: string | null;
    addition?: string | null;
}

export const Title = ({title, shortTitle, addition}: OwnProps) => {
    return (
        <div>
            <span>{title}{shortTitle && " - " + shortTitle}</span>
            <span>{addition && addition}</span>
        </div>
    )

}
