import React from "react";
import {useFormik} from "formik";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import * as Yup from "yup";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {ValidatedFieldV2} from "../../components/form/ValidatedFieldV2";
import graphql from "babel-plugin-relay/macro";
import {useTypedDispatch} from "../../Store";
import {LoginScreen_LoginMutation} from "../../__generated__/LoginScreen_LoginMutation.graphql";
import {useMutation} from "react-relay";
import {LoginData, setLoggedIn} from "../../slices/AuthSlice";
import {BaseAuthScreen} from "../../components/ui/BaseAuthScreen";

const LOGIN_MUTATION = graphql`
    mutation LoginScreen_LoginMutation($input: LoginInput!){
        Auth{
            login(input: $input) {
                userId
                token
                groupAssociations {
                    account {
                        id
                    }
                }
            }
        }
    }
`;

interface FormState {
    email: string
    password: string
}

export const LoginScreen = () => {
    const [login, isLoggingIn] = useMutation<LoginScreen_LoginMutation>(LOGIN_MUTATION)
    const dispatch = useTypedDispatch()
    const navigate = useNavigate();

    const formik = useFormik<FormState>({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup
                .string()
                .email("Bitte geben Sie eine gültige E-Mail ein")
                .required("Das Feld E-Mail wird benötigt."),
            password: Yup
                .string()
                .min(8, "Ein Passwort muss mindestens 8 Stellen lang sein.")
                .required("Das Feld Passwort wird benötigt."),
        }),
        onSubmit: (data) => {
            login({
                variables: {
                    input: {
                        email: data.email,
                        password: data.password
                    }
                },
                onCompleted: response => {
                    if (response.Auth.login) {
                        dispatch(setLoggedIn({loginData: response.Auth.login as LoginData}))
                        window.location.reload()
                    }
                }
            })
        }
    });

    return <BaseAuthScreen>
        <h1 className="text-center">Thekey Community TMS</h1>
        <FormContainer onSubmit={formik.handleSubmit} className="p-fluid">
            <ValidatedFieldV2<FormState, string>
                name={"email"}
                label={"E-Mail"}
                iconClass={"pi-envelope"}
                formikConfig={formik}
                component={({fieldValue, updateField, fieldName, isValid}) => {
                    return <InputText id={fieldName} name={fieldName} value={fieldValue}
                                      onChange={e => updateField(e.target.value)}
                                      className={classNames({'p-invalid': !isValid})}/>

                }}/>
            <ValidatedFieldV2<FormState, string>
                name={"password"}
                label={"Passwort"}
                formikConfig={formik}
                component={({fieldValue, updateField, fieldName, isValid}) => {
                    return <Password id={fieldName} name={fieldName} value={fieldValue}
                                     onChange={e => updateField(e.target.value)}
                                     toggleMask
                                     feedback={false}
                                     className={classNames({'p-invalid': !isValid})}
                    />

                }}/>
            <Button disabled={isLoggingIn} type="submit" label="Einloggen" className="mt-2 mb-2"/>
            <Button label="Noch kein Account? Jetzt registrieren" className="p-button-link"
                    onClick={() => navigate("/registration")}/>
            <Button label="Passwort vergessen?" className="p-button-link"
                    onClick={() => navigate("/forgot-password")}/>
        </FormContainer>
    </BaseAuthScreen>
}

const FormContainer = styled.form`
  min-width: 300px;
`
