/**
 * @generated SignedSource<<ad03bb26bd219366a4a6c97c0e2c433f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ItemType = "CartBulkDiscount" | "CartDiscount" | "CartProduct" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ReviewCartItem_CartItemFragment$data = {
  readonly amount?: number;
  readonly individualPrice?: {
    readonly grossPrice: number;
    readonly netPrice: number;
  };
  readonly itemType: ItemType;
  readonly product?: {
    readonly icon?: {
      readonly url: string | null;
    } | null;
    readonly title?: string;
  } | null;
  readonly totalPrice?: {
    readonly grossPrice: number;
    readonly netPrice: number;
  };
  readonly " $fragmentType": "ReviewCartItem_CartItemFragment";
};
export type ReviewCartItem_CartItemFragment$key = {
  readonly " $data"?: ReviewCartItem_CartItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReviewCartItem_CartItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "netPrice",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "grossPrice",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewCartItem_CartItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "itemType",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "product",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "File",
                  "kind": "LinkedField",
                  "name": "icon",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "Product",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Price",
          "kind": "LinkedField",
          "name": "totalPrice",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Price",
          "kind": "LinkedField",
          "name": "individualPrice",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        }
      ],
      "type": "CartProduct",
      "abstractKey": null
    }
  ],
  "type": "Item",
  "abstractKey": "__isItem"
};
})();

(node as any).hash = "a8198471cdb40e96cb30924f3cf4c72c";

export default node;
