import React from 'react';
import graphql from 'babel-plugin-relay/macro'
import {useLazyLoadQuery} from "react-relay";
import {AccountScreen_Query} from "../../__generated__/AccountScreen_Query.graphql";
import {OrderItem} from "./parts/OrderItem";

const QUERY = graphql`
    query AccountScreen_Query {
        Viewer {
            Billing {
                OrdersForUser {
                    edges {
                        node {
                            ...OrderItem_OrderFragment
                        }
                    }
                }
            }
        }
    }
`

export const AccountScreen = () => {
    const {
        Viewer: {
            Billing: {
                OrdersForUser
            }
        }
    } = useLazyLoadQuery<AccountScreen_Query>(QUERY, {}, {fetchPolicy: "network-only"})


    return (
        <div className="flex flex-column align-items-center">
            <h2 className="mb-8">Meine Bestellungen: </h2>
            {OrdersForUser.edges?.map(order =>
                <OrderItem orderFragmentRef={order?.node!}/>
            )}
        </div>
    )

}
