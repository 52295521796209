import {MenuItem} from "primereact/menuitem";
import {RouterMenuItem} from "./RouterMenuItem";
import React from "react";

export const MAIN_MENU_ITEMS : (collapsed?: boolean) => MenuItem[] = (collapsed? :boolean) => [
    {
        label: 'Dashboard',
        url: "/",
        icon: "pi pi-home",
        template: (item) => {
            return <RouterMenuItem collapsed={collapsed} item={item}/>
        }
    },
    {
        label: 'Meine Termine',
        url: "/courses",
        icon: "pi pi-th-large",
        template: (item) => {
            return <RouterMenuItem collapsed={collapsed} item={item}/>
        }
    },
    {
        label: 'Umgebuchte Termine',
        url: "/rebooked-courses",
        icon: "pi pi-th-large",
        template: (item) => {
            return <RouterMenuItem collapsed={collapsed} item={item}/>
        }
    },
    {
        label: 'FAQ',
        url: "/faqs",
        icon: "pi pi-question-circle",
        template: (item) => {
            return <RouterMenuItem collapsed={collapsed} item={item}/>
        }
    },
];

