import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import {ActivationScreen_ActivationMutation} from "../../__generated__/ActivationScreen_ActivationMutation.graphql";
import {useEffect, useState} from "react";
import {ProgressSpinner} from "primereact/progressspinner";
import {Message} from "primereact/message";
import {useMatch} from "react-router-dom";
import {ACTIVATION_PATH} from "../../routes/AuthModuleRoutes";

const ACTIVATION_MUTATION = graphql`
    mutation ActivationScreen_ActivationMutation($input: ActivateUserInput!) {
        Auth{
            activateUser(input: $input) {
                clientMutationId
            }
        }
    }
`
type ActivationState = "unknown" | "succeeded" | "error"

export const ActivationScreen = () => {
    const {params: {token}} = useMatch(ACTIVATION_PATH)!;
    const [state, setState] = useState<ActivationState>("unknown")

    const [activate, isInFlight] = useMutation<ActivationScreen_ActivationMutation>(ACTIVATION_MUTATION)

    useEffect(() => {
        activate({
            variables: {input: {token: token!}},
            onCompleted: () => setState("succeeded"),
            onError: () => setState("error")
        })
        // eslint-disable-next-line
    }, [])

    return <>
        <h1>Konto wird aktiviert</h1>
        {isInFlight ? <div className="flex justify-content-center"><ProgressSpinner/></div> : <div>
            {state === "succeeded" && <Message severity={"success"} content={<div className="block">
                Ihr Konto wurde erfolgreich aktiviert. Sie können sich jetzt auf der <a href={"/"}
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer">Webseite</a> einloggen.
            </div>}>
            </Message>}
            {state === "error" && <Message severity={"error"} content={<>
                Ihr Konto konnte nicht aktiviert werden. Bitte überprüfen Sie dem Link den Sie gefolgt sind.
            </>}>
            </Message>}
        </div>}
    </>
}
